<template>
  <span v-html="formattedValue"></span>
</template>

<script>
import FormatterFactory from '../../helpers/formatter'

export default {
  props: {
    'value': null,
    'type': null,
    'formatter': null,
    'options': {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
    }
  },
  computed: {
    locale () {
      return this.$store.getters['locale/currentLocale']
    },
    formattedValue () {
      let value = this.value
      if (this.type) {
        try {
          switch (this.type) {
            case 'date':
              value = FormatterFactory('Date', {locale: this.locale}).format(value)
              break
            case 'datetime':
              value = FormatterFactory('Datetime', {locale: this.locale}).format(value)
              break
            case 'localized':
              value = FormatterFactory('Localized', {locale: this.locale}).format(value)
              break
          }
        } catch (e) {
          console.error(value, e)
        }
      }

      if (this.formatter) {
        Object.keys(this.formatter).forEach((formatterName) => {
          try {
            let formatter = FormatterFactory(formatterName, this.formatter[formatterName])
            value = formatter.format(value)
          } catch (e) {
            console.error(e)
          }
        })
      }

      return value
    }
  },
  methods: {
  }
}
</script>
