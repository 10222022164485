export const responseHelper = {
  handleError,
  logout
}

function handleError (response) {
  if (typeof response.status === 'undefined' && typeof response.statusText === 'undefined') {
    return Promise.reject(new Error(response))
  }
  let message = response.statusText
  if (response.data && Object.prototype.hasOwnProperty.call(response.data,'message')) {
    message = response.data.message
  } else if (response.data && Object.prototype.hasOwnProperty.call(response.data,'error')) {
    message = Object.prototype.hasOwnProperty.call(response.data.error,'message') ? response.data.error.message : response.data.error
  }
  message = response.status + ': ' + message
  if (response.status === 500) {
    message = 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.'
  } else if (response.status === 403) {
    message = 'Sie haben nicht die Berechtigungen die Daten zu laden.'
  }
  return Promise.reject(new Error(message))
}

function logout () {
  // remove user from local storage to log user out
  localStorage.removeItem('auth')
  localStorage.removeItem('user')
}
