export const hazardRating = {
  rating,
  getRatingItems,
  getRatingIndex,
  getRatingSa,
  getRatingEx,
  getRatingWg
}

function rating (hazard) {
  if (hazard.risk_company_type !== null) {
    const v = hazard.risk_assessment
    let lastValue = -1
    const l = this.getRatingItems().filter((item) => {
      if (v > lastValue && v <= item.value) {
        return true
      }
      lastValue = item.value
      return false
    })
    if (l.length > 0) {
      return l.shift()
    }
  }
  return {
    value: null,
    text: 'nicht definiert',
    color: 'gray',
    icon: 'help_outline'
  }
}

function getRatingItems () {
  return [
    {
      value: 9,
      text: 'akzeptabel',
      color: 'green',
      icon: 'thumb_up'
    },
    {
      value: 15,
      text: 'gering',
      color: 'green',
      icon: 'thumb_up'
    },
    {
      value: 20,
      text: 'signifikant',
      color: 'warning',
      icon: 'warning'
    },
    {
      value: 25,
      text: 'hoch',
      color: 'error',
      icon: 'error'
    },
    {
      value: 35,
      text: 'extrem',
      color: 'error',
      icon: 'error'
    }
  ]
}

function getRatingIndex (item) {
  return this.getRatingItems().findIndex(i => {
    return item.value === i.value
  })
}

function getRatingSa () {
  return [
    { value: 0, text: 'keine Verletzung' },
    { value: 4, text: 'leichte Verletzung' },
    { value: 8, text: 'vollständig heilbare Verletzung' },
    { value: 12, text: 'bleibender Gesundheitsschaden ohne Beeinflussung der Lebensqualität' },
    { value: 16, text: 'bleibender Gesundheitsschaden mit Beeinflussung der Lebensqualität' },
    { value: 20, text: 'Tod' }
  ]
}

function getRatingEx () {
  return [
    { value: 0, text: 'Keine' },
    { value: 1, text: 'ca. 1 Stunde pro Monat' },
    { value: 2, text: 'ca. 1 Stunde pro Woche' },
    { value: 3, text: 'ca. 1 Stunde pro Tag' },
    { value: 4, text: 'ca. 1-4 Stunden pro Tag' },
    { value: 5, text: 'über 4 Stunden pro Tag' }
  ]
}

function getRatingWg () {
  return [
    { value: 0, text: 'keine' },
    { value: 2, text: 'kaum vorstellbar' },
    { value: 4, text: 'vorstellbar aber unüblich' },
    { value: 6, text: 'unter bestimmten Bedingungen vorstellbar' },
    { value: 8, text: 'kann erwartet werden' },
    { value: 10, text: 'tritt ohne Zweifel ein' }
  ]
}
