export const settings = {
  namespaced: true,
  state: {
    settings: {}
  },
  getters: {
    get: (state) => {
      return state.settings
    }
  },
  mutations: {
    set (state, settings) {
      state.settings = settings
    }
  }
}
