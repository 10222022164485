<template>
  <div class="step4">
    <v-app-bar flat color="grey lighten-2">
      <img src="../../../assets/circle-03.svg" alt="" class="circle">
      <v-toolbar-title>
        {{ $t('Beurteilung der Gefährdungen') }}
      </v-toolbar-title>
    </v-app-bar>

    <v-divider></v-divider>

    <v-card-title primary-title>
      <h2 class="title">
        <formatter :value="hazardAssessment.description" type="localized"></formatter><span v-if="hazardAssessment.type">: {{ hazardAssessment.type.name[locale] }}</span>
      </h2>
    </v-card-title>

    <v-card-text>
      <v-layout wrap>
        <v-flex sm12>
          <div class="title primary--text d-inline-block mr-4">{{ $t('Gefährdungsliste') }}</div>

          <v-data-table
            :headers="headers"
            :items="hazardAssessment.hazards"
            hide-default-footer
            class=""
          >
            <template slot="item" slot-scope="props">
              <tr>
                <td>{{ props.item.name[locale] }}</td>
                <td>
                  <status :status="getStatus(props.item)"></status>
                </td>
                <td>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                    <v-icon
                      class="mr-2"
                      color="primary"
                      @click.stop="selectHazard(props.item)"
                      v-on="on"
                    >input</v-icon>
                    </template>
                    <span>{{ $t('Beurteilung der Gefährdung') }}</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
            <template slot="no-data">
              {{ $t('Sie haben noch keine Gefährdung erfasst.') }}
            </template>
          </v-data-table>
          <v-divider class="mb-3"></v-divider>

        </v-flex>
      </v-layout>
    </v-card-text>

    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      scrollable
      max-width="1096"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-card tile>
        <v-app-bar flat dark color="primary">
            <v-btn icon dark @click.native="dialog = false">
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ $t('Beurteilung der Gefährdung') }}</v-toolbar-title>
        </v-app-bar>

        <v-card-text>
          <v-form v-model="valid" ref="form">

            <h2><formatter :value="hazard.name" type="localized"></formatter></h2>

            <v-container grid-list-md fluid>

              <v-layout wrap>
                <v-flex xs12 md6>
                  <v-radio-group
                    v-model="hazard.risk_company_type"
                    :label="$t('Um welche Art von Betrieb handelt es sich?')"
                    :rules="requiredCompanyType"
                  >
                    <template v-for="(t, index) in companyTypes">
                      <v-radio :label="t.text" :value="t.value" :key="index"></v-radio>
                    </template>
                  </v-radio-group>
                </v-flex>
                <v-flex xs12 md6 class="text-md-right">
                  <v-btn color="primary" small text @click.stop="infoDialog = true">
                    <v-icon left>info</v-icon> {{ $t('Bewertungskriterien anzeigen') }}
                  </v-btn>
                </v-flex>
              </v-layout>

              <template v-if="hazard.risk_company_type !== null">
                <v-switch
                  :label="$t('Manuelle Festlegung?')"
                  v-model="switcher"
                  @change="toggleSwitcher"
                ></v-switch>

                <template v-if="!switcher">
                  <v-select
                    :label="$t('SA: Wie hoch schätzen Sie das Schadensausmaß ein?')"
                    :items="getRatingSa()"
                    v-model="hazard.risk_values.sa"
                    clearable
                  ></v-select>

                  <v-select
                    :label="$t('EX: Wie hoch schätzen Sie die Häufigkeit und Dauer der Exposition ein?')"
                    :items="getRatingEx()"
                    v-model="hazard.risk_values.ex"
                    clearable
                  ></v-select>

                  <v-select
                    :label="$t('WG: Wie hoch schätzen Sie die Wahrscheinlichkeit des Gefährdungseintritts ein?')"
                    :items="getRatingWg()"
                    v-model="hazard.risk_values.wg"
                    clearable
                  ></v-select>

                  <v-subheader>{{ $t('Die berechnete Maßzahl für das verbleibenden Risiko:') }}</v-subheader>
                  <div class="riskProgress">
                    <v-progress-linear
                      :value="100"
                      height="40"
                      :color="progressSelect"
                    >
                    </v-progress-linear>
                    <p class="title text-center">{{ hazard.risk_assessment }}</p>
                  </div>

                </template>
                <template v-else>
                  {{ $t('Wie hoch schätzen Sie das verbleibende Risiko ein?') }}
                  <v-slider
                    v-model="riskManual"
                    :tick-labels="riskManualLabels"
                    :min="0"
                    :max="riskManualValues.length - 1"
                    step="1"
                  >
                  </v-slider>
                  <div class="riskProgress">
                    <v-progress-linear
                      :value="100"
                      height="40"
                      :color="progressSelect"
                    >
                    </v-progress-linear>
                    <p class="title text-center">{{ progressText }}</p>
                  </div>
                </template>
              </template>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" text @click.native="dialog = false">
            {{ $t('Abbrechen') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="saveHazard" :disabled="!valid">
            <v-icon left>save</v-icon> {{ $t('Bewertung speichern') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      :timeout="4000"
      top
      multi-line
      v-model="snackbar"
      color="green"
    >
      {{ $t('Die Gefährdungsbeurteilung wurde aktualisiert.') }}

      <template v-if="snackbarInfo">
        <br>{{ $t('Es sind keine weiteren Maßnahmen erforderlich.') }}
      </template>

      <v-btn text @click.native="snackbar = false">
        <v-icon left>close</v-icon> {{ $t('Schließen') }}
      </v-btn>
    </v-snackbar>

    <v-dialog
      v-model="infoDialog"
      transition="dialog-bottom-transition"
      :overlay="false"
      scrollable
      max-width="500px"
    >
      <v-card>
        <v-card-title><h2>{{ $t('Bewertungskriterien') }}</h2></v-card-title>

        <v-card-text>
          <table class="data">
            <thead>
              <tr>
                <th>{{ $t('Bewertungskriterien') }}</th>
                <th style="white-space: nowrap">{{ $t('Risikofaktoren (R)') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th colspan="2">{{ $t('SA: Schadensausmaß (Bewertungsfaktor 4)') }}</th>
              </tr>
              <tr v-for="item in getRatingSa()" :key="'sa' + item.value">
                <td>{{ item.text }}</td>
                <td class="text-center">{{ item.value }}</td>
              </tr>
              <tr>
                <th colspan="2">{{ $t('EX: Häufigkeit und Dauer der Exposition (Bewertungsfaktor 1)') }}</th>
              </tr>
              <tr v-for="item in getRatingEx()" :key="'ex' + item.value">
                <td>{{ item.text }}</td>
                <td class="text-center">{{ item.value }}</td>
              </tr>
              <tr>
                <th colspan="2">{{ $t('WG: Wahrscheinlichkeit des Gefährdungseintritts (Bewertungsfaktor 2)') }}</th>
              </tr>
              <tr v-for="item in getRatingWg()" :key="'wg' + item.value">
                <td>{{ item.text }}</td>
                <td class="text-center">{{ item.value }}</td>
              </tr>
            </tbody>
          </table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click.stop="infoDialog = false">{{ $t('Schließen') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { hazardassessmentService } from '../../../services'
  import { hazardRating } from '../../../helpers'
  import Status from './Status.vue'
  import formatter from '../../helpers/Formatter'

  export default {
    data () {
      return {
        valid: true,
        snackbar: false,
        snackbarInfo: false,
        switcher: false,
        form: true,
        dialog: false,
        infoDialog: false,
        hazard: null,
        hazardIndex: 1,
        requiredCompanyType: [v => v !== null || this.$t('Bitte geben Sie an um welche Art von Betrieb es sich handelt.')],
        companyTypes: [
          {'value': 1, 'text': 'Normalbetrieb / normale Tätigkeit'},
          {'value': 0, 'text': 'Einrichten Sonderbetrieb Instandhaltung'}
        ]
      }
    },
    props: [
      'hazardAssessment',
      'hazardAssessmentObject',
      'actiontypes',
      'isValid'
    ],
    created () {
      this.hazard = JSON.parse(JSON.stringify(this.hazardAssessmentObject.hazards[0]))
      this.hazardEmpty = JSON.parse(JSON.stringify(this.hazardAssessmentObject.hazards[0]))
      this.$emit('isValid', this.validates())
    },
    watch: {
      'hazard.risk_values': {
        handler () {
          if (!this.switcher) {
            let sa = (this.hazard.risk_values.sa === null) ? 0 : this.hazard.risk_values.sa
            let ex = (this.hazard.risk_values.ex === null) ? 0 : this.hazard.risk_values.ex
            let wg = (this.hazard.risk_values.wg === null) ? 0 : this.hazard.risk_values.wg
            this.hazard.risk_assessment = (sa + ex + wg)
          }
        },
        deep: true
      },
      hazards () {
        this.$emit('isValid', this.validates())
      }
    },
    computed: {
      locale () {
        return this.$store.getters['locale/currentLocale']
      },
      progressText: function () {
        let status = this.getStatus(this.hazard)
        return status.text
      },
      progressSelect: function () {
        let status = this.getStatus(this.hazard)
        return status.color
      },
      riskManualLabels () {
        let labels = []
        hazardRating.getRatingItems().forEach(item => {
          labels.push(item.text)
        })
        return labels
      },
      riskManualValues () {
        let values = []
        hazardRating.getRatingItems().forEach(item => {
          values.push(item.value)
        })
        return values
      },
      riskManualValue: {
        get () {
          let status = this.getStatus(this.hazard)
          let index = hazardRating.getRatingIndex(status)
          return (index === -1) ? 0 : index
        },
        set () { }
      },
      riskManual: {
        get () {
          return this.riskManualValue || 0
        },
        set (value) {
          let items = hazardRating.getRatingItems()
          this.hazard.risk_assessment = items[value] ? items[value].value : 0
        }
      },
      hazards () {
        return this.hazardAssessment.hazards
      },
      headers () {
        return [
          {
            text: 'Name',
            align: 'left',
            sortable: true,
            value: `name.${this.locale}`
          },
          {
            text: 'Status',
            align: 'left',
            sortable: false,
            value: 'risk_assessment'
          },
          { text: 'Aktion', value: 'name', sortable: false }
        ]
      }
    },
    methods: {
      toggleSwitcher: function () {
        this.hazard.risk_values = {sa: null, ex: null, wg: null}
        this.hazard.risk_assessment = null
        this.riskManual = null
      },
      validates () {
        let rtn = true
        this.hazardAssessment.hazards.forEach(item => {
          if (item.risk_company_type === null) {
            rtn = false
          }
        })
        return rtn
      },
      getStatus (hazard) {
        return hazardRating.rating(hazard)
      },
      saveHazard () {
        if (this.hazardIndex === null) {
          this.hazardIndex = this.hazardAssessment.hazards.length
        }
        if (this.valid) {
          this.$set(this.hazardAssessment.hazards, this.hazardIndex, JSON.parse(JSON.stringify(this.hazard)))
          hazardassessmentService.save(this.hazardAssessment).then(() => {
              if ((this.hazard.risk_assessment > 0 && this.hazard.risk_assessment < 10) || this.hazard.risk_assessment === 0) {
                this.snackbarInfo = true
              } else {
                this.snackbarInfo = false
              }
              this.dialog = false
              this.snackbar = true
            },
            error => {
              let {dispatch} = this.$store
              dispatch('alert/error', error.message, {root: true})
            }
          ).then(() => {
            this.hazard = JSON.parse(JSON.stringify(this.hazardEmpty))
          })
        }
      },
      newHazard () {
        this.hazardIndex = this.hazardAssessment.hazards.length
        this.hazard = JSON.parse(JSON.stringify(this.hazardEmpty))
        this.dialog = true
      },
      selectHazard (hazard) {
        this.hazardIndex = this.hazardAssessment.hazards.indexOf(hazard)
        this.hazard = JSON.parse(JSON.stringify(this.hazardAssessment.hazards[this.hazardIndex]))
        this.switcher = (this.hazard.risk_values === null || this.hazard.risk_values.sa === null)
        if(typeof this.$refs['form'] !== 'undefined') {
          this.$refs['form'].resetValidation()
        }
        this.dialog = true
      },
      getRatingSa () {
        return hazardRating.getRatingSa()
      },
      getRatingEx () {
        return hazardRating.getRatingEx()
      },
      getRatingWg () {
        return hazardRating.getRatingWg()
      }
    },
    components: {
      Status,
      formatter
    }
  }
</script>
