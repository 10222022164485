import DateFormatter from './formatter/date'
import DatetimeFormatter from './formatter/datetime'
import LocalizedFormatter from './formatter/localized'
import LocaleFormatter from './formatter/locale'
import { store } from '../store'
import md5 from 'md5'

const constructorMap = {
  Date: DateFormatter,
  Datetime: DatetimeFormatter,
  Localized: LocalizedFormatter,
  Locale: LocaleFormatter
}

const instanceMap = {}

export default function create (name, settings) {
  if (!settings.locale) {
    settings.locale = store.getters['locale/currentLocale']
  }
  const hash = `${name}_${md5(JSON.stringify(settings))}`
  if (!instanceMap[hash]) {
    instanceMap[hash] = new (constructorMap[name])(settings)
  }
  return instanceMap[hash]
}
