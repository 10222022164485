import { responseHelper } from '../helpers'
import Vue from 'vue'

export const redmineService = {
  getAll,
  get,
  save,
  saveComment
}

function getAll () {
  return Vue.prototype.$http.get('/support')
    .then(response => {
      return response.data
    })
    .catch(error => {
      return responseHelper.handleError(error)
    })
}

function get (id) {
  return Vue.prototype.$http.get('/support/' + id)
    .then(response => {
      return response.data
    })
    .catch(error => {
      return responseHelper.handleError(error)
    })
}

function save (object) {
  return Vue.prototype.$http.post('/support', object)
    .then(response => {
      return response.data
    })
    .catch(function (error) {
      return responseHelper.handleError(error)
    })
}

function saveComment (id, object) {
  return Vue.prototype.$http.put('/support/' + id, object)
    .then(response => {
      return response.data
    })
    .catch(error => {
      return responseHelper.handleError(error)
    })
}
