<template>
  <v-chip outlined :color="statusColor" v-if="status">
    <v-icon left :color="statusColor">{{ statusIcon }}</v-icon>
    {{ status.text }}
  </v-chip>
</template>

<script>
  export default {
    data () {
      return {
      }
    },
    props: {
      status: null,
      small: {
        type: Boolean,
        default: function() {
          return false
        },
      }
    },
    computed: {
      statusColor () {
        return this.status ? this.status.color : 'info'
      },
      statusIcon () {
        return this.status ? this.status.icon : 'info'
      }
    }
  }
</script>
