<template>
  <div>
    <v-layout wrap class="my-4">
    <v-flex xs12 lg10 offset-lg1 xl8 offset-xl2>
      <v-card>
        <v-app-bar flat color="grey lighten-2">
          <v-toolbar-title>
            Authentifizierung
          </v-toolbar-title>
        </v-app-bar>

        <v-divider></v-divider>

        <v-card-text>
          <p class="body-2">Bitte geben Sie Ihre Anmeldedaten ein:</p>
          <v-form ref="form" v-model="valid">
            <v-text-field
              label="Benutzername"
              v-model="login"
              single-line
              :rules="[rules.required]"
            ></v-text-field>

            <v-text-field
              label="Passwort"
              v-model="password"
              single-line
              :rules="[rules.required, rules.min]"
              type="password"
            ></v-text-field>

            <v-btn
              color="primary"
              :disabled="!valid"
              @click="handleSubmit"
            >
              Anmelden
            </v-btn>
          </v-form>

        </v-card-text>
      </v-card>

      <v-card flat class="mt-2 grey lighten-5">
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="legalnotice = true">
            <span>{{ $t('Impressum') }}</span>
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>

    </v-flex>
  </v-layout>

    <v-dialog
      v-model="legalnotice"
      transition="dialog-bottom-transition"
      scrollable
      max-width="600"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <legal-notice :dialog="true" @close="legalnotice = false"></legal-notice>
    </v-dialog>
  </div>
</template>

<script>
import LegalNotice from '../legalnotice/LegalNotice'

export default {
  data () {
    return {
      valid: true,
      login: '',
      password: '',
      legalnotice: false,
      rules: {
        required: value => !!value || 'Bitte machen Sie ein Eingabe.',
        min: v => v.length >= 4 || 'Es werden mindestens 4 Zeichen benötigt.'
      }
    }
  },
  computed: {
    loggedIn () {
      return this.$store.state.authentication.status.loggedIn
    }
  },
  methods: {
    handleSubmit () {
      if (this.$refs.form.validate()) {
        const {login, password} = this
        const {dispatch} = this.$store
        if (login && password) {
          dispatch('authentication/login', {login, password})
        }
      }
    }
  },
  components: {
    LegalNotice
  }
}
</script>
