<template>
  <v-card>
    <v-app-bar flat color="grey lighten-2">
      <v-toolbar-title>
        {{ $t('Hilfe') }}
      </v-toolbar-title>
    </v-app-bar>

    <v-divider></v-divider>

    <v-card-text>
      <p>Die Hilfe beantwortet die am häufigsten gestellten Fragen und enthält einige Bild- und Videoanleitungen.</p>
      <p>
        Bei technischen Problemen verwenden Sie bitte den <a href="#" @click.prevent="support()">Supportbereich</a>
        oder schreiben Sie an <a href="mailto:support@gefaehrdungsmanager.de">support@gefaehrdungsmanager.de</a>.
      </p>

      <v-btn depressed color="primary" @click.native="open()">Zur Onlinehilfe</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'help',
  data () {
    return {
    }
  },
  methods: {
    open () {
      window.open('https://gefaehrdungs-manager.de/online-hilfe/', '_blank')
    },
    support () {
      this.$router.push({name: 'support'})
    }
  }
}
</script>
