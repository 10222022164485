import { responseHelper } from '../helpers'
import Vue from 'vue'

export const hazardassessmentService = {
  getAll,
  get,
  find,
  create,
  save,
  interrupt,
  execute,
  finish,
  reissue,
  remove,
  pdf
}

function getAll () {
  return Vue.prototype.$http.get('/hazardassessment')
    .then(response => {
      return response.data
    })
    .catch(error => {
      return responseHelper.handleError(error)
    })
}

function get (id) {
  return Vue.prototype.$http.get('/hazardassessment/' + id)
    .then(response => {
      return response.data
    })
    .catch(error => {
      return responseHelper.handleError(error)
    })
}

function find (search) {
  return Vue.prototype.$http.post('/hazardassessment/find', search)
    .then(response => {
      return response.data
    })
    .catch(error => {
      return responseHelper.handleError(error)
    })
}

function create () {
  return Vue.prototype.$http.get('/hazardassessment/create')
    .then(response => {
      return response.data
    })
    .catch(error => {
      return responseHelper.handleError(error)
    })
}

function save (object) {
  if (object.id !== null && object.id !== 0) {
    return Vue.prototype.$http.put('/hazardassessment/' + object.id, object)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return responseHelper.handleError(error)
      })
  } else {
    return Vue.prototype.$http.post('/hazardassessment', object)
      .then(response => {
        return response.data
      })
      .catch(function (error) {
        return responseHelper.handleError(error)
      })
  }
}

function execute (object) {
  return Vue.prototype.$http.put('/hazardassessment/execute/' + object.id, object)
    .then(response => {
      return response.data
    })
    .catch(error => {
      return responseHelper.handleError(error)
    })
}

function finish (object) {
  return Vue.prototype.$http.put('/hazardassessment/finish/' + object.id, object)
    .then(response => {
      return response.data
    })
    .catch(error => {
      return responseHelper.handleError(error)
    })
}

function reissue (object) {
  return Vue.prototype.$http.put('/hazardassessment/reissue/' + object.id)
    .then(response => {
      return response.data
    })
    .catch(error => {
      return responseHelper.handleError(error)
    })
}

function interrupt (object) {
  return Vue.prototype.$http.put('/hazardassessment/interrupt/' + object.id, object)
    .then(response => {
      return response.data
    })
    .catch(error => {
      return responseHelper.handleError(error)
    })
}

function remove (object) {
  return Vue.prototype.$http.delete('/hazardassessment/' + object.id)
    .then(response => {
      return response.data
    })
    .catch(error => {
      return responseHelper.handleError(error)
    })
}

function pdf (object) {
  return Vue.prototype.$http.get('/hazardassessment/pdf/' + object.id)
    .then(response => {
      return response.data
    })
    .catch(error => {
      return responseHelper.handleError(error)
    })
}
