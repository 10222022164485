<template>
  <div>
    <v-card>
      <v-app-bar flat color="grey lighten-2">
        <img src="../../assets/circle-blank.svg" alt="" class="circle">
        <v-toolbar-title>
          {{ $t('Liste aller erfassten Gefährdungsbeurteilungen') }}
        </v-toolbar-title>
      </v-app-bar>

      <v-divider></v-divider>

      <v-card-text>
        <v-card flat>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="search"
              :label="$t('Suche')"
              single-line
              hide-details
            ></v-text-field>

            <v-spacer></v-spacer>

            <v-switch
              :label="$t('Abgeschlossene einblenden')"
              v-model="listToggle"
            ></v-switch>

            <v-spacer></v-spacer>

            <v-tooltip bottom v-if="!isGranted(['ROLE_IMPLEMENTER'])">
              <template v-slot:activator="{ on }">
                <v-btn
                  fab
                  dark
                  color="primary"
                  v-on="on"
                  @click="$router.push({name: 'hazardassessment_edit_step', params: {id: 0, step: 1}})"
                >
                  <v-icon>add</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('Neue Gefährdungsbeurteilungen erfassen') }}</span>
            </v-tooltip>

            <!-- v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  fab
                  small
                  v-on="on"
                  @click="getData()"
                >
                  <v-icon>refresh</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('Gefährdungsbeurteilungen aktualisieren') }}</span>
            </v-tooltip -->
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="list"
            :options.sync="pagination"
            :search="search"
            :custom-filter="filterList"
            item-key="name"
          >
            <template slot="item" slot-scope="props">
              <tr @click="selectItem(props.item)">
                <td>{{ props.item.id }} <formatter :value="props.item.description" type="localized"></formatter></td>
                <td>{{ props.item.systemno }}</td>
                <td>{{ props.item.company.number }}</td>
                <td>
                  <formatter :value="props.item.location" type="localized"></formatter><span v-if="props.item.location">, </span>
                  <formatter :value="props.item.city" type="localized"></formatter>
                </td>
                <td><formatter :value="props.item.department" type="localized"></formatter></td>
                <td><formatter :value="props.item.date" type="date"></formatter></td>
                <td>{{ $t(props.item.status_name) }}</td>
                <td nowrap>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        v-on="on"
                        @click.stop="selectItem(props.item)"
                        class="ma-0"
                      >
                        <v-icon color="primary">input</v-icon>
                      </v-btn>
                    </template>
                    <span v-if="props.item.status_name === 'STATUS_EXECUTION'">{{ $t('Gefährdungsbeurteilung durchführen') }}</span>
                    <span v-else-if="props.item.status_name === 'STATUS_FINISHED'">{{ $t('Gefährdungsbeurteilung anzeigen') }}</span>
                    <span v-else>{{ $t('Gefährdungsbeurteilung bearbeiten') }}</span>
                  </v-tooltip>

                  <v-tooltip bottom v-if="props.item.status_name === 'STATUS_CREATE'">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        @click.stop="deleteItem(props.item)"
                        class="ma-0"
                        v-on="on"
                      >
                        <v-icon color="primary">delete</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('Gefährdungsbeurteilung löschen') }}</span>
                  </v-tooltip>

                  <!-- v-btn icon class="ma-0">
                    <v-icon color="primary">print</v-icon>
                  </v-btn -->
                </td>
              </tr>
            </template>
            <v-alert slot="no-data" :value="true" color="info" icon="warning">
              Es wurden noch keine Gefährdungsbeurteilungen erfasst.
            </v-alert>
            <v-alert slot="no-results" :value="true" color="error" icon="warning">
              Für Ihre Suche nach "{{ search }}" gibt es keine Ergebnisse.
            </v-alert>
          </v-data-table>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {
  hazardassessmentService,
  settingsService,
  userService
} from '../../services'
import formatter from '../helpers/Formatter'
import FormatterFactory from '../../helpers/formatter'

export default {
  name: 'hazardassessment_list',
  data () {
    return {
      data: [],
      specifications: [],
      dateFormatter: FormatterFactory('Date', { locale: (this.locale || 'de') }),
      search: '',
      toggle: false,
      pagination: {
        sortBy: ['name'],
        rowsPerPage: 25,
        totalItems: this.totalItems
      }
    }
  },
  created: async function () {
    this.getData()
  },
  computed: {
    listToggle: {
      get () {
        return this.toggle
      },
      set (toggle) {
        this.toggle = toggle
      }
    },
    list () {
      return this.data.filter(item => {
        return !this.toggle ? item.status_name !== 'STATUS_FINISHED' : true
      })
    },
    totalItems () {
      return this.list.length
    },
    locale () {
      return typeof this.$store.getters.currentLocale === 'undefined' ? 'de' : this.$store.getters.currentLocale
    },
    headers () {
      return [
        {
          text: 'Bezeichnung',
          align: 'left',
          sortable: true,
          value: `description.${this.locale}`
        },
        {
          text: 'Systemnummer',
          align: 'left',
          sortable: true,
          value: `systemno`
        },
        {
          text: 'Betriebsnr.',
          align: 'left',
          sortable: true,
          value: 'company.number'
        },
        {
          text: 'Standort',
          align: 'left',
          sortable: true,
          value: `location.${this.locale}`
        },
        {
          text: 'Abteilung',
          align: 'left',
          sortable: true,
          value: `department.${this.locale}`
        },
        {
          text: 'Erstelldatum',
          align: 'left',
          sortable: true,
          value: 'date'
        },
        {
          text: 'Status',
          align: 'left',
          sortable: true,
          value: 'status_name'
        },
        {text: 'Aktion', value: 'name', sortable: false}
      ]
    }
  },
  methods: {
    filterList (items, search, filter) {
      search = search.toString().toLowerCase()
      return items.filter(item => (
        Object.keys(item).some(key => {
          if (key === 'date') {
            if (!item[key]) {
              return false
            }
            let [year, month, day] = [...item[key].split('-')]
            let date = new Date(year, month - 1, day)
            if (!(date instanceof Date)) {
              return false
            }
            return filter(this.dateFormatter.format(date), search)
          } else if(key === 'status_name'){
              return filter(this.$t(item[key]), search)
          } else if(key === 'location'){
              return filter(item[key][this.locale] + ', ' + item['city'][this.locale], search)
          } else {
              if (item[key] === null) {
                return false
              }
              return Object.prototype.hasOwnProperty.call(item[key], this.locale) ? filter(item[key][this.locale], search) : filter(item[key], search)
          }
        })
      ))
    },
    async getData () {
      let {dispatch} = this.$store
      dispatch('loader/show', {root: true})
      settingsService.getData()
        .then(
          result => {
            if (result !== null && typeof result.list !== 'undefined') {
              this.listToggle = result.list
            }
          },
          error => {
            let {dispatch} = this.$store
            dispatch('alert/error', error.message, {root: true})
          }
        )
      await hazardassessmentService.getAll()
        .then(
          result => { this.data = result },
          error => {
            dispatch('alert/error', error.message, {root: true})
          }
        )
        .finally(() => dispatch('loader/hide', {root: true}))
    },
    deleteItem (item) {
      if (confirm('Soll die Gefährdungsbeurteilung wirklich gelöscht werden?')) {
        let {dispatch} = this.$store
        dispatch('loader/show', {root: true})
        hazardassessmentService.remove(item)
          .then(
            () => {
              let {dispatch} = this.$store
              dispatch('alert/success', 'Die Gefährdungsbeurteilung wurde gelöscht.', {root: true})
              this.data.splice(this.data.indexOf(item), 1)
            },
            error => {
              let {dispatch} = this.$store
              dispatch('alert/error', error.message, {root: true})
            }
          )
          .finally(() => dispatch('loader/hide', {root: true}))
      }
    },
    selectItem (item) {
      if (item.status_name === 'STATUS_EXECUTION') {
        this.$router.push({name: 'hazardassessment_execute', params: {id: item.id}})
      } else if (item.status_name === 'STATUS_FINISHED') {
        this.$router.push({name: 'hazardassessment_finished', params: { id: item.id }})
      } else {
        this.$router.push({name: 'hazardassessment_edit_step', params: { id: item.id, step: 1 }})
      }
    },
    isGranted (roles) {
      if (roles === null || roles.length === 0) {
        return true
      }
      return userService.isGranted(roles)
    }
  },
  components: {
    formatter
  }
}
</script>
