<template>
  <div class="step5">
    <v-app-bar flat color="grey lighten-2">
      <img src="../../../assets/circle-05.svg" alt="" class="circle">
      <v-toolbar-title>
        {{ $t('Maßnahmen erfassen, durchführen & prüfen') }}
      </v-toolbar-title>
    </v-app-bar>

    <v-divider></v-divider>

    <v-card-title primary-title>
      <h2 class="title">
        <formatter :value="hazardAssessment.description" type="localized"></formatter><span v-if="hazardAssessment.type">: {{ hazardAssessment.type.name[locale] }}</span>
      </h2>

      <v-spacer></v-spacer>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn fab dark color="primary" @click="newAction()" v-on="on">
            <v-icon dark>add</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('Neue Maßnahme erfassen') }}</span>
      </v-tooltip>
    </v-card-title>

    <v-card-text>
      <v-tabs
        right
        v-model="tabview"
      >
        <v-row>
          <v-col sm="4">
            <v-text-field
                    :label="$t('Suchen')"
                    single-line
                    hide-details
                    clearable
                    prepend-inner-icon="search"
                    v-model="search"
                    class="pt-0"
            ></v-text-field>
          </v-col>
          <v-col sm="8" class="d-flex">
            <template v-if="!isGranted(['ROLE_IMPLEMENTER'])">
              <v-switch
                      :label="$t('Durchführung erforderlich')"
                      v-model="executionToggle"
                      class="pt-0 mt-2"
              ></v-switch>
              <v-spacer></v-spacer>
              <v-switch
                      :label="$t('Wirksamkeitsprüfung erforderlich')"
                      v-model="effectivenessToggle"
                      class="pt-0 mt-2"
              ></v-switch>
              <v-spacer></v-spacer>
            </template>
          </v-col>
        </v-row>

        <v-tabs-slider></v-tabs-slider>
        <v-tab href="#tab-0">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">view_list</v-icon>
            </template>
            <span>{{ $t('Maßnahmen nach Gefährdungen gruppieren') }}</span>
          </v-tooltip>
        </v-tab>
        <v-tab href="#tab-1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">view_module</v-icon>
            </template>
            <span>{{ $t('Maßnahmenliste ohne Gruppierung') }}</span>
          </v-tooltip>
        </v-tab>
        <v-tabs-items v-model="tabview">
          <v-tab-item value="tab-0" class="pt-3 mt-4">
            <v-expansion-panels
              v-model="panel"
              focusable
              popout
            >
              <v-expansion-panel
                v-for="(hazard, i) in hazardAssessment.hazards"
                :key="i"
              >
                <v-expansion-panel-header>
                  <v-layout wrap>
                    <v-flex xs10 class="py-2">
                      <span v-html="getHazardPrefix(hazard)"></span>
                      <formatter :value="hazard.name" type="localized"></formatter>
                    </v-flex>
                    <v-flex xs2><status :status="getRating(hazard)"></status></v-flex>
                  </v-layout>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-data-table
                      :headers="headers"
                      :items="getActions(hazard)"
                      :search="search"
                      hide-default-footer
                      item-key="name"
                      class="hazards"
                    >
                      <template slot="item" slot-scope="props">
                        <tr :class="{finished:isFinished(props.item), 'not-assigned':!isAssigned(props.item)}">
                          <td><formatter :value="props.item.name" type="localized"></formatter></td>
                          <td>
                            <ul v-if="props.item.hazard_dangers && props.item.hazard_dangers.length > 0">
                              <li v-for="(d, index) in props.item.hazard_dangers.map(danger => danger.name.de)" :key="index">{{ d }}</li>
                            </ul>
                          </td>
                          <td>
                            <template v-if="isAssigned(props.item)">
                              <v-layout
                                row
                                wrap
                                align-content-center
                                v-if="!isFinished(props.item)"
                              >
                                <v-flex align-self-center xs6>
                                  <v-progress-linear
                                    v-model="props.item.implemented"
                                    height="12"
                                  ></v-progress-linear>
                                </v-flex>
                                <v-flex align-self-center xs6>
                                  <span class="ml-1 text-no-wrap">{{ props.item.implemented||0 }}%</span>
                                </v-flex>
                              </v-layout>
                              <template v-else>
                                <v-tooltip bottom v-if="hasStatus(props.item, 'STATUS_EFFECTIVE')">
                                  <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" color="success">thumb_up</v-icon>
                                  </template>
                                  <span>{{ $t('Wirksamkeit wurde erreicht') }}</span>
                                </v-tooltip>

                                <v-tooltip bottom v-else-if="hasStatus(props.item, 'STATUS_INEFFECTIVE')">
                                  <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" color="warning">thumb_down</v-icon>
                                  </template>
                                  <span>{{ $t('Wirksamkeit wurde nicht erreicht') }}</span>
                                </v-tooltip>

                                <v-tooltip bottom v-else>
                                  <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" color="grey lighten-2" class="status">thumbs_up_down</v-icon>
                                  </template>
                                  <span>{{ $t('Wirksamkeitsprüfung steht noch aus') }}</span>
                                </v-tooltip>
                              </template>
                            </template>
                            <template v-else>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-icon
                                    color="primary"
                                    v-on="on"
                                  >notification_important</v-icon>
                                </template>
                                <span>{{ $t('Die Maßnahme wurde importiert und muss zuerst einem Mitarbeiter zugewiesen werden.') }}</span>
                              </v-tooltip>
                            </template>
                          </td>
                          <td><span v-if="props.item.implementation_responsible">{{ props.item.implementation_responsible.name }}</span></td>
                          <td><formatter :value="props.item.implementation_deadline" type="date"></formatter></td>
                          <td class="px-0 py-2" style="white-space: nowrap;">
                            <v-tooltip bottom v-if="!isAssigned(props.item) && !isGranted(['ROLE_IMPLEMENTER'])">
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  class="mr-2"
                                  color="primary"
                                  @click.stop="assignExecute(props.item, hazard)"
                                  v-on="on"
                                >input</v-icon>
                              </template>
                              <span>{{ $t('Maßnahme zuweisen') }}</span>
                            </v-tooltip>
                            <v-tooltip bottom v-else-if="props.item.implemented < 100 && !isFinished(props.item)">
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  class="mr-2"
                                  color="primary"
                                  @click.stop="selectExecute(props.item, hazard)"
                                  v-on="on"
                                >input</v-icon>
                              </template>
                              <span>{{ $t('Maßnahme durchführen') }}</span>
                            </v-tooltip>

                            <v-tooltip bottom v-if="props.item.implemented === 100 && !isFinished(props.item) && !isGranted(['ROLE_IMPLEMENTER'])">
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  class="mr-2"
                                  color="primary"
                                  @click.stop="selectEffectiveness(props.item, hazard)"
                                  v-on="on"
                                >input</v-icon>
                              </template>
                              <span>{{ $t('Wirksamkeit der Maßnahme überprüfen') }}</span>
                            </v-tooltip>

                            <v-tooltip bottom v-if="isFinished(props.item) && !isGranted(['ROLE_IMPLEMENTER'])">
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  class="mr-2"
                                  color="secondary"
                                  @click.stop="selectReactivate(props.item, hazard)"
                                  v-on="on"
                                >autorenew</v-icon>
                              </template>
                              <span>{{ $t('Maßnahme reaktivieren') }}</span>
                            </v-tooltip>

                            <v-tooltip bottom v-if="!isFinished(props.item) && (!props.item.implemented || props.item.implemented === 0) && !isGranted(['ROLE_IMPLEMENTER'])">
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  class="mr-2"
                                  color="primary"
                                  @click.stop="selectRemove(props.item, hazard)"
                                  v-on="on"
                                >delete</v-icon>
                              </template>
                              <span>{{ $t('Maßnahme löschen') }}</span>
                            </v-tooltip>

                            <v-tooltip bottom v-if="(props.item.hasOwnProperty('version_id') && props.item.version_id !== null)">
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  class="mr-2"
                                  color="secondary"
                                  @click.stop="selectVersion(props.item, hazard)"
                                  v-on="on"
                                >history</v-icon>
                              </template>
                              <span>{{ $t('Versionen der Maßnahme anzeigen') }}</span>
                            </v-tooltip>
                          </td>
                        </tr>
                      </template>
                      <template slot="no-data">
                        <em>{{ $t('Keine Maßnahmen zur Anzeige vorhanden.') }}</em>
                      </template>
                      <template slot="no-results">
                        <em>{{ $t('Ihre Suche nach "%{search}" hat keine Daten gefunden.', {search: search}) }}</em>
                      </template>
                    </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-tab-item>

          <v-tab-item value="tab-1" class="pt-3 mt-4">
              <v-data-table
                :headers="headers"
                :items="getActions()"
                :search="search"
                hide-default-footer
                item-key="name"
              >
                <template slot="item" slot-scope="props">
                  <tr :class="{finished:isFinished(props.item), 'not-assigned':!isAssigned(props.item)}">
                    <td><formatter :value="props.item.name" type="localized"></formatter></td>
                    <td>
                      <ul v-if="props.item.hazard_dangers && props.item.hazard_dangers.length > 0">
                        <li v-for="(d, index) in props.item.hazard_dangers.map(danger => danger.name.de)" :key="index">{{ d }}</li>
                      </ul>
                    </td>
                    <td>
                      <template v-if="isAssigned(props.item)">
                        <v-layout
                          row
                          wrap
                          align-content-center
                          v-if="!isFinished(props.item)"
                        >
                          <v-flex align-self-center xs6>
                            <v-progress-linear v-model="props.item.implemented" height="12"></v-progress-linear>
                          </v-flex>
                          <v-flex align-self-center xs6>
                            <span class="ml-1">{{ props.item.implemented||0 }}%</span>
                          </v-flex>
                        </v-layout>

                        <template v-else>
                          <v-tooltip bottom v-if="hasStatus(props.item, 'STATUS_EFFECTIVE')">
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on" color="success">thumb_up</v-icon>
                            </template>
                            <span>{{ $t('Wirksamkeit wurde erreicht') }}</span>
                          </v-tooltip>

                          <v-tooltip bottom v-else-if="hasStatus(props.item, 'STATUS_INEFFECTIVE')">
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on" color="warning">thumb_down</v-icon>
                            </template>
                            <span>{{ $t('Wirksamkeit wurde nicht erreicht') }}</span>
                          </v-tooltip>

                          <v-tooltip bottom v-else>
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on" color="grey lighten-2">thumbs_up_down</v-icon>
                            </template>
                            <span>{{ $t('Wirksamkeitsprüfung steht noch aus') }}</span>
                          </v-tooltip>
                        </template>
                      </template>
                    </td>
                    <td><span v-if="props.item.implementation_responsible">{{ props.item.implementation_responsible.name }}</span></td>
                    <td><formatter :value="props.item.implementation_deadline" type="date"></formatter></td>
                    <td class="px-0 py-2" style="white-space: nowrap;">
                      <v-tooltip bottom v-if="!isAssigned(props.item) && !isGranted(['ROLE_IMPLEMENTER'])">
                        <template v-slot:activator="{ on }">
                          <v-icon
                            class="mr-2"
                            color="primary"
                            @click.stop="assignExecute(props.item, props.item.hazard)"
                            v-on="on"
                          >input</v-icon>
                        </template>
                        <span>{{ $t('Maßnahme zuweisen') }}</span>
                      </v-tooltip>
                      <v-tooltip bottom v-else-if="props.item.implemented < 100 && !isFinished(props.item)">
                        <template v-slot:activator="{ on }">
                          <v-icon
                            class="mr-2"
                            color="primary"
                            @click.stop="selectExecute(props.item, props.item.hazard)"
                            v-on="on"
                          >input</v-icon>
                        </template>
                        <span>{{ $t('Maßnahme durchführen') }}</span>
                      </v-tooltip>

                      <v-tooltip bottom v-if="props.item.implemented === 100 && !isFinished(props.item) && !isGranted(['ROLE_IMPLEMENTER'])">
                        <template v-slot:activator="{ on }">
                          <v-icon
                            class="mr-2"
                            color="primary"
                            @click.stop="selectEffectiveness(props.item, props.item.hazard)"
                            v-on="on"
                          >input</v-icon>
                        </template>
                        <span>{{ $t('Wirksamkeit der Maßnahme überprüfen') }}</span>
                      </v-tooltip>

                      <v-tooltip bottom v-if="isFinished(props.item) && !isGranted(['ROLE_IMPLEMENTER'])">
                        <template v-slot:activator="{ on }">
                          <v-icon
                            class="mr-2"
                            color="secondary"
                            @click.stop="selectReactivate(props.item, props.item.hazard)"
                            v-on="on"
                          >autorenew</v-icon>
                        </template>
                        <span>{{ $t('Maßnahme reaktivieren') }}</span>
                      </v-tooltip>

                      <v-tooltip bottom v-if="!isFinished(props.item) && (!props.item.implemented || props.item.implemented === 0) && !isGranted(['ROLE_IMPLEMENTER'])">
                        <template v-slot:activator="{ on }">
                          <v-icon
                            class="mr-2"
                            color="primary"
                            @click.stop="selectRemove(props.item, props.item.hazard)"
                            v-on="on"
                          >delete</v-icon>
                        </template>
                        <span>{{ $t('Maßnahme löschen') }}</span>
                      </v-tooltip>

                      <v-tooltip bottom v-if="props.item.hasOwnProperty('version_id') && props.item.version_id !== null">
                        <template v-slot:activator="{ on }">
                          <v-icon
                            class="mr-2"
                            color="primary"
                            @click.stop="selectVersion(props.item, props.item.hazard)"
                            v-on="on"
                          >history</v-icon>
                        </template>
                        <span>{{ $t('Versionen der Maßnahme anzeigen') }}</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </template>
                <template slot="no-data">
                  <em>{{ $t('Keine Maßnahmen zur Anzeige vorhanden.') }}</em>
                </template>
                <template slot="no-results">
                  <em>{{ $t('Ihre Suche nach "%{search}" hat keine Daten gefunden.', { search: search }) }}</em>
                </template>
              </v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>

    </v-card-text>

    <v-dialog
      v-model="deleteDialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="360"
    >
      <v-card>
        <v-card-title class="headline text-xs-center">{{ $t('Möchten Sie die Maßnahme unwiderruflich löschen?') }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="deleteDialog = false" text>{{ $t('Nein') }}</v-btn>
          <v-btn color="red darken-1" @click="remove()" text>{{ $t('Ja') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="editDialog"
      transition="dialog-bottom-transition"
      scrollable
      max-width="1096"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-card tile>
        <v-app-bar flat dark color="primary">
            <v-btn icon dark @click.native="editDialog = false">
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title v-if="action && !isAssigned(action)">{{ $t('Maßnahme zuweisen') }}</v-toolbar-title>
            <v-toolbar-title v-else-if="actionIndex === null">{{ $t('Weitere Maßnahme erfassen') }}</v-toolbar-title>
            <v-toolbar-title v-else>{{ $t('Maßnahme aktualisieren') }}</v-toolbar-title>
        </v-app-bar>

        <v-card-text class="pt-3">
          <v-form v-model="valid" ref="form">
            <v-select
              :items="hazardAssessment.hazards"
              item-value="id"
              item-text="name.de"
              v-model="hazard"
              return-object
              :label="$t('Gefährdung')"
              :hint="$t('Bitte wählen Sie die Gefährdung, der Sie eine Maßnahme hinzufügen möchten:')"
              persistent-hint
              :rules="rulesHazard"
            >
              <template slot="selection" slot-scope="data">
                {{ data.item.name[locale] }}
              </template>
              <template slot="items" slot-scope="data">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ data.item.name[locale] }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-select>

            <v-subheader class="mt-2">{{ $t('Gefährdungen') }}</v-subheader>
            <v-layout wrap v-if="hazard">
              <template v-if="hazard.hazard_dangers.length > 0">
                <v-flex
                  xs4
                  v-for="(d, index) in hazard.hazard_dangers"
                  :key="index"
                >
                  <v-checkbox
                    v-model="action.hazard_dangers"
                    :label="d.name.de"
                    :value="d"
                    :rules="actionDangerRules"
                  ></v-checkbox>
                </v-flex>
              </template>
              <template v-else>
                <em>Es sind keine Gefährdungen definiert!</em>
              </template>
            </v-layout>

            <v-text-field
              :label="$t('Bezeichnung der Maßnahme')"
              v-model="action.name[locale]"
              rows="1"
              auto-grow
              :rules="rulesName"
              :disabled="!isHazardSelected"
            >
            </v-text-field>

            <v-textarea
              :label="$t('Beschreibung der Maßnahme')"
              v-model.trim="action.description[locale]"
              rows="2"
              auto-grow
              :rules="rulesDescription"
              :disabled="!isHazardSelected"
            >
            </v-textarea>

            <v-select
              :disabled="!isHazardSelected"
              :items="actiontypes"
              v-model="action.hazard_action_type"
              :label="$t('Maßnahmentyp') + ':'"
              :rules="rulesActionType"
              item-value="id"
              item-text="name.de"
              return-object
            >
              <template
                 slot="selection"
                 slot-scope="{ item }"
              >
                {{ item.name[locale].charAt(0).toUpperCase() }}&nbsp;<small>({{ item.name[locale] }})</small>
              </template>
              <template slot="items" slot-scope="data">
               <v-list-item-title>
                 {{ data.item.name[locale].charAt(0).toUpperCase() }}&nbsp;<small>({{ data.item.name[locale] }})</small>
               </v-list-item-title>
             </template>
            </v-select>

            <v-switch
              :label="$t('Unterweisungsrelevante Maßnahme')"
              v-model="action.instruction_relevant"
            ></v-switch>

            <v-select
              :label="$t('Umsetzungsverantwortlicher')"
              v-model="action.implementation_responsible"
              :items="responsiblesFiltered"
              :rules="responsibleRules"
              :disabled="!isHazardSelected"
              item-text="name"
              item-value="id"
              required
            ></v-select>

            <v-menu
              ref="dateMenuDeadline"
              :close-on-content-click="false"
              v-model="dateMenuDeadline"
              :nudge-right="40"
              :disabled="!isHazardSelected"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateDeadlineFormatted"
                  :label="$t('Umsetzungsfrist')"
                  :disabled="!isHazardSelected"
                  prepend-icon="event"
                  readonly
                  :rules="requiredDate"
                  @blur="dateDeadline = parseDate(dateDeadlineFormatted)"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateDeadline"
                :locale="locale"
                :first-day-of-week="1"
                @input="dateMenuDeadline = false"
              ></v-date-picker>
            </v-menu>

            <file-uploader v-model="action.files" :disabled="!isHazardSelected"></file-uploader>

          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click.native="editDialog = false">
            {{ $t('Abbrechen') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="save()"
            v-if="actionIndex === null"
            :disabled="!valid"
          >
            <v-icon left>save</v-icon> {{ $t('Maßnahme speichern') }}
          </v-btn>
          <v-btn
            color="primary"
            @click="save()"
            v-if="actionIndex !== null"
            :disabled="!valid"
          >
            <v-icon left>save</v-icon> {{ $t('Maßnahme aktualisieren') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="executeDialog"
      transition="dialog-bottom-transition"
      scrollable
      max-width="1096"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-card tile>
        <v-app-bar flat dark color="primary">
            <v-btn icon dark @click.native="executeDialog = false">
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ $t('Maßnahme durchführen') }}</v-toolbar-title>
        </v-app-bar>

        <v-card-text class="pt-3">
          <v-form v-model="validExecute" ref="validExecute" v-if="hazard && hazard.id">
            <v-layout wrap class="mt-3">
              <v-flex sm12 md6>
                <p class="subheading font-weight-medium mb-0">{{ $t('Gefährdung') }}</p>
                <p><formatter :value="hazard.name" type="localized"></formatter></p>

                <p class="subheading font-weight-medium mb-0">{{ $t('Maßnahmentyp') }}</p>
                <p>
                  <template v-if="action.hazard_action_type && action.hazard_action_type.name && action.hazard_action_type.name[locale]">
                    {{ action.hazard_action_type.name[locale].charAt(0).toUpperCase() }}&nbsp;<small>({{ action.hazard_action_type.name[locale] }})</small>
                  </template>
                </p>
              </v-flex>
              <v-flex sm12 md6>
                <p class="subheading font-weight-medium mb-0">{{ $t('Maßnahme') }}</p>
                <p>
                  <span class="subheading"><formatter :value="action.name" type="localized"></formatter>:</span>
                  <formatter :value="action.description" type="localized"></formatter>
                </p>
                <p class="subheading font-weight-medium mb-0">{{ $t('Unterweisungsrelevant') }}</p>
                <p>
                  <template v-if="action.instruction_relevant">
                    {{ $t('Ja') }}
                  </template>
                  <template v-else>
                    {{ $t('Nein') }}
                  </template>
                </p>
              </v-flex>
            </v-layout>

            <v-divider class="mt-3" />
            <h2 class="headline mt-2">{{ $t('Rückmeldung') }}</h2>

            <v-subheader class="pl-0">{{ $t('Status der Abarbeitung in %') }}</v-subheader>
            <v-slider
              v-model="action.implemented"
              :min="0"
              :max="100"
              :step="25"
              :label="action.implemented + '%'"
              ticks="always"
              inverse-label
              class="mt-0 ml-4 mr-2"
            ></v-slider>

            <v-layout wrap justify-space-between>
              <v-flex sm12 md5>
                <v-menu
                  ref="dateMenuExecute"
                  :close-on-content-click="false"
                  v-model="dateMenuExecute"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateExecuteFormatted"
                      :label="$t('Datum der Umsetzung')"
                      :rules="requiredDate"
                      prepend-icon="event"
                      readonly
                      @blur="dateExecute = parseDate(dateExecuteFormatted)"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateExecute"
                    :locale="locale"
                    :first-day-of-week="1"
                    :max="todayMax"
                    @input="dateMenuExecute = false"
                  ></v-date-picker>
                </v-menu>

              </v-flex>
              <v-flex sm12 md6>
                <v-select
                  :label="$t('Durchführende Person')"
                  v-model="action.report_responsible"
                  :items="responsibles"
                  :rules="responsibleRules"
                  item-text="name"
                  item-value="id"
                  required
                ></v-select>

              </v-flex>
            </v-layout>

            <v-textarea
              :label="$t('Bemerkung')"
              auto-grow
              :rows="1"
              v-model.trim="action.report_comment"
            >
            </v-textarea>

            <file-uploader v-model="action.report_files" :disabled="!isHazardSelected"></file-uploader>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click.native="executeDialog = false">
            {{ $t('Abbrechen') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" :disabled="!validExecute" @click="execute()">
            <v-icon left>save</v-icon> {{ $t('Rückmeldung speichern') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="effectivenessDialog"
      transition="dialog-bottom-transition"
      scrollable
      max-width="1096"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-card tile>
        <v-app-bar flat dark color="primary">
            <v-btn icon dark @click.native="effectivenessDialog = false">
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ $t('Wirksamkeit überprüfen') }}</v-toolbar-title>
        </v-app-bar>

        <v-card-text class="pt-3">
          <v-form v-model="validEffectiveness" ref="validEffectiveness">
            <template v-if="hazard && hazard.id">
              <v-layout wrap class="mt-3">
                <v-flex sm12 md6>
                  <p class="subheading font-weight-medium mb-0">{{ $t('Gefährdung') }}</p>
                  <p><formatter :value="hazard.name" type="localized"></formatter></p>

                  <p class="subheading font-weight-medium mb-0">{{ $t('Maßnahmentyp') }}</p>
                  <p>
                    <template v-if="action.hazard_action_type && action.hazard_action_type.name && action.hazard_action_type.name[locale]">
                      {{ action.hazard_action_type.name[locale].charAt(0).toUpperCase() }}&nbsp;<small>({{ action.hazard_action_type.name[locale] }})</small>
                    </template>
                  </p>

                  <p class="subheading font-weight-medium mb-0">{{ $t('Durchführende Person') }}</p>
                  <p>
                    <template v-if="action.report_responsible">
                      {{ action.report_responsible.name }}
                    </template>
                  </p>

                </v-flex>
                <v-flex sm12 md6>
                  <p class="subheading font-weight-medium mb-0">{{ $t('Maßnahme') }}</p>
                  <p>
                    <span class="body-2"><formatter :value="action.name" type="localized"></formatter>:</span>
                    <formatter :value="action.description" type="localized"></formatter>
                  </p>

                  <p class="subheading font-weight-medium mb-0">{{ $t('Terminvorgabe') }}</p>
                  <p><formatter :value="action.report_date" type="date" v-if="action.report_date"></formatter></p>

                  <p class="subheading font-weight-medium mb-0">{{ $t('Bemerkung') }}</p>
                  <p><template v-if="action.report_comment">{{ action.report_comment }}</template></p>

                </v-flex>
              </v-layout>

              <v-layout wrap class="mb-4">
                <v-flex sm12>
                  <v-divider class="mb-4"></v-divider>
                  <h2>{{ $t('Wirksamkeit prüfen') }}</h2>
                </v-flex>
              </v-layout>

              <v-layout wrap justify-space-between>
                <v-flex sm12 md2>
                  <div class="subheading grey--text text--darken-1">{{ $t('Wirksamkeit erreicht?') }}</div>
                  <v-radio-group
                    v-model="effectivenessStatus"
                    :rules="requiredEffectivenessStatus"
                    row
                    class="mt-1"
                  >
                    <v-radio
                      v-for="n in effectivenessStatusList"
                      :key="n.value"
                      :label="n.label"
                      :value="n.value"
                    ></v-radio>
                  </v-radio-group>
                </v-flex>

                <v-flex sm12 md3>
                  <v-menu
                    ref="dateMenuEffectiveness"
                    :close-on-content-click="false"
                    v-model="dateMenuEffectiveness"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateEffectivenessFormatted"
                        :label="$t('Datum der Prüfung')"
                        :rules="requiredDate"
                        prepend-icon="event"
                        readonly
                        @blur="dateEffectiveness = parseDate(dateEffectivenessFormatted)"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dateEffectiveness"
                      :locale="locale"
                      :first-day-of-week="1"
                      :max="todayMax"
                      :min="action.report_date"
                      @input="dateMenuEffectiveness = false"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>

                <v-flex sm12 md4>
                  <v-textarea
                    :label="$t('Bemerkung')"
                    auto-grow
                    :rows="1"
                    v-model.trim="action.effectiveness_comment"
                    :rules="[rules.requiredComment]"
                  >
                  </v-textarea>
                </v-flex>
              </v-layout>
            </template>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click.native="effectivenessDialog = false">
            {{ $t('Abbrechen') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" :disabled="!validEffectiveness" @click="effectiveness()">
            <v-icon left>save</v-icon> {{ $t('Wirksamkeitsprüfung speichern') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="versionsDialog"
      transition="dialog-bottom-transition"
      scrollable
      max-width="1096"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-card tile>
        <v-app-bar flat dark color="primary">
            <v-btn icon dark @click.native="versionsDialog = false">
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ $t('Vorherige Versionen der Maßnahme') }}</v-toolbar-title>
        </v-app-bar>

        <v-card-text class="pt-3">

          <v-expansion-panels
            v-model="versionsPanel"
            multiple
          >
            <v-expansion-panel
              v-for="(action, i) in actionVersions"
              :key="i"
            >
              <v-expansion-panel-header>
                <template v-slot:actions>
                  <v-chip outlined color="success" v-if="hasStatus(action, 'STATUS_EFFECTIVE')">
                    <v-icon left color="success" class="status">thumb_up</v-icon>
                    {{ $t('Wirksamkeit wurde erreicht') }}
                  </v-chip>
                  <v-chip outlined color="warning" v-else-if="hasStatus(action, 'STATUS_INEFFECTIVE')">
                    <v-icon left color="warning" class="status">thumb_down</v-icon>
                    {{ $t('Wirksamkeit wurde nicht erreicht') }}
                  </v-chip>
                  <v-chip>
                    <formatter :value="action.updated" type="date" />
                  </v-chip>
                  <v-icon>$vuetify.icons.expand</v-icon>
                </template>

                <formatter :value="action.name" type="localized"></formatter>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-card>
                  <v-card-text class="pt-0">
                    <v-layout wrap>
                      <v-flex sm12 md6>
                        <p class="body-2 font-weight-medium mb-0">{{ $t('Beschreibung') }}</p>
                        <p><formatter :value="action.description" type="localized" /></p>
                      </v-flex>
                      <v-flex sm12 md6>
                        <p class="body-2 font-weight-medium mb-0">{{ $t('Maßnahmentyp') }}</p>
                        <p>
                          <template v-if="action.hazard_action_type && action.hazard_action_type.name && action.hazard_action_type.name[locale]">
                            {{ action.hazard_action_type.name[locale].charAt(0).toUpperCase() }}&nbsp;<small>({{ action.hazard_action_type.name[locale] }})</small>
                          </template>
                        </p>
                      </v-flex>
                    </v-layout>
                    <v-layout wrap>
                      <v-flex sm12 md6>
                        <p class="body-2 font-weight-medium mb-0">{{ $t('Maßnahmenverantwortlicher') }}</p>
                        <p><span v-if="action.implementation_responsible">{{ action.implementation_responsible.name }}</span></p>
                      </v-flex>
                      <v-flex sm12 md6>
                        <p class="body-2 font-weight-medium mb-0">{{ $t('Datumsvorgabe für Umsetzung') }}</p>
                        <p><formatter :value="action.implementation_deadline" type="date" /></p>
                      </v-flex>
                    </v-layout>
                    <v-layout wrap>
                      <v-flex sm12 md6>
                        <h3>{{ $t('Umsetzung') }}</h3>
                        <p class="body-2 font-weight-medium mb-0">{{ $t('Durchführende Person') }}</p>
                        <p><span v-if="action.report_responsible">{{ action.report_responsible.name }}</span></p>
                        <p class="body-2 font-weight-medium mb-0">{{ $t('Datum') }}</p>
                        <p><formatter :value="action.report_date" type="date" /></p>
                        <p class="body-2 font-weight-medium mb-0">{{ $t('Bemerkung') }}</p>
                        <p><template v-if="action.report_comment">{{ action.report_comment }}</template></p>
                      </v-flex>
                      <v-flex sm12 md6>
                        <h3>{{ $t('Wirksamkeit') }}</h3>
                        <p class="body-2 font-weight-medium mb-0">{{ $t('Wirksam?') }}</p>
                        <p>
                          <v-tooltip bottom v-if="hasStatus(action, 'STATUS_EFFECTIVE')">
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on" color="success">thumb_up</v-icon>
                            </template>
                            <span>{{ $t('Wirksamkeit wurde erreicht') }}</span>
                          </v-tooltip>

                          <v-tooltip bottom v-else-if="hasStatus(action, 'STATUS_INEFFECTIVE')">
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on" color="warning">thumb_down</v-icon>
                            </template>
                            <span>{{ $t('Wirksamkeit wurde nicht erreicht') }}</span>
                          </v-tooltip>
                        </p>
                        <p class="body-2 font-weight-medium mb-0">{{ $t('Datum') }}</p>
                        <p><formatter :value="action.effectiveness_date" type="date" /></p>
                        <p class="body-2 font-weight-medium mb-0">{{ $t('Bemerkung') }}</p>
                        <p><template v-if="action.effectiveness_comment">{{ action.effectiveness_comment }}</template></p>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click.native="versionsDialog = false">
            {{ $t('Schließen') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      :timeout="4000"
      top
      multi-line
      v-model="snackbar"
      color="green"
    >
      {{ snackbarContent }}

      <v-btn text @click.native="snackbar = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>

    <!-- v-dialog
      v-model="reactivateDialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="450"
    >
      <v-card>
        <v-card-title class="headline text-xs-center">{{ $t('Möchten Sie die Maßnahme reaktivieren?') }}</v-card-title>
        <v-card-text class="subheading text-xs-center">
          {{ $t('Es wird eine Kopie der Maßnahme erstellt, die Sie neu durchführen können.') }}<br>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red darken-1" @click="reactivateDialog = false" text>{{ $t('Nein') }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success darken-1" @click="selectReactivate()" text>{{ $t('Ja, reaktivieren') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog -->

    <v-dialog
      v-model="reactivateDialog"
      transition="dialog-bottom-transition"
      scrollable
      max-width="1096"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-card tile>
        <v-app-bar flat dark color="primary">
            <v-btn icon dark @click.native="reactivateDialog = false">
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ $t('Maßnahme reaktivieren') }}</v-toolbar-title>
        </v-app-bar>

        <v-card-text class="pt-3">
          <v-form v-model="validReactivate" ref="validExecute" v-if="hazard && hazard.id">
            <v-layout wrap>
              <v-flex sm12 md6>
                <p class="subheading font-weight-medium mb-0">{{ $t('Gefährdung') }}</p>
                <p><formatter :value="hazard.name" type="localized"></formatter></p>

                <p class="subheading font-weight-medium mb-0">{{ $t('Maßnahmentyp') }}</p>
                <p>
                  <template v-if="action.hazard_action_type && action.hazard_action_type.name && action.hazard_action_type.name[locale]">
                    {{ action.hazard_action_type.name[locale].charAt(0).toUpperCase() }}&nbsp;<small>({{ action.hazard_action_type.name[locale] }})</small>
                  </template>
                </p>
              </v-flex>
              <v-flex sm12 md6>
                <p class="subheading font-weight-medium mb-0">{{ $t('Maßnahme') }}</p>
                <p>
                  <span class="subheading"><formatter :value="action.name" type="localized"></formatter>:</span>
                  <formatter :value="action.description" type="localized"></formatter>
                </p>
                <p class="subheading font-weight-medium mb-0">{{ $t('Unterweisungsrelevant') }}</p>
                <p>
                  <template v-if="action.instruction_relevant">
                    {{ $t('Ja') }}
                  </template>
                  <template v-else>
                    {{ $t('Nein') }}
                  </template>
                </p>
              </v-flex>
            </v-layout>

            <h2>{{ $t('Reaktivierung') }}</h2>

            <v-select
              :label="$t('Umsetzungsverantwortlicher')"
              v-model="action.implementation_responsible"
              :items="responsibles"
              :rules="responsibleRules"
              :disabled="!isHazardSelected"
              item-text="name"
              item-value="id"
              required
            ></v-select>

            <v-menu
              ref="dateMenuReactivate"
              :close-on-content-click="false"
              v-model="dateMenuReactivate"
              :nudge-right="40"
              :disabled="!isHazardSelected"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateReactivateFormatted"
                  :label="$t('Umsetzungsfrist')"
                  :disabled="!isHazardSelected"
                  prepend-icon="event"
                  readonly
                  :rules="requiredDate"
                  @blur="dateReactivate = parseDate(dateReactivateFormatted)"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateReactivate"
                :locale="locale"
                :first-day-of-week="1"
                @input="dateMenuReactivate = false"
              ></v-date-picker>
            </v-menu>

            <file-uploader v-model="action.files" :disabled="!isHazardSelected"></file-uploader>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click.native="reactivateDialog = false">
            {{ $t('Reaktivierung abbrechen') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success darken-1" :disabled="!validReactivate" @click="selectReactivate()" text>
            {{ $t('Ja, Maßnahme reaktivieren') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Moment from 'moment'
import formatter from '../../helpers/Formatter'
import Status from './Status.vue'
import { hazardactionService, userService } from '../../../services'
import { hazardRating } from '../../../helpers'
import fileUploader from '../../helpers/FileUploader'

export default {
  data () {
    return {
      rules: {
        requiredComment: value => {
          if (this.hasStatus(this.action, 'STATUS_INEFFECTIVE') || this.effectivenessStatus === 'STATUS_INEFFECTIVE') {
            return !!value || this.$t('Bitte geben Sie eine Bemerkung an.')
          }
          return true
        }
      },
      rulesName: [v => !!v || this.$t('Bitte geben Sie eine Bezeichnung an.')],
      rulesHazard: [v => (!!v && v.id !== null) || this.$t('Bitte wählen Sie eine Gefährdung aus.')],
      rulesDescription: [v => !!v || this.$t('Bitte geben Sie eine Beschreibung an.')],
      rulesActionType: [v => !!v || this.$t('Bitte wählen Sie einen Maßnahmentp aus.')],
      responsibleRules: [v => !!v || this.$t('Bitte wählen Sie einen Verantwortlichen aus.')],
      requiredEffectivenessStatus: [v => !!v || this.$t('Bitte geben Sie an ob die Wirksamkeit erreicht wurde.')],
      requiredDate: [v => !!v || this.$t('Bitte geben Sie ein Datum an.')],
      actionDangerRules: [() => (this.action.hazard_dangers.length > 0) || this.$t('Bitte wählen Sie mindestens eine Gefährdung.')],
      search: '',
      tabview: 'tab-0',
      executionToggle: null,
      effectivenessToggle: null,
      form: true,
      editDialog: false,
      executeDialog: false,
      deleteDialog: false,
      reactivateDialog: false,
      effectivenessDialog: false,
      versionsDialog: false,
      versionsPanel: [],
      hazard: {},
      action: {},
      todayMax: Moment().format(),
      todayMin: Moment().subtract(1, 'day').format(),
      dateMenuEffectiveness: false,
      dateEffectiveness: null,
      dateEffectivenessFormatted: null,
      dateMenuReactivate: false,
      dateReactivate: null,
      dateReactivateFormatted: null,
      dateMenuExecute: false,
      dateDeadline: null,
      dateDeadlineFormatted: null,
      dateMenuDeadline: false,
      oldReportDate: null,
      dateExecute: null,
      dateExecuteFormatted: null,
      validEffectiveness: true,
      validExecute: true,
      validReactivate: true,
      effectivenessStatus: null,
      date: null,
      hazardIndex: null,
      actionIndex: null,
      snackbar: false,
      snackbarContent: this.$t('Die Maßnahme wurde gespeichert.'),
      effectivenessStatusList: [
        { value: 'STATUS_EFFECTIVE', label: this.$t('Ja') },
        { value: 'STATUS_INEFFECTIVE', label: this.$t('Nein') }
      ],
      actionEmpty: null,
      action_status: ['0', 25, 50, 75, 100],
      actionVersions: [],
      panel: 0
    }
  },
  props: [
    'hazardAssessment',
    'hazardAssessmentObject',
    'actiontypes',
    'responsibles',
    'isValid',
    'settings'
  ],
  created () {
    let hazardEmpty = JSON.parse(JSON.stringify(this.hazardAssessmentObject.hazards[0]))
    this.actionEmpty = JSON.parse(JSON.stringify(hazardEmpty.actions[0]))
    this.actionEmpty.status_history = ['STATUS_OPEN']
    this.actionEmpty.implemented = 0
    this.actionEmpty.effectiveness_status = null
    this.action = JSON.parse(JSON.stringify(this.actionEmpty))
    hazardEmpty.actions = []
    this.hazard = hazardEmpty
    this.hazardEmpty = JSON.parse(JSON.stringify(hazardEmpty))
  },
  watch: {
    effectivenessStatus () {
      this.$refs['validEffectiveness'].resetValidation()
    },
    dateEffectiveness () {
      if (this.action !== null) {
        this.action.effectiveness_date = this.dateEffectiveness
        this.dateEffectivenessFormatted = this.formatDate(this.action.effectiveness_date)
      } else {
        this.dateEffectivenessFormatted = null
      }
    },
    dateExecute () {
      if (this.action !== null) {
        this.action.report_date = this.dateExecute
        let today = Moment()
        if (Moment(this.action.report_date, 'YYYY-MM-DD').isAfter(today)) {
          this.action.report_date = today.format('YYYY-MM-DD')
        }
        this.dateExecuteFormatted = this.formatDate(this.action.report_date)
      } else {
        this.dateExecuteFormatted = null
      }
    },
    dateDeadline () {
      if (this.action !== null) {
        this.action.implementation_deadline = this.dateDeadline
        this.dateDeadlineFormatted = this.formatDate(this.action.implementation_deadline)
      } else {
        this.dateDeadlineFormatted = null
      }
    },
    dateReactivate () {
      if (this.action !== null) {
        this.action.implementation_deadline = this.dateReactivate
        this.dateReactivateFormatted = this.formatDate(this.action.implementation_deadline)
      } else {
        this.dateReactivateFormatted = null
      }
    },
    action: function () {
      this.$emit('isValid', this.validates())
    },
    settings: {
      immediate: true,
      handler () {
        if (this.settings) {
          if (typeof this.settings.executionToggle !== 'undefined') {
            this.executionToggle = this.settings.executionToggle
          }
          if (typeof this.settings.effectivenessToggle !== 'undefined') {
            this.effectivenessToggle = this.settings.effectivenessToggle
          }
          if (typeof this.settings.executeView !== 'undefined') {
            this.tabview = this.settings.executeView ? 'tab-1' : 'tab-0'
          }
        }
      }
    }
  },
  computed: {
    locale () {
      return this.$store.getters['locale/currentLocale']
    },
    minDateExecute () {
      if (this.oldReportDate === null) {
        return Moment().format()
      }
      return Moment(this.oldReportDate, 'YYYY-MM-DD').subtract(1, 'day').format()
    },
    isHazardSelected () {
      return this.hazard && this.hazard.id !== null
    },
    headers () {
      let headers = JSON.parse(JSON.stringify([
        {
          text: this.$t('Bezeichnung'),
          align: 'left',
          sortable: true,
          value: `name.${this.locale}`
        },
        {
          text: this.$t('Gefährdung'),
          align: 'left',
          sortable: true,
          value: `hazard.name.${this.locale}`
        },
        {
          text: this.$t('Status'),
          align: 'left',
          sortable: true,
          value: 'status'
        },
        {
          text: this.$t('Verantwortlicher'),
          align: 'left',
          sortable: true,
          value: 'responsible'
        },
        {
          text: this.$t('Umsetzungsfrist'),
          align: 'left',
          sortable: true,
          value: 'date'
        },
        { text: this.$t('Aktion'), value: 'name', sortable: false }
      ]))
      return headers
    },
    valid: {
      set (val) {
        this.form = val
      },
      get () {
        return this.form
      }
    },
    responsiblesFiltered () {
      /* Bei unterweisungsrelevanten Maßnahmen kommen nur ROLE_MANAGER alsUmsetzungsverantwortliche in Frage */
      if (this.action !== null && this.action.instruction_relevant === true) {
        return this.responsibles.filter(responsible => responsible.roles.length > 0 && responsible.roles.indexOf('ROLE_MANAGER') >= 0)
      }
      return this.responsibles
    }
  },
  methods: {
    validates () {
      let rtn = true
      this.hazardAssessment.hazards.forEach(hazard => {
        if (hazard.actions.length === 0 && hazard.risk_assessment >= 20) {
          rtn = false
        } else if (hazard.actions.length > 0) {
          hazard.actions.forEach(item => {
            if (!this.isFinished(item)) {
              rtn = false
            }
          })
        }
      })
      return rtn
    },
    hasStatus (action, status) {
      return action.status_history && action.status_history.indexOf(status) >= 0
    },
    newAction () {
      this.resetSelect()
      if(typeof this.$refs['form'] !== 'undefined') {
        this.$refs['form'].resetValidation()
      }
      if (this.hazardAssessment.hazards.length === 1) {
        this.hazardIndex = 0
        this.hazard = this.hazardAssessment.hazards[this.hazardIndex]
      }
      this.editDialog = true
    },
    resetSelect () {
      this.hazardIndex = null
      this.hazard = JSON.parse(JSON.stringify(this.hazardEmpty))
      this.actionIndex = null
      this.action = JSON.parse(JSON.stringify(this.actionEmpty))
    },
    selectAction (action, hazard) {
      if (typeof hazard === 'undefined') {
        hazard = this.getHazardByAction(action)
      }
      this.hazardIndex = this.hazardAssessment.hazards.indexOf(hazard)
      this.hazard = this.hazardAssessment.hazards[this.hazardIndex]
      if (typeof action.hazard !== 'undefined') {
        delete action.hazard
      }
      let a = this.hazard.actions.filter(item => { return action.id === item.id })
      this.actionIndex = this.hazard.actions.indexOf(a.shift())
      this.action = JSON.parse(JSON.stringify(this.hazard.actions[this.actionIndex]))
      if (isNaN(this.action.implemented)) {
        this.action.implemented = 0
      }
      this.oldReportDate = null
    },
    save () {
      if (this.valid) {
        this.hazardIndex = this.hazardAssessment.hazards.indexOf(this.hazard)
        this.action.hazard = this.hazard
        hazardactionService.save(this.action).then(
          result => {
            let {dispatch} = this.$store
            dispatch('alert/success', 'Die Maßnahme wurde gespeichert.', {root: true})
            if (this.actionIndex === null) {
              this.actionIndex = this.hazardAssessment.hazards[this.hazardIndex].actions.length
            }
            this.$set(this.hazardAssessment.hazards[this.hazardIndex].actions, this.actionIndex, JSON.parse(JSON.stringify(result)))
            this.$refs['form'].reset()
            this.$emit('isValid', this.validates())
          },
          error => {
            let {dispatch} = this.$store
            dispatch('alert/error', error.message, {root: true})
          }
        ).then(() => {
          this.resetSelect()
        })
        this.editDialog = false
      }
    },
    selectReactivate (action, hazard) {
      if (typeof action !== 'undefined' && typeof hazard !== 'undefined') {
        this.selectAction(action, hazard)
      }
      if (!this.reactivateDialog) {
        this.reactivateDialog = true
      } else if (this.action !== null && this.action.id !== null) {
        hazardactionService.reactivate(this.action).then(
          result => {
            let {dispatch} = this.$store
            dispatch('alert/success', 'Die Maßnahme wurde reaktiviert.', {root: true})
            this.$set(this.hazardAssessment.hazards[this.hazardIndex].actions, this.actionIndex, JSON.parse(JSON.stringify(result)))
            this.$emit('isValid', this.validates())
          },
          error => {
            let {dispatch} = this.$store
            dispatch('alert/error', error.message, {root: true})
          }
        ).then(() => {
          this.resetSelect()
        })
        this.reactivateDialog = false
      }
    },
    assignExecute (action, hazard) {
      this.selectAction(action, hazard)
      if(typeof this.$refs['form'] !== 'undefined') {
        this.$refs['form'].validate()
      }
      this.editDialog = true
    },
    selectExecute (action, hazard) {
      this.selectAction(action, hazard)
      this.oldReportDate = JSON.parse(JSON.stringify(this.action.report_date))
      this.dateExecute = action.report_date
      if (action.report_responsible === null) {
        action.report_responsible = JSON.parse(JSON.stringify(action.implementation_responsible))
      }
      this.executeDialog = true
    },
    selectVersion (action, hazard) {
      this.selectAction(action, hazard)
      hazardactionService.versions(this.action).then(
        result => {
          this.actionVersions = result
          this.versionsDialog = true
        },
        error => {
          this.resetSelect()
          let {dispatch} = this.$store
          dispatch('alert/error', error.message, {root: true})
        }
      )
    },
    execute () {
      hazardactionService.save(this.action).then(
        result => {
          let {dispatch} = this.$store
          dispatch('alert/success', 'Die Maßnahme wurde gespeichert.', {root: true})
          this.$set(this.hazardAssessment.hazards[this.hazardIndex].actions, this.actionIndex, JSON.parse(JSON.stringify(result)))
          this.$emit('isValid', this.validates())
        },
        error => {
          let {dispatch} = this.$store
          dispatch('alert/error', error.message, {root: true})
        }
      ).then(() => {
        this.resetSelect()
      })
      this.executeDialog = false
    },
    selectRemove (action, hazard) {
      this.selectAction(action, hazard)
      this.deleteDialog = true
    },
    remove () {
      if (this.action && this.action.id) {
        hazardactionService.remove(this.action).then(
          result => {
            let {dispatch} = this.$store
            if (typeof result === 'object') {
              dispatch('alert/success', 'Die Maßnahme wurde gelöscht und die vorherige Version wiederhergestellt.', {root: true})
              this.$set(this.hazardAssessment.hazards[this.hazardIndex].actions, this.actionIndex, JSON.parse(JSON.stringify(result)))
            } else {
              dispatch('alert/success', 'Die Maßnahme wurde gelöscht.', {root: true})
              this.hazardAssessment.hazards[this.hazardIndex].actions.splice(this.actionIndex, 1)
            }
            this.$emit('isValid', this.validates())
          },
          error => {
            let {dispatch} = this.$store
            dispatch('alert/error', error.message, {root: true})
          }
        ).then(() => {
          this.resetSelect()
        })
      } else {
        this.hazardAssessment.hazards[this.hazardIndex].actions.splice(this.actionIndex, 1)
        this.resetSelect()
        this.snackbarContent = this.$t('Die Maßnahme wurde gelöscht.')
        this.snackbar = true
        this.$emit('isValid', this.validates())
      }
      this.deleteDialog = false
    },
    selectEffectiveness (action, hazard) {
      this.selectAction(action, hazard)
      this.dateEffectiveness = action.effectiveness_date
      this.effectivenessDialog = true
      this.$nextTick(function () {
        this.$refs['validEffectiveness'].reset()
      })
    },
    effectiveness () {
      if (this.$refs['validEffectiveness'].validate()) {
        this.action.status_name = this.effectivenessStatus
        hazardactionService.save(this.action).then(
          result => {
            let {dispatch} = this.$store
            dispatch('alert/success', 'Die Maßnahme wurde gespeichert.', {root: true})
            this.$set(this.hazardAssessment.hazards[this.hazardIndex].actions, this.actionIndex, JSON.parse(JSON.stringify(result)))
            this.$refs['validEffectiveness'].reset()
            this.$emit('isValid', this.validates())
          },
          error => {
            let {dispatch} = this.$store
            dispatch('alert/error', error.message, {root: true})
          }
        ).then(() => {
          this.resetSelect()
        })
        this.effectivenessDialog = false
      }
    },
    isFinished (item) {
      return this.hasStatus(item, 'STATUS_EFFECTIVE') || this.hasStatus(item, 'STATUS_INEFFECTIVE')
    },
    isAssigned (item) {
      return this.hasStatus(item, 'STATUS_TAKEN') || item.implementation_responsible !== null
    },
    getActions (hazard) {
      let actions = []
      if (hazard) {
        actions = hazard.actions
      } else if (this.hazardAssessment.hazards) {
        this.hazardAssessment.hazards.forEach(hazard => {
          if (hazard.actions) {
            hazard.actions.forEach(action => {
              let a = JSON.parse(JSON.stringify(action))
              a.hazard = hazard
              actions.push(a)
            })
          }
        })
      }
      if (this.executionToggle && this.effectivenessToggle) {
        return actions.filter(action => {
          return action.implemented < 100 || !this.hasStatus(action, 'STATUS_EFFECTIVE')
        })
      }
      if (this.executionToggle) {
        return actions.filter(action => {
          return action.implemented < 100
        })
      }
      if (this.effectivenessToggle) {
        return actions.filter(action => {
          return action.implemented === 100 && !this.hasStatus(action, 'STATUS_EFFECTIVE')
        })
      }
      return actions
    },
    getHazardByAction (action) {
      let hazard = JSON.parse(JSON.stringify(this.hazardEmpty))
      this.hazardAssessment.hazards.forEach(h => {
        if (h.actions && h.actions.indexOf(action)) {
          hazard = h
        }
      })
      return hazard
    },
    formatDate (date) {
      if (!date) return null
      let d = Moment(date, 'YYYY-MM-DD')
      return d.format('L')
    },
    parseDate (date) {
      if (!date) return null

      let d = Moment(date, 'DD.MM.YYYY')
      return `${d.year()}-${(d.month() + 1)}-${d.date()}`
    },
    getRating (hazard) {
      return hazardRating.rating(hazard)
    },
    getHazardPrefix (hazard) {
      let label = this.hazardAssessment.type_description[this.locale] + '; '
      if (typeof hazard.hazard_type !== 'undefined' && hazard.hazard_type !== null) {
        label += hazard.hazard_type.num + '.'
      }
      if (typeof hazard.hazard_factor !== 'undefined' && hazard.hazard_factor !== null) {
        label += hazard.hazard_factor.num + '.'
      }
      label += ' &raquo; '
      return label.replace(/</g, '&lt;').replace(/>/g, '&gt;')
    },
    isGranted (roles) {
      if (roles === null || roles.length === 0) {
        return true
      }
      return userService.isGranted(roles)
    }
  },
  components: {
    formatter,
    Status,
    fileUploader
  }
}
</script>
