import { responseHelper } from '../helpers'
import Vue from 'vue'

export const hazardtypeService = {
  getAll,
  get
}

function getAll () {
  return Vue.prototype.$http.get('/hazardtype')
    .then(response => {
      return response.data
    })
    .catch(error => {
      return responseHelper.handleError(error)
    })
}

function get (id) {
  return Vue.prototype.$http.get('/hazardtype/' + id)
    .then(response => {
      return response.data
    })
    .catch(error => {
      return responseHelper.handleError(error)
    })
}
