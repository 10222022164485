<template>
  <div class="step1">
    <v-app-bar flat>
      <img src="../../../assets/circle-01.svg" alt="" class="circle">
      <v-toolbar-title>
        {{ $t('Arbeitsbereiche und Tätigkeiten festlegen') }}
      </v-toolbar-title>
    </v-app-bar>

    <v-divider></v-divider>

    <v-form v-model="valid" ref="form">
      <v-card-text>
        <v-container grid-list-md>
        <v-layout wrap>
          <v-flex md6>
            <v-text-field
              :label="$t('Betriebsnummer')"
              v-model="hazardAssessment.company.number"
              :rules="noRules"
              :disabled="true"
              required>
            </v-text-field>

            <v-menu
              ref="dateMenu"
              :close-on-content-click="false"
              v-model="dateMenu"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFormatted"
                  :label="$t('Erstelldatum')"
                  :rules="requiredDate"
                  prepend-icon="event"
                  readonly
                  @blur="hazardAssessmentDate = parseDate(dateFormatted)"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="hazardAssessmentDate"
                :locale="locale"
                :first-day-of-week="1"
                :max="today"
                @input="dateMenu = false"
              ></v-date-picker>
            </v-menu>

            <v-text-field
              :label="$t('Standort')"
              v-model="hazardAssessment.location[locale]"
              :rules="locationRules"
              required>
            </v-text-field>

            <v-text-field
              :label="$t('Abteilung')"
              v-model="hazardAssessment.department[locale]"
              :rules="departmentRules"
              required>
            </v-text-field>

            <v-select
              :label="$t('Wiedervorlagezeit')"
              v-model="hazardAssessment.resubmission_interval"
              :items="resubmissionIntervals"
              item-text="name"
              item-value="id"
              required
              :hint="$t('Wenn Sie eine Wiedervorlagezeit auswählen, wird Ihnen die Gefährdungsbeurteilung nach gewähltem Zeitraum als Wiedervorlage angezeigt.')"
              persistent-hint
            ></v-select>
          </v-flex>
          <v-flex md6>
            <v-text-field
              :label="$t('Ort')"
              v-model="hazardAssessment.city[locale]"
              :rules="cityRules"
              required>
            </v-text-field>

            <v-select
              :label="$t('Vorgesetzter')"
              v-model="hazardAssessment.supervisor"
              :items="supervisors"
              :rules="supervisorRules"
              item-text="name"
              item-value="id"
              required
            ></v-select>

            <v-text-field
              :label="$t('Ersteller')"
              v-model="hazardAssessment.creator.name"
              :rules="creatorRules"
              :disabled="true"
              required>
            </v-text-field>

            <v-text-field
              :label="$t('Systemnummer')"
              v-model="hazardAssessment.systemno"
              :rules="systemnoRules"
              :hint="$t('Z.B. Fertigung 00107126668101, Werk, Telefonnummer, Arbeitsplatzbeschreibung, Revisionnsnummer der Beurteilung')"
              persistent-hint
              required>
            </v-text-field>
          </v-flex>
        </v-layout>
        </v-container>

      </v-card-text>
    </v-form>
  </div>
</template>

<script>
  import Moment from 'moment'
  export default {
    data () {
      return {
        form: true,
        dateMenu: false,
        dateFormatted: null,
        requiredDate: [v => !!v || this.$t('Bitte geben Sie ein Datum an.')],
        noRules: [v => !!v || this.$t('Bitte geben Sie eine Betriebsnummer an.')],
        locationRules: [v => !!v || this.$t('Bitte geben Sie einen Standort an.')],
        departmentRules: [v => !!v || this.$t('Bitte geben Sie eine Abteilung an.')],
        cityRules: [v => !!v || this.$t('Bitte geben Sie einen Ort an.')],
        supervisorRules: [v => !!v || this.$t('Bitte geben Sie einen Vorgesetzter an.')],
        creatorRules: [v => !!v || this.$t('Bitte geben Sie einen Ersteller an.')],
        systemnoRules: [v => !!v || this.$t('Bitte geben Sie eine Systemnummer an.')],
        hazardAssessmentDate: null,
        today: Moment().format()
      }
    },
    props: [
      'hazardAssessment',
      'supervisors',
      'isValid',
      'resubmissionIntervals'
    ],
    created () {
      if (this.hazardAssessment && this.hazardAssessment.date !== null) {
        this.dateFormatted = this.formatDate(this.hazardAssessment.date)
      }
    },
    watch: {
      hazardAssessmentDate () {
        if (this.hazardAssessment !== null) {
          this.hazardAssessment.date = this.hazardAssessmentDate
          this.dateFormatted = this.formatDate(this.hazardAssessment.date)
        } else {
          this.dateFormatted = null
        }
      }
    },
    computed: {
      locale () {
        return this.$store.getters['locale/currentLocale']
      },
      valid: {
        set (val) {
          this.form = val
          this.$emit('isValid', this.form)
        },
        get () {
          return this.form
        }
      }
    },
    methods: {
      formatDate (date) {
        if (!date) return null
        let d = Moment(date, 'YYYY-MM-DD')
        return d.format('L')
      },
      parseDate (date) {
        if (!date) return null

        let d = Moment(date, 'DD.MM.YYYY')
        return `${d.year()}-${(d.month() + 1)}-${d.date()}`
      }
    }
  }
</script>
