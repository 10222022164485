import Vue from 'vue'
import { store } from '../store'
import { responseHelper } from '../helpers'

export const userService = {
  login,
  tokenLogin,
  logout,
  get,
  getSupervisors,
  getResponsibles,
  isGranted
}

function login (login, password) {
  return Vue.prototype.$http.post('/login_check', { login, password })
    .then(response => {
      if (response.data.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('auth', JSON.stringify(response.data))
        Vue.prototype.$http.defaults.headers.common = {
          Authorization: 'Bearer ' + response.data.token
        }
        // settingsService.get()
        this.get()
      }
      return response.data
    })
    .catch(error => {
      return responseHelper.handleError(error)
    })
}

function tokenLogin (token) {
  if (token) {
    const auth = { token: token }
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    localStorage.setItem('auth', JSON.stringify(auth))
    Vue.prototype.$http.defaults.headers.common = {
      Authorization: 'Bearer ' + token
    }
    // settingsService.get()
    this.get()
    return new Promise(function (resolve) {
      resolve(auth)
    })
  }
  return new Promise(function (resolve, reject) {
    reject(new Error('Login failed'))
  })
}

function get () {
  return Vue.prototype.$http.get('/userinfo')
    .then(response => {
      store.commit('users/setUser', response.data)
      localStorage.setItem('roles', JSON.stringify(response.data.roles))
      localStorage.setItem('id', response.data.id)
      if (response.data.settings) {
        store.commit('settings/set', response.data.settings)
      }
      return response.data
    })
    .catch(response => {
      return responseHelper.handleError(response)
    })
}

function getSupervisors () {
  return Vue.prototype.$http.get('/user/supervisor')
    .then(response => {
      return response.data
    })
    .catch(response => {
      return responseHelper.handleError(response)
    })
}

function getResponsibles () {
  return Vue.prototype.$http.get('/user/responsibles')
    .then(response => {
      return response.data
    })
    .catch(response => {
      return responseHelper.handleError(response)
    })
}

function logout () {
  localStorage.removeItem('roles')
  localStorage.removeItem('auth')
}

function isGranted (roles) {
  const user = store.state.users.user
  let userRoles = Object.prototype.hasOwnProperty.call(user,'roles') ? user.roles : localStorage.getItem('roles')
  if (typeof userRoles === 'undefined' || !userRoles) {
    return true
  }
  if (typeof userRoles === 'string') {
    userRoles = JSON.parse(userRoles)
  }
  return Object.values(userRoles).filter(role => {
    return Object.values(roles).indexOf(role) >= 0
  }).length > 0
}
