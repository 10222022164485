import Vue from 'vue'

export default class LocaleFormatter {
  constructor () {
    this.languages = {
      de: Vue.$i18n.translate('Deutsch'),
      en: Vue.$i18n.translate('Englisch'),
      it: Vue.$i18n.translate('Italienisch'),
      fr: Vue.$i18n.translate('Französisch')
    }
  }

  format (value) {
    const langCodes = Object.keys(this.languages)
    if (langCodes.indexOf(value) >= 0) {
      return this.languages[value]
    } else {
      return value
    }
  }
}
