<template>
  <v-dialog
    persistent
    :value="true"
    max-width="800px"
    :scrollable="true"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
  >
    <v-card>
      <v-app-bar flat dark color="primary">
          <v-btn icon dark @click="cancel()">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $t('Neue Anfrage') }}</v-toolbar-title>
      </v-app-bar>

      <v-card-text>
        <v-form v-model="valid" ref="form">
          <h2>{{ $t('Ermittlung der Gefährdungen') }}</h2>
          <v-select
            :label="$t('Module')"
            :items="modules"
            v-model="issue.subsystem"
            :rules="requiredRules"
          >
          </v-select>

          <v-text-field
            v-model="issue.email"
            :label="$t('E-Mail')"
            :rules="emailRules"
            :hint="$t('Bitte geben Sie Ihre E-Mail-Adresse ein, über die Sie zum Vorgang Benachrichtigt werden wollen')"
            persistent-hint
          >
          </v-text-field>

          <v-text-field
            v-model="issue.subject"
            :label="$t('Kurzbeschreibung')"
            :rules="requiredRules"
          >
          </v-text-field>

          <v-textarea
            v-model="issue.body"
            :label="$t('Problembeschreibung')"
            :rules="requiredRules"
          >
          </v-textarea>

          <file-uploader
            v-model="issue.files"
          ></file-uploader>

        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer/>
        <v-btn color="primary" text @click="save()" :disabled="!valid">{{ $t('Absenden') }}</v-btn>
        <v-btn color="primary" text @click="cancel()">{{ $t('Schließen') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {redmineService} from '../../services'
import fileUploader from '../helpers/FileUploader'

export default {
  name: 'SupportAdd',
  data () {
    return {
      valid: false,
      dialog: true,
      issue: {
        subsystem: null,
        email: this.email,
        subject: null,
        body: null,
        files: [],
        user_agent: this.$browserDetect.meta.name + ' ' + this.$browserDetect.meta.version,
        user_agent_os: this.$browserDetect.meta.ua
      },
      requiredRules: [v => !!v || this.$t('Bitte machen Sie eine Eingabe, das Feld darf nicht leer sein.')],
      emailRules: [
        (v) => !!v || 'Bitte machen Sie eine Eingabe, das Feld darf nicht leer sein.',
        (v) => /.+@.+/.test(v) || 'Bitte geben Sie eine gültige E-Mail-Adresse ein.'
      ]
    }
  },
  props: {
    modules: {
      type: Array,
      required: true
    },
    email: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    cancel () {
      this.$router.push({name: 'support'})
    },
    save () {
      if (this.valid) {
        redmineService.save(this.issue).then(() => {
            let {dispatch} = this.$store
            dispatch('alert/success', 'Die Anfrage wurde versendet.', {root: true})
            this.$emit('needsUpdate')
            this.$router.push({name: 'support'})
          },
          error => {
            let {dispatch} = this.$store
            dispatch('alert/error', error.message, {root: true})
          }
        )
      }
    }
  },
  components: {
    fileUploader
  }
}
</script>
