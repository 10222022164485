import { responseHelper } from '../helpers'
import Vue from 'vue'

export const hazardassessmenttypeService = {
  getAll
}

function getAll () {
  return Vue.prototype.$http.get('/hazardassessmenttype')
    .then(response => {
      return response.data
    })
    .catch(error => {
      return responseHelper.handleError(error)
    })
}
