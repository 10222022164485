import Vue from 'vue'
import Vuex from 'vuex'

import { loader } from './loader.module'
import { alert } from './alert.module'
import { authentication } from './authentication.module'
import { users } from './users.module'
import { locale } from './locale.module.js'
import { settings } from './settings.module.js'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    loader,
    alert,
    authentication,
    users,
    locale,
    settings
  }
})
