export default class DateFormatter {
  constructor (settings) {
    this.locale = settings.locale
    this.formatter = new Intl.DateTimeFormat(this.locale, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })
  }

  format (value) {
    if (!value) {
      return ''
    }
    if (!(value instanceof Date)) {
      /* Workaround for Safari Browsers */
      const parts = value.match(/(\d+)/g)
      let i = 0
      const fmt = {}
      'yyyy-mm-dd'.replace(/(yyyy|dd|mm)/g, function (part) {
        fmt[part] = i++
      })
      value = new Date(parts[fmt.yyyy], parts[fmt.mm] - 1, parts[fmt.dd])
    }
    return this.formatter.format(value)
  }
}
