export default {
  de: {
    Suche: 'Suche',
    Ja: 'Ja',
    Nein: 'Nein',
    STATUS_CREATE: 'Entwurf',
    STATUS_EXECUTION: 'Durchführung',
    STATUS_FINISHED: 'Abgeschlossen',
    STATUS_DELETED: 'Gelöscht',
    year: 'Jahr | Jahre',
    hazardassessment: {
      step2: {
        text1: 'Bitte wählen Sie für Ihre Gefährdungsbeurteilung aus dem Gefährdungskatalog für den Gefährdungstyp "{type}" eine Vorlage aus.'
      },
      add_specification: 'Drücken Sie die <kbd>Eingabetaste</kbd>, um einen neuen Eintrag zu erfassen.'
    },
    fileuploader: {
      uploading: '{count} Dateien werden hochgeladen ...',
      drag: 'Ziehen Sie Ihre Datei(en) hierher oder klicken Sie um auszuwählen',
      error: 'Upload fehlgeschlagen.',
      previewError: 'Eine Vorschau der Datei {name} wird leider nicht unterstützt.',
      maxSize: 'Die maximal erlaubte Dateigröße beträgt {size}.',
      extensions: {
        accepted: 'Dateien vom Typ {types} sind erlaubt.',
        all: 'Alle Dateitypen sind erlaubt.'
      }
    },
    support: {
      status_changed: 'Status geändert zu {status}',
      attachment_added: 'Datei {filename} wurde hinzugefügt.'
    },
    $vuetify: {
      dataIterator: {
        rowsPerPageText: 'Elemente pro Seite:',
        rowsPerPageAll: 'Alle',
        noResultsText: 'Keine Elemente gefunden',
        nextPage: 'Nächste Seite',
        prevPage: 'Vorherige Seite'
      },
      dataTable: {
        itemsPerPageText: 'Zeilen pro Seite:',
      },
      dataFooter: {
        pageText: '{0}-{1} von {2}',
      },
      noDataText: 'Keine Daten vorhanden',
      carousel: {
        prev: 'Vorheriges visuelles',
        next: 'Nächstes Bild'
      }
    }
  },
  en: {
    Suche: 'Search',
    Ja: 'Yes',
    Nein: 'No',
    STATUS_CREATE: 'Draft',
    STATUS_EXECUTION: 'Execution',
    STATUS_FINISHED: 'FINISHED',
    STATUS_DELETED: 'Deleted',
    hazardassessment: {
      step2: {
        text1: 'Sie haben die Möglichkeit eine Gefährdungsbeurteilung vom Gefährdungstyp {type} aus einer Vorlage zu laden.'
      },
      add_specification: 'Press <kbd>Enter</kbd> to insert a new entry.'
    },
    fileuploader: {
      uploading: 'Uploading {count} files...',
      drag: 'Drag your file(s) here or click to browse',
      error: 'Upload failed.'
    },
    $vuetify: {
      dataIterator: {
        rowsPerPageText: 'Items per page:',
        rowsPerPageAll: 'All',
        pageText: '{0}-{1} of {2}',
        noResultsText: 'No matching records found',
        nextPage: 'Next page',
        prevPage: 'Previous page'
      },
      dataTable: {
        rowsPerPageText: 'Rows per page:'
      },
      noDataText: 'No data available',
      carousel: {
        prev: 'Previous visual',
        next: 'Next visual'
      }
    }
  }
}
