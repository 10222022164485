<template>
  <div v-if="pdfdata">
    <v-toolbar
      v-if="toolbar && !preview"
      absolute
      color="teal lighten-3"
      dark
    >
      <v-spacer></v-spacer>

      <v-btn icon @click="page > 1 ? page-- : 1">
        <v-icon>arrow_back_ios</v-icon>
      </v-btn>
      <v-toolbar-title class="ml-0">{{ page }} / {{ numPages ? numPages : '∞' }}</v-toolbar-title>
      <v-btn icon @click="page < numPages ? page++ : 1">
        <v-icon>arrow_forward_ios</v-icon>
      </v-btn>

      <!--v-btn icon @click="scale -= scale > 0.2 ? 0.1 : 0">
        <v-icon>zoom_out</v-icon>
      </v-btn>
      <v-toolbar-title>{{ formattedZoom }} %</v-toolbar-title>
      <v-btn icon @click="scale += scale < 2 ? 0.1 : 0">
        <v-icon>zoom_in</v-icon>
      </v-btn-->

    </v-toolbar>

    <pdf
      :src="pdfdata"
      v-for="i in numPages"
      :key="i"
      :id="i"
      :page="i"
      :scale.sync="scale"
      style="width:100%;margin:20px auto;"
    >
      <template slot="loading">
        loading content here...
      </template>
    </pdf>

  </div>
</template>

<script>
import PdfVuer from './PdfVuer'

export default {
  data () {
    return {
      page: 1,
      numPages: 0,
      pdfdata: {},
      errors: [],
      scale: this.pageScale
    }
  },
  props: {
    src: {
      type: String,
      default: null
    },
    pageScale: {
      default: 'page-width'
    },
    toolbar: {
      type: Boolean,
      default: true
    },
    preview: {
      type: Boolean,
      default: false
    },
    window: {
      type: String,
      default: 'scrollarea'
    }
  },
  computed: {
    formattedZoom () {
      let scale = isNaN(this.scale) ? 1 : this.scale
      return Number.parseInt(scale * 100)
    }
  },
  mounted () {
    this.getPdf()
  },
  watch: {
    show: function (s) {
      if (s) {
        this.getPdf()
      }
    },
    page: function (p) {
      let w = document.getElementById(this.window)
      if (w.scrollTop <= this.findPos(document.getElementById(p)) || (document.getElementById(p + 1) && w.scrollTop >= this.findPos(document.getElementById(p + 1)))) {
        // window.scrollTo(0,this.findPos(document.getElementById(p)));
        document.getElementById(p + 1).scrollIntoView()
      }
    },
    pageScale () {
      this.scale = this.pageScale
    }
  },
  methods: {
    getPdf () {
      var self = this
      self.pdfdata = PdfVuer.createLoadingTask(this.src)
      self.pdfdata.then(pdf => {
        self.numPages = self.preview ? 1 : pdf.numPages

        let w = document.getElementById(this.window)
        if (w) {
          w.onscroll = function () {
            changePage()
          }
        }
        function changePage () {
          let i = 1
          let count = Number(pdf.numPages)
          do {
            if (w.scrollTop >= self.findPos(document.getElementById(i)) &&
              w.scrollTop <= self.findPos(document.getElementById(i + 1))) {
              self.page = i
            }
            i++
          } while (i < count)
          if (w.scrollTop >= self.findPos(document.getElementById(i))) {
            self.page = i
          }
        }
      })
    },
    findPos (obj) {
      if (!obj) {
        return 0
      }
      return obj.offsetTop
    }
  },
  components: {
    pdf: PdfVuer
  }
}
</script>

<style scoped>
  .button {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
</style>
