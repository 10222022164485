import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from './i18n'

import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '../sass/main.sass'

Vue.use(Vuetify)

const opts = {
  icons: {
    iconfont: 'md'
  },
  lang: { t: (key, ...params) => i18n.t(key, params) },
  theme: {
    primary: '#1976d2',
    secondary: '#424242',
    accent: '#82B1FF',
    info: '#2196F3'
  }
}

export default new Vuetify(opts)
