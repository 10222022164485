import { responseHelper } from '../helpers'
import { store } from '../store'
import Vue from 'vue'

export const settingsService = {
  getData,
  get,
  save
}

function getData () {
  const settings = store.getters['settings/get']
  if (typeof settings !== 'undefined' && settings !== null && Object.keys(settings).length !== 0) {
    return new Promise((resolve) => {
      resolve(settings)
    })
  }
  return Vue.prototype.$http.get('/settings')
    .then(response => {
      store.commit('settings/set', response.data)
      return response.data
    })
    .catch(error => {
      return responseHelper.handleError(error)
    })
}

function get () {
  return Vue.prototype.$http.get('/settings')
    .then(response => {
      store.commit('settings/set', response.data)
      return response.data
    })
    .catch(error => {
      return responseHelper.handleError(error)
    })
}

function save (object) {
  return Vue.prototype.$http.put('/settings', object)
    .then(response => {
      store.commit('settings/set', response.data)
      return response.data
    })
    .catch(error => {
      return responseHelper.handleError(error)
    })
}
