import { responseHelper } from '../helpers'
import Vue from 'vue'

export const fileService = {
  upload,
  remove,
  get
}

function upload (formData) {
  const BASE_URL = process.env.VUE_APP_ROOT_API
  return Vue.prototype.$http.post('/file', formData)
  // get data
    .then(x => x.data)
  // add url field
    .then(x => x.map(img => Object.assign({},
      img, { url: `${BASE_URL}/file/${img.id}` })))
}

function remove (file) {
  return Vue.prototype.$http.delete('/file/' + file.id)
    .then(response => {
      return response.data
    })
    .catch(error => {
      return responseHelper.handleError(error)
    })
}

function get (file) {
  return Vue.prototype.$http.get('/file/' + file.id)
    .then(response => {
      return response.data
    })
    .catch(error => {
      return responseHelper.handleError(error)
    })
}
