export const users = {
  namespaced: true,
  state: {
    user: { }
  },
  getters: {
    getUser: (state) => {
      return state.user
    }
  },
  actions: {
    setUser ({ commit }, user) {
      commit('setUser', user)
    }
    /* get ({commit}) {
      commit('setUserRequest')

      userService.get()
        .then(
          user => {
            commit('setUserSuccess', user)
          },
          error => commit('setUserFailure', error)
        )
    } */
  },
  mutations: {
    setUser (state, user) {
      state.user = user
    },
    setUserRequest (state) {
      state.user = { loading: true }
    },
    setUserSuccess (state, user) {
      state.user = user
    },
    setUserFailure (state, error) {
      state.user = { error }
    }
  }
}
