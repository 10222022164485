import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/components/auth/Login'
import Logout from '@/components/auth/Logout'
import Help from '@/components/help/Help'
import Support from '@/components/support/Support'
import SupportView from '@/components/support/View'
import SupportAdd from '@/components/support/Add'
import Settings from '@/components/settings/Settings'
import LegalNotice from '@/components/legalnotice/LegalNotice'
import PermissionDenied from '@/components/permissiondenied/PermissionDenied'
import HazardAssessment from '@/components/hazardassessment/HazardAssessment'
import HazardAssessmentList from '@/components/hazardassessment/List'
import HazardAssessmentWorkflow from '@/components/hazardassessment/Workflow'
import { store } from '../store'
import { userService } from '../services'

Vue.use(Router)

export const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/logout',
      name: 'logout',
      component: Logout
    },
    {
      path: '/',
      name: 'hazardassessment',
      component: HazardAssessment,
      redirect: { name: 'hazardassessment_list' },
      children: [
        {
          path: '/hazardassessment/list',
          name: 'hazardassessment_list',
          component: HazardAssessmentList
        }, {
          path: '/hazardassessment/:id',
          name: 'hazardassessment_edit',
          component: HazardAssessmentWorkflow,
          props: (route) => ({ id: parseInt(route.params.id), step: 1 }),
          meta: {
            roles: ['ROLE_CREATOR', 'ROLE_MANAGER', 'ROLE_ADMIN']
          },
          beforeEnter: (to, from, next) => {
            !userService.isGranted(to.meta.roles) ? next('/permissiondenied') : next()
          }
        }, {
          path: '/hazardassessment/:id/execute',
          name: 'hazardassessment_execute',
          component: HazardAssessmentWorkflow,
          props: (route) => ({ id: parseInt(route.params.id), step: 5 })
        }, {
          path: '/hazardassessment/:id/finished',
          name: 'hazardassessment_finished',
          component: HazardAssessmentWorkflow,
          props: (route) => ({ id: parseInt(route.params.id), step: 6 })
        }, {
          path: '/hazardassessment/:id/step/:step',
          component: HazardAssessmentWorkflow,
          name: 'hazardassessment_edit_step',
          props: (route) => ({ id: parseInt(route.params.id), step: parseInt(route.params.step) }),
          meta: {
            roles: ['ROLE_CREATOR', 'ROLE_MANAGER', 'ROLE_ADMIN']
          },
          beforeEnter: (to, from, next) => {
            !userService.isGranted(to.meta.roles) ? next('/permissiondenied') : next()
          }
        }
      ]
    },
    {
      path: '/help',
      name: 'help',
      component: Help
    },
    {
      path: '/support',
      name: 'support',
      component: Support,
      children: [
        {
          name: 'support_view',
          path: '/support/:id',
          component: SupportView
        },
        {
          name: 'support_add',
          path: '/support/add',
          component: SupportAdd
        }
      ]
    },
    {
      path: '/settings',
      name: 'settings',
      component: Settings
    },
    {
      path: '/legalnotice',
      name: 'legalnotice',
      component: LegalNotice
    },
    {
      path: '/permissiondenied',
      name: 'permissiondenied',
      component: PermissionDenied
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
})

router.beforeEach((to, from, next) => {
  let loggedIn = localStorage.getItem('auth')
  const publicPages = ['/login', '/legalnotice']
  const authRequired = !publicPages.includes(to.path)
  if (window.location.search) {
    const params = new URLSearchParams(window.location.search)
    if (params.has('token')) {
      const token = params.get('token')
      const { dispatch } = store
      dispatch('authentication/tokenLogin', { token })
      loggedIn = localStorage.getItem('auth')
    }
  }
  // redirect to login page if not logged in and trying to access a restricted page
  if (authRequired && !loggedIn) {
    return next('/login')
  }
  next()
})
