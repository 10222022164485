// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import i18n from './plugins/i18n'
import vuetify from './plugins/vuetify'
import { store } from './store'
import { router } from './helpers'
import App from './App'
import axios from 'axios'
import Moment from 'moment'
import browserDetect from 'vue-browser-detect-plugin'
import VueMatomo from 'vue-matomo'
import { userService } from './services'

// import { configureFakeBackend } from './helpers'
// configureFakeBackend()

Vue.use(browserDetect)

if (process.env.VUE_APP_TRACKING_SITE_ID && process.env.NODE_ENV === 'production') {
  Vue.use(VueMatomo, {
    // Configure your matomo server and site by providing
    host: 'https://track.lum-software.de',
    siteId: process.env.VUE_APP_TRACKING_SITE_ID,
    // Changes the default .js and .php endpoint's filename
    // Default: 'piwik'
    trackerFileName: 'matomo',
    // Overrides the autogenerated tracker endpoint entirely
    // Default: undefined
    // trackerUrl: 'https://example.com/whatever/endpoint/you/have',
    // Enables automatically registering pageviews on the router
    router: router,
    // Enables link tracking on regular links. Note that this won't
    // work for routing links (ie. internal Vue router links)
    // Default: true
    enableLinkTracking: true,
    // Require consent before sending tracking information to matomo
    // Default: false
    requireConsent: false,
    // Whether to track the initial page view
    // Default: true
    trackInitialView: true,
    // Whether or not to log debug information
    // Default: false
    debug: false
  })
}

Vue.config.productionTip = false

axios.defaults.baseURL = process.env.VUE_APP_ROOT_API
axios.interceptors.request.use(function (config) {
  const auth = JSON.parse(localStorage.getItem('auth'))
  if (auth) {
    config.headers.common = { Authorization: 'Bearer ' + auth.token }
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

axios.interceptors.response.use(response => {
  return response
}, error => {
  if (error.response && error.response.status === 401 && router.currentRoute.name !== 'login') {
    const { dispatch } = store
    dispatch('authentication/logout')
    router.push({ name: 'login' })
  }
  return Promise.reject(error.response)
})

Vue.prototype.$http = axios

Moment.locale(process.env.VUE_APP_DEFAULT_LOCALE)

/* eslint-disable no-new */
new Vue({
  vuetify,
  store,
  router,
  i18n,
  render: h => h(App),
  computed: {
    currentLocale () {
      return this.$store.getters.currentLocale ? this.$store.getters.currentLocale : process.env.VUE_APP_DEFAULT_LOCALE
    },
    loggedIn () {
      return this.$store.state.authentication.status.loggedIn
    }
  },
  data: {
    interval: null
  },
  methods: {
    keepalive: function () {
      Vue.prototype.$http.get('/keepalive')
    }
  },
  watch: {
    currentLocale () {
      this.$i18n.locale = this.currentLocale
      Moment.locale(this.currentLocale)
    }
  },
  mounted () {
    this.$store.commit('locale/setAvailableLocales', JSON.parse(process.env.VUE_APP_LOCALES))
    this.$store.commit('locale/setCurrentLocale', process.env.VUE_APP_DEFAULT_LOCALE)
    /* this.interval = setInterval(function () {
      this.keepalive()
    }.bind(this), 300000) // 5min */
    if (this.loggedIn) {
      userService.get()
        .then(
          user => {
            if (typeof this.$matomo !== 'undefined') {
              this.$matomo.setUserId(user.id)
            }
          }
        )
    }
  },
  beforeDestroy () {
    // clearInterval(this.interval)
  }
}).$mount('#app')
