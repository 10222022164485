<template>
  <div class="step2">

    <v-app-bar flat color="grey lighten-2">
      <img src="../../../assets/circle-01.svg" alt="" class="circle">
      <v-toolbar-title>
        {{$t('Arbeitsbereiche und Tätigkeiten festlegen')}}
      </v-toolbar-title>
    </v-app-bar>

    <v-divider></v-divider>

    <v-card-text>
      <v-form v-model="valid" ref="form" enctype="multipart/form-data">
        <v-layout wrap>
          <v-flex sm12 md7>
              <v-radio-group
                v-model="hazardAssessment.type"
                :label="$t('Gefährdungstyp')"
                :rules="typeRules"
                @change="templates = []"
              >
                <v-radio
                  v-for="item in hazardAssessmentTypes"
                  :key="item.id"
                  :label="item.name[locale]"
                  :value="item"
                ></v-radio>
              </v-radio-group>

              <v-text-field
                v-if="hazardAssessment.type && hazardAssessment.type.description_label"
                :label="hazardTypesSublabel"
                :rules="descriptionRules"
                v-model="hazardAssessment.type_description[locale]"
              >
              </v-text-field>
          </v-flex>
          <v-flex sm12 md5 class="description-column">
            <div class="my-4">
              <h3 class="primary--text">{{ $t('Vorlagen für Ihre Gefährdungsbeurteilung') }}</h3>
              <p class="primary--text mb-0">
                {{ $t('Hier können Sie aus dem Gefährdungskatalog für den entsprechenden Gefährdungstyp Ihre Vorlage auswählen.') }}
              </p>
              <v-btn color="info" class="ml-0" @click.prevent="selectTemplate" :disabled="!hazardAssessment || !hazardAssessment.type">
                <v-icon left>find_in_page</v-icon>
                {{$t('Vorlagen anzeigen')}}
              </v-btn>
            </div>
            <!--h3 class="primary--text">{{ $t('Hinweis') }}</h3>
            <p class="primary--text">
              {{ $t('Verkettete Anlagen sind als Gesamteinheit zu betrachten.') }}
              {{ $t('Alle eingebundenen Maschinen/Anlageteile sind dann aufzuführen.') }}
              {{ $t('Bei gleichartigen Arbeitsbedingungen ist die Beurteilung eines Arbeitsplatzes bzw. einer Tätigkeit ausreichend.') }}
              {{ $t('Bei tätigkeitsbezogenen Betrachtungsweisen sind alle Tätigkeiten der betreffenden Mitarbeiter zu betrachten.') }}
            </p-->
          </v-flex>
        </v-layout>

        <v-layout wrap>
          <v-flex sm12 md7>
            <v-text-field
              :label="$t('Beschreibung')"
              v-model="hazardAssessment.description[locale]"
              :rules="descriptionRules"
              :hint="$t('Beschreibung des Arbeitsplatzes, Bereichs, Arbeitsmittels oder der Tätigkeit, Anlage')"
              persistent-hint
              required>
            </v-text-field>

          </v-flex>
          <!--v-flex sm12 md5 class="description-column">
            <p class="primary--text">{{ $t('Beschreibung des Arbeitsplatzes, Bereichs, Arbeitsmittels oder der Tätigkeit, Anlage') }}</p>
          </v-flex-->
        </v-layout>

        <v-layout wrap class="mt-3">
          <v-flex sm12 md7>
            <v-combobox
              v-model="hazardAssessment.specifications"
              :items="specifications"
              :label="$t('Regelwerke aus Liste auswählen oder neue erfassen')"
              :hide-no-data="!searchSpec"
              :search-input.sync="searchSpec"
              :item-text="specificationItemText"
              chips
              return-object
              multiple
              persistent-hint
              hide-selected
              :hint="$t('Sie können vorhandene Einträge aus der Liste auswählen oder durch direkte Eingabe neue erfassen.')"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('Keine Ergebnis für') }} "<strong>{{ searchSpec }}</strong>". <span v-html="$t('hazardassessment.add_specification')"></span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:selection="{ item, parent, selected }">
                <v-chip
                  :input-value="selected"
                  close
                  small
                  class="chip--select-multi"
                  @input="parent.selectItem(item)"
                >
                  <template v-if="typeof item === 'object'">{{ item.abbr.de }}</template>
                  <template v-else>{{ item }}</template>
                </v-chip>
              </template>
            </v-combobox>

            <file-uploader v-model="hazardAssessment.files" class="mt-3"></file-uploader>
          </v-flex>
          <v-flex sm12 md5 class="description-column">
            <v-subheader>{{ $t('Gewählte Regelwerke') }}</v-subheader>
            <template v-if="hazardAssessment.specifications && hazardAssessment.specifications.length > 0">
              <v-chip label v-for="spec in hazardAssessment.specifications" :key="JSON.stringify(spec)">
                <template v-if="typeof spec === 'object'">
                  <a v-if="specificationsUrl(spec)" :href="specificationsUrl(spec)" target="_blank">{{ spec.abbr.de }}</a>
                  <template v-else>{{ spec.abbr.de }}</template>
                </template>
                <template v-else>{{ spec }}</template>
              </v-chip>
            </template>
            <template v-else>
              <p class="grey--text">{{ $t('Keine Einträge vorhanden') }}</p>
            </template>
          </v-flex>
        </v-layout>
      </v-form>
    </v-card-text>

    <v-dialog
      v-model="templatesDialog"
      scrollable
      transition="dialog-bottom-transition"
      max-width="1096"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-card>
        <v-app-bar flat dark color="primary">
          <v-btn icon dark @click.native="imagesDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $t('Gefährdungskatalog mit Vorlagen') }}</v-toolbar-title>
        </v-app-bar>

        <v-card-text>
          <h2 class="title my-2">Gefährdungsbeurteilung aus Vorlagen laden</h2>
          <p>
            <template v-if="hazardAssessment.type">{{ $t('hazardassessment.step2.text1', {type: hazardAssessment.type.name[locale]}) }}<br></template>
            {{ $t('Bestätigen Sie Ihre Auswahl mit „Vorlage übernehmen“. Beachten Sie, dass bisher gemachte Angaben gegebenenfalls überschrieben werden.') }}<br>
            {{ $t('Wenn Sie keine Auswahl treffen möchten, klicken Sie auf „Abbrechen“.') }}
          </p>

          <v-select
            :items="templates"
            label="Vorlagen"
            item-value="id"
            item-text="name.de"
            v-model="template"
            return-object
            :loading="templatesLoading"
            :no-data-text="$t('Zum gewählten Gefährdungstyp sind leider keine Vorlagen vorhanden.')"
          >
            <template slot="selection" slot-scope="data">
              <formatter :value="data.item.name" type="localized"></formatter>
              <small v-if="data.item.hazards" class="grey--text">&nbsp;({{ data.item.hazards.length }} {{ $t('Gefährdungen') }})</small>
            </template>
            <template slot="items" slot-scope="data">
              <v-list-item-content>
                <v-list-item-title>
                  <formatter :value="data.item.name" type="localized"></formatter>
                  <small v-if="data.item.hazards" class="grey--text">&nbsp;({{ data.item.hazards.length }} {{ $t('Gefährdungen') }})</small>
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-select>
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" text @click.native="templatesDialog = false">
            {{ $t('Abbrechen') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="useTemplate()" :disabled="!template">
            <v-icon left>save</v-icon> {{ $t('Vorlage übernehmen') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar top v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <v-btn text @click="snack = false"><v-icon>close</v-icon></v-btn>
    </v-snackbar>
  </div>
</template>

<script>
  import { hazardassessmentService } from '../../../services'
  import fileUploader from '../../helpers/FileUploader'
  import formatter from '../../helpers/Formatter'

  export default {
    data () {
      return {
        form: false,
        snack: false,
        snackColor: '',
        snackText: '',
        showImages: false,
        imagesDialog: false,
        templatesDialog: false,
        selectedIndex: null,
        templates: [],
        template: null,
        templatesLoading: false,
        searchSpec: null,
        typeRules: [v => !!v || this.$t('Bitte wählen Sie einen Gefärdungstypen.')],
        descriptionRules: [v => !!v || this.$t('Bitte geben Sie eine Beschreibung an.')]
      }
    },
    props: [
      'hazardAssessment',
      'hazardAssessmentTypes',
      'specifications',
      'isValid'
    ],
    methods: {
      specificationsUrl (specification) {
        if(!Object.prototype.hasOwnProperty.call(specification, 'url')) {
          return false
        }
        let url = Object.prototype.hasOwnProperty.call(specification.url, this.locale) ? specification.url[this.locale] : specification.url
        if (!url) {
          return url
        }
        return url.replace('www.arbeitsschutz-center.de/uploads/56/asc/', 'www.unterweisungsmanager.de/asc/')
      },
      specificationItemText (item) {
        return (typeof item === 'object') ? item.abbr.de : item
      },
      save () {
        this.hazardAssessment.item = this.item
        this.$emit('resetFormError')
      },
      selectTemplate () {
        this.templatesDialog = true
        this.templatesLoading = true
        hazardassessmentService.find({ type: this.hazardAssessment.type.id }).then(
          result => {
            if (result.length > 0) {
              this.templates = result
            }
          },
          error => {
            let {dispatch} = this.$store
            dispatch('alert/error', error.message, {root: true})
          }
        ).then(() => {
          this.templatesLoading = false
        })
      },
      useTemplate () {
        let data = JSON.parse(JSON.stringify(this.template))
        this.hazardAssessment.template = data.id
        if (data.hazards.length > 0) {
          this.hazardAssessment.hazards = data.hazards
        }
        if (typeof data.type === 'object') {
          this.hazardAssessment.type = data.type
        }
        if (typeof data.type_description === 'object') {
          this.hazardAssessment.type_description = data.type_description
        }
        if (typeof data.specifications === 'object') {
          this.hazardAssessment.specifications = data.specifications
        }
        /* Bereits vorhandene Beschreibung soll nicht überschrieben werden */
        if (typeof data.description === 'object' && typeof this.hazardAssessment.description === 'object' && this.hazardAssessment.description[this.locale].length === 0) {
          this.hazardAssessment.description = data.description
        }
        this.templatesDialog = false
        this.snackColor = 'success'
        this.snackText = this.$t('Die Gefährdungsbeurteilung wurde übernommen.')
        this.snack = true
      }
    },
    computed: {
      locale () {
        return this.$store.getters['locale/currentLocale']
      },
      valid: {
        set (val) {
          this.form = val
          this.$emit('isValid', this.form)
        },
        get () {
          return this.form
        }
      },
      hazardTypesSublabel: function () {
        if (!this.hazardAssessment || !this.hazardAssessment.type) {
          return ''
        }
        let v = this.hazardAssessment.type.id
        let l = this.hazardAssessmentTypes.filter((type) => {
          return (type.id === v)
        })
        if (l.length === 0) {
          return ''
        }
        l = l.shift()
        return l.description_label[this.locale]
      }
    },
    beforeUpdate: function () {
      if (this.hazardAssessment.type === null && this.hazardAssessmentTypes && this.hazardAssessmentTypes.length > 0) {
        this.hazardAssessment.type = JSON.parse(JSON.stringify(this.hazardAssessmentTypes[0]))
      }
    },
    components: {
      fileUploader,
      formatter
    }
  }
</script>
