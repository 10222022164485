<template>
  <v-card>
    <v-app-bar flat dark color="primary">
      <v-btn icon dark @click="close()" v-if="dialog">
        <v-icon>close</v-icon>
      </v-btn>

      <v-toolbar-title>
        {{ $t('Impressum') }}
      </v-toolbar-title>
    </v-app-bar>

    <v-divider></v-divider>

    <v-card-text>

      <a href="https://www.universum.de/" target="_blank" class="d-block my-2">
        <img src="../../assets/universum-verlag-logo.svg" alt="Logo: Universum Verlag">
      </a>

      <p>
        Universum Verlag GmbH<br>
        Taunusstraße 54<br>
        D-65183 Wiesbaden
      </p>
      <p>
        Telefon: +49 611 90 30 0<br>
        Fax: +49 611 90 30 183<br>
        Kontakt: info@universum.de
      </p>
      <p>
        Registriert beim Amtsgericht Wiesbaden, HRB 2208<br>
        Umsatzsteuer-Identifikationsnummer: DE 250786819<br>
      </p>
      <p>
        Geschäftsführer:<br>
        Hans-Joachim Kiefer, Gernot Leinert
      </p>
      <p>
        Verantwortlich für die Inhalte (§ 55 Abs. 2 RStV):<br>
        Hans-Joachim Kiefer
      </p>

      <h2>Online-Plattform zur außergerichtlichen Streitschlichtung sowie Hinweis nach § 36 VSBG:</h2>
      <p>
        Als Online-Unternehmen sind wir verpflichtet, Sie als Verbraucher auf die Plattform zur Online-Streitbeilegung
        (OS-Plattform) der Europäischen Kommission hinzuweisen. Diese OS-Plattform ist über folgenden Link erreichbar:
        <a href="http://ec.europa.eu/consumers/odr/" target="_blank">http://ec.europa.eu/consumers/odr/</a>.
        Wir nehmen an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle allerdings nicht teil.
      </p>

      <h3>Copyright-Hinweis</h3>
      <p>
        Die verwendeten Texte, Bilder und Grafiken sowie das Layout dieser Seiten unterliegen weltweitem Urheberrecht.
        Die unerlaubte Verwendung, Reproduktion oder Weitergabe einzelner Inhalte oder kompletter Seiten ist untersagt.
        Dies gilt ebenso für die unerlaubte Einbindung einzelner Inhalte oder kompletter Seiten auf
        Internetseiten anderer Betreiber. Das Einrichten von Links auf diese Homepage ist jedoch ausdrücklich gestattet.
      </p>
    </v-card-text>

    <v-card-actions v-if="dialog">
      <v-spacer></v-spacer>
      <v-btn
        text
        @click="close()"
      >
        {{ $t('Schließen') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'LegalNotice',
  data () {
    return {
    }
  },
  props: {
    dialog: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

