<template>
  <div class="step3">

    <v-app-bar flat color="grey lighten-2">
      <img src="../../../assets/circle-02.svg" alt="" class="circle">
      <v-toolbar-title>
       {{ $t('Systematische Ermittlung der Gefährdungen') }}
      </v-toolbar-title>
    </v-app-bar>

    <v-divider></v-divider>

    <v-card-title primary-title>
      <h2 class="title">
        <formatter :value="hazardAssessment.description" type="localized"></formatter><span v-if="hazardAssessment.type">: {{ hazardAssessment.type.name[locale] }}</span>
      </h2>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn fab dark color="primary" @click="newHazard()" v-on="on">
            <v-icon dark>add</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('neue Gefährdung erfassen') }}</span>
      </v-tooltip>
    </v-card-title>

    <v-card-text>
      <div class="title primary--text d-inline-block mr-4">{{ $t('Gefährdungsliste') }}</div>
      <v-data-table
        :headers="headers"
        :items="hazardAssessment.hazards"
        hide-default-footer
      >
        <template slot="item" slot-scope="props">
          <tr>
            <td><formatter :value="props.item.name" type="localized"></formatter></td>
            <td>
              <ul v-if="props.item.hazard_dangers && props.item.hazard_dangers.length > 0">
                <li v-for="(d, index) in props.item.hazard_dangers.map(danger => danger.name.de)" :key="index">{{ d }}</li>
              </ul>
            </td>
            <td class="text-sm-right">{{ props.item.actions.filter(action => action.template === null).length }}</td>
            <td class="text-no-wrap">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    class="mr-2"
                    color="primary"
                    @click.stop="selectHazard(props.item)"
                    v-on="on"
                  >edit</v-icon>
                </template>
                <span>{{ $t('Gefährdung bearbeiten') }}</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    class="mr-2"
                    color="primary"
                    @click.stop="removeHazard(props.item)"
                    v-on="on"
                  >delete</v-icon>
                </template>
                <span>{{ $t('Gefährdung löschen') }}</span>
              </v-tooltip>
            </td>
          </tr>
        </template>
        <template slot="no-data">
          {{ $t('Sie haben noch keine Gefährdung erfasst.') }}
        </template>
      </v-data-table>

      <v-divider class="mb-3"></v-divider>

    </v-card-text>

    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      scrollable
      max-width="1096"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-card tile flat>
        <v-app-bar flat dark color="primary">
            <v-btn icon dark @click.native="dialog = false">
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ $t('Systematische Ermittlung der Gefährdung') }}</v-toolbar-title>
        </v-app-bar>

        <v-card-text>
          <v-stepper v-model="step">
            <v-stepper-header>
              <v-stepper-step :complete="step > 1" step="1">{{ $t('Ermittlung der Gefährdungen') }}</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="step > 2" step="2">{{ $t('Gefährdungsbeschreibung') }}</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="step > 3" step="3">{{ $t('Regelwerke') }}</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="step > 4" step="4">{{ $t('Bisher getroffene Maßnahmen') }}</v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <v-form v-model="valid1" ref="form1">
                  <h2>{{ $t('Ermittlung der Gefährdungen') }}</h2>

                  <v-alert
                    :value="hazard.template !== null"
                    color="warning"
                  >
                    {{ $t('Da die vorliegende Gefährdung Teil einer Vorlage ist, können einige Felder nicht bearbeitet werden.') }}
                  </v-alert>

                  <v-text-field
                    v-if="hazardAssessment.type"
                    v-model="hazardAssessment.type.name[locale]"
                    :label="$t('Gefährdungstyp')"
                    :disabled="true"
                  >
                  </v-text-field>

                  <v-text-field
                    v-if="hazardAssessment.type && hazardAssessment.type_description"
                    v-model="hazardAssessment.type_description[locale]"
                    :label="hazardAssessment.type.description_label[locale]"
                    :disabled="true"
                  >
                  </v-text-field>

                  <v-select
                    :items="hazardtypes"
                    item-value="id"
                    item-text="name.de"
                    v-model="tmpHazardType"
                    return-object
                    :label="$t('Art der Gefährdung')"
                    :rules="hazardTypeRules"
                    :disabled="hazard.template !== null"
                  >
                  </v-select>

                  <v-select
                    :items="factors"
                    item-value="id"
                    item-text="name.de"
                    return-object
                    v-model="tmpHazardFactor"
                    :label="$t('Gefährdungs-/Belastungsfaktoren')"
                    :rules="hazardFactorRules"
                    :disabled="factors.length === 0 || hazard.template !== null"
                    @input="resetSelectedDangers()"
                  >
                  </v-select>

                  <v-subheader>{{ $t('Gefährdungen') }}</v-subheader>
                  <v-checkbox
                    v-for="item in dangers"
                    :key="item.id"
                    v-model="hazard.hazard_dangers"
                    :label="item.name[locale]"
                    :value="item"
                    :rules="hazardHazardRules"
                    return-object
                    class="v-checkbox--small"
                  ></v-checkbox>

                  <file-uploader
                    v-model="hazard.files"
                  ></file-uploader>

                  <v-alert
                    :value="true"
                    color="info"
                    outlined
                    class="mt-4 mb-2"
                  >
                    {{ $t('Zu jedem Eintrag sind alle Gefährungsfaktoren zu betrachten.') }}
                    <!--template v-if="hazard.hazard_factor != null">
                      <h3 class="primary--text">{{ $t('Checkliste und Hinweise zum Gefährdungsfaktor') }}</h3>
                      <v-card flat>
                        <v-card-text v-html="info" class="primary--text"></v-card-text>
                      </v-card>
                    </template-->

                  </v-alert>
                </v-form>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-form v-model="valid2" ref="form2">
                  <h2>{{ $t('Gefährdungsbeschreibung') }}</h2>
                  <v-layout wrap align-center>
                    <v-flex>
                      <v-subheader class="pa-0">{{ $t('Bezeichnung') }}</v-subheader>
                      <v-layout align-center>
                        <div
                          :class="{'grey--text':hazard.template !== null}"
                          v-html="getDescription(locale)"
                        >
                        </div>
                        <v-text-field
                          class="ml-4"
                          v-model="hazard.name[locale]"
                          :hint="$t('(automatisch erstellten Text entsprechend ergänzen)')"
                          persistent-hint
                          :rules="nameRules"
                        >
                        </v-text-field>
                      </v-layout>

                      <v-textarea
                        ref="hazardComment"
                        :label="$t('Beschreibung')"
                        v-model="hazard.comment[locale]"
                        rows="2"
                        auto-grow
                      >
                      </v-textarea>
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-stepper-content>

              <v-stepper-content :step="3">
                <v-form v-model="valid3" ref="form3">
                  <h2>{{ $t('Regelwerke') }}</h2>

                  <v-layout wrap>
                    <v-flex sm12 md6>
                      <v-combobox
                        v-model="hazard.specifications"
                        :items="specifications"
                        :label="$t('Regelwerke aus Liste auswählen oder neue erfassen')"
                        :hide-no-data="!searchSpec"
                        :search-input.sync="searchSpec"
                        :item-text="specificationItemText"
                        chips
                        return-object
                        multiple
                        persistent-hint
                        hide-selected
                        :hint="$t('Sie können vorhandene Einträge aus der Liste auswählen oder durch direkte Eingabe neue erfassen.')"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ $t('Keine Ergebnis für') }} "<strong>{{ searchSpec }}</strong>". <span v-html="$t('hazardassessment.add_specification')"></span>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                        <template v-slot:selection="{ item, parent, selected }">
                          <v-chip
                            :input-value="selected"
                            close
                            small
                            class="chip--select-multi"
                            @input="parent.selectItem(item)"
                          >
                            <template v-if="typeof item === 'object'">{{ item.abbr.de }}</template>
                            <template v-else>{{ item }}</template>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </v-flex>
                    <v-flex sm12 md1></v-flex>
                    <v-flex sm12 md5>
                      <v-subheader>{{ $t('Gewählte Regelwerke') }}</v-subheader>
                      <template v-for="(spec, index) in hazard.specifications">
                        <v-chip label :key="index" class="mr-1">
                          <template v-if="typeof spec === 'object'">
                            <a v-if="specificationsUrl(spec)" :href="specificationsUrl(spec)" target="_blank">{{ spec.abbr.de }}</a>
                            <template v-else>{{ spec.abbr.de }}</template>
                          </template>
                          <template v-else>{{ spec }}</template>
                        </v-chip>
                      </template>

                      <v-subheader>{{ $t('Mitgeltende Regelwerke der Gefährdungsbeurteilung') }}</v-subheader>
                      <template v-if="hazardAssessment.specifications != null && hazardAssessment.specifications.length > 0">
                        <template v-for="(spec, index) in hazardAssessment.specifications">
                          <v-chip label :key="index">
                            <template v-if="typeof spec === 'object'">
                              <a v-if="spec.url" :href="spec.url.de" target="_blank">{{ spec.abbr.de }}</a>
                              <template v-else>{{ spec.abbr.de }}</template>
                            </template>
                            <template v-else>{{ spec }}</template>
                          </v-chip>
                        </template>
                      </template>
                      <template v-else>
                        <em>{{ $t('keine vorhanden') }}</em>
                      </template>
                    </v-flex>
                  </v-layout>

                </v-form>
              </v-stepper-content>

              <v-stepper-content step="4">
                <v-form v-model="valid4" ref="form4">
                  <template v-if="action === null">
                    <v-card-title class="pa-0">
                      <h2>{{ $t('Bisher getroffene Maßnahmen') }}</h2>
                      <v-spacer></v-spacer>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn fab dark color="primary" @click="newAction" v-on="on">
                            <v-icon dark>add</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('Neue Maßnahme erfassen') }}</span>
                      </v-tooltip>
                    </v-card-title>

                    <h3 class="subheading mt-2 mb-3">
                      <formatter :value="hazard.name" type="localized"></formatter>
                    </h3>

                    <v-data-table
                      :headers="actionHeaders"
                      :items="actionsTaken()"
                      hide-default-footer
                      class=""
                    >
                      <template slot="item" slot-scope="props">
                        <tr>
                        <td><formatter :value="props.item.name" type="localized"></formatter></td>
                        <td>
                          <ul v-if="props.item.hazard_dangers && props.item.hazard_dangers.length > 0">
                            <li v-for="(d, index) in props.item.hazard_dangers.map(danger => danger.name.de)" :key="index">{{ d }}</li>
                          </ul>
                        </td>
                        <td><formatter v-if="props.item.hazard_action_type" :value="props.item.hazard_action_type.name" type="localized"></formatter></td>
                        <td><span v-if="props.item.report_responsible">{{ props.item.report_responsible.name }}</span></td>
                        <td><formatter :value="props.item.report_date" type="date"></formatter></td>
                        <td style="white-space: nowrap">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                text
                                icon
                                color="primary"
                                @click="editAction(props.item)"
                                class="ma-0"
                                v-on="on"
                              >
                                <v-icon>edit</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $t('Maßnahme bearbeiten') }}</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                text
                                icon
                                color="primary"
                                @click="removeAction(props.item)"
                                class="ma-0"
                                v-on="on"
                              >
                                <v-icon>delete</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $t('Maßnahme löschen') }}</span>
                          </v-tooltip>
                        </td>
                        </tr>
                      </template>
                      <template slot="no-data">
                        {{ $t('Sie haben noch keine Maßnahme erfasst.') }}
                      </template>
                    </v-data-table>
                  </template>

                  <template v-else>
                    <h2 class="title">
                      {{ $t('Maßnahme') }}
                      <span v-if="actionIndex !== null">{{ $t('bearbeiten') }}</span><span v-else>{{ $t('erfassen') }}</span>
                    </h2>

                    <v-subheader>{{ $t('Gefährdungen') }}</v-subheader>
                    <v-layout wrap>
                      <template v-if="hazard.hazard_dangers.length > 0">
                        <v-flex
                          xs4
                          v-for="(d, index) in hazard.hazard_dangers"
                          :key="index"
                        >
                          <v-checkbox
                            v-model="action.hazard_dangers"
                            :label="d.name.de"
                            :value="d"
                            :rules="actionDangerRules"
                          ></v-checkbox>
                        </v-flex>
                      </template>
                      <template v-else>
                        <em>Es sind keine Gefährdungen definiert!</em>
                      </template>
                    </v-layout>

                    <v-text-field
                      ref="actionName"
                      :label="$t('Name der Maßnahme')"
                      v-model.trim="action.name[locale]"
                      rows="1"
                      auto-grow
                      :rules="actionNameRules"
                    >
                    </v-text-field>

                    <v-textarea
                      ref="actionDescription"
                      :label="$t('Beschreibung der Maßnahme')"
                      v-model.trim="action.description[locale]"
                      rows="2"
                      auto-grow
                      :rules="actionDescriptionRules"
                    >
                    </v-textarea>

                    <v-select
                      :items="actiontypes"
                      v-model="action.hazard_action_type"
                      :label="$t('Maßnahmentyp') + ':'"
                      :rules="actionTypeRules"
                      item-value="id"
                      item-text="name.de"
                      return-object
                    >
                      <template
                         slot="selection"
                         slot-scope="{ item }"
                      >
                        {{ item.name[locale].charAt(0).toUpperCase() }}&nbsp;<small>({{ item.name[locale] }})</small>
                      </template>
                      <template slot="items" slot-scope="data">
                       <v-list-item-title>
                         {{ data.item.name[locale].charAt(0).toUpperCase() }}&nbsp;<small>({{ data.item.name[locale] }})</small>
                       </v-list-item-title>
                     </template>
                    </v-select>

                    <v-select
                      ref="actionResponsible"
                      :label="$t('Verantwortliche Person')"
                      v-model="action.report_responsible"
                      :items="responsibles"
                      :rules="actionResponsibleRules"
                      item-text="name"
                      item-value="id"
                      return-object
                      required
                    ></v-select>

                    <v-menu
                      ref="dateMenu"
                      :close-on-content-click="false"
                      v-model="dateMenu"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dateReportFormatted"
                          :label="$t('Datum der Umsetzung')"
                          prepend-icon="event"
                          readonly
                          :rules="actionDateRules"
                          @blur="reportDate = parseDate(dateReportFormatted)"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="reportDate"
                        :locale="locale"
                        :first-day-of-week="1"
                        @input="dateMenu = false"
                        :max="todayMax"
                      ></v-date-picker>
                    </v-menu>

                    <file-uploader v-model="action.files"></file-uploader>

                    <v-card-actions>
                      <v-btn color="primary" text @click="cancelAction">
                        {{ $t('Abbrechen') }}
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        @click="saveAction()"
                        :disabled="!valid4"
                      >
                        <v-icon left>save</v-icon> {{ $t('Maßnahme speichern') }}
                      </v-btn>
                    </v-card-actions>

                  </template>
                </v-form>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click.native="cancelStep" :disabled="action !== null">
            {{ $t('Abbrechen') }}
          </v-btn>
          <v-spacer></v-spacer>

          <template v-if="step > 1">
            <v-btn color="primary" text @click="step--" :disabled="action !== null">
              <v-icon left>arrow_left</v-icon> {{ $t('Zurück') }}
            </v-btn>
          </template>
          <template v-if="step < 4">
          <v-btn color="primary" @click="next()">
            {{ $t('Weiter') }} <v-icon right>arrow_right</v-icon>
          </v-btn>
          </template>
          <template v-else>
            <v-btn color="primary" @click="saveHazard" :disabled="action !== null"><!--  :disabled="actionsTaken().length === 0 || action !== null" -->
              <v-icon left>save</v-icon> {{ $t('Gefährdung speichern') }}
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar top v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <v-btn text @click="snack = false"><v-icon>close</v-icon></v-btn>
    </v-snackbar>

    <v-dialog
      v-model="infoDialog"
      transition="dialog-bottom-transition"
      :overlay="false"
      scrollable
      max-width="500px"
    >
      <v-card>
        <v-card-title><h2>{{ $t('Bewertungskriterien') }}</h2></v-card-title>

        <v-card-text>
          <table class="data">
            <thead>
              <tr>
                <th>{{ $t('Bewertungskriterien') }}</th>
                <th style="white-space: nowrap">{{ $t('Risikofaktoren (R)') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th colspan="2">{{ $t('SA: Schadensausmaß (Bewertungsfaktor 4)') }}</th>
              </tr>
              <tr v-for="item in risks.sa" :key="'sa' + item.value">
                <td>{{ item.text }}</td>
                <td class="text-xs-center">{{ item.value }}</td>
              </tr>
              <tr>
                <th colspan="2">{{ $t('EX: Häufigkeit und Dauer der Exposition (Bewertungsfaktor 1)') }}</th>
              </tr>
              <tr v-for="item in risks.ex" :key="'ex' + item.value">
                <td>{{ item.text }}</td>
                <td class="text-xs-center">{{ item.value }}</td>
              </tr>
              <tr>
                <th colspan="2">{{ $t('WG: Wahrscheinlichkeit des Gefährdungseintritts (Bewertungsfaktor 2)') }}</th>
              </tr>
              <tr v-for="item in risks.wg" :key="'wg' + item.value">
                <td>{{ item.text }}</td>
                <td class="text-xs-center">{{ item.value }}</td>
              </tr>
            </tbody>
          </table>
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" text @click.stop="infoDialog = false">{{ $t('Schließen') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
  import Moment from 'moment'
  import formatter from '../../helpers/Formatter'
  import fileUploader from '../../helpers/FileUploader'

  export default {
    data () {
      return {
        step: 1,
        dateMenu: false,
        dateFormatted: null,
        dateReportFormatted: null,
        today: Moment().format(),
        reportDate: null,
        snack: false,
        snackColor: '',
        snackText: '',
        valid1: true,
        valid2: true,
        valid3: true,
        valid4: true,
        searchSpec: null,
        hazard: null,
        hazardEmpty: null,
        actionEmpty: null,
        tmpHazardType: null,
        tmpHazardFactor: null,
        dialog: false,
        editDialog: false,
        infoDialog: false,
        imagesDialog: false,
        info: '<p>Wo sollen diese Inhalte herkommmen?</p>',
        hn: null,
        nameRules: [v => !!v || this.$t('Bitte geben Sie eine Bezeichnung an.')],
        itemRules: [v => !!v || this.$t('Bitte erfassen Sie mindestens eine Gefährdung.')],
        responsibleRules: [v => !!v || this.$t('Bitte wählen Sie einen Verantwortlichen aus.')],
        hazardTypeRules: [v => !!v || this.$t('Bitte wählen Sie die Art der Gefährdung.')],
        hazardFactorRules: [v => !!v || this.$t('Bitte geben Sie den Gefährdungs-/Belastungsfaktor.')],
        hazardHazardRules: [() => (this.hazard.hazard_dangers !== null && this.hazard.hazard_dangers.length > 0) || this.$t('Bitte wählen Sie mindestens eine Gefährdung.')],
        requiredDate: [v => !!v || this.$t('Bitte geben Sie ein Datum an.')],
        actionNameRules: [v => (this.action !== null && !!v) || this.$t('Bitte geben Sie eine Bezeichnung an.')],
        actionDescriptionRules: [v => (this.action !== null && !!v) || this.$t('Bitte geben Sie eine Beschreibung an.')],
        actionTypeRules: [v => (this.action !== null && !!v) || this.$t('Bitte wählen Sie einen Typ aus.')],
        actionResponsibleRules: [v => (this.action !== null && !!v) || this.$t('Bitte wählen Sie einen Verantwortlichen aus.')],
        actionDateRules: [v => (this.action !== null && !!v) || this.$t('Bitte geben Sie ein Datum an.')],
        actionDangerRules: [() => (this.action.hazard_dangers.length > 0) || this.$t('Bitte wählen Sie mindestens eine Gefährdung.')],
        action: null,
        actionIndex: null,
        actionValid: true,
        hazardIndex: 1,
        hazardfactors: null,
        todayMax: Moment().format(),
        risks: {
          'types': [
            {'value': 1, 'text': this.$t('Normalbetrieb / normale Tätigkeit')},
            {'value': 0, 'text': this.$t('Einrichten Sonderbetrieb Instandhaltung')}
          ],
          'items': [
            {'value': 0, 'text': this.$t('akzeptabel')},
            {'value': 1, 'text': this.$t('gering')},
            {'value': 2, 'text': this.$t('signifikant')},
            {'value': 3, 'text': this.$t('hoch')},
            {'value': 4, 'text': this.$t('extrem')}
          ],
          'sa': [
            {'value': 0, 'text': this.$t('keine Verletzung')},
            {'value': 4, 'text': this.$t('leichte Verletzung')},
            {'value': 8, 'text': this.$t('vollständig heilbare Verletzung')},
            {'value': 12, 'text': this.$t('bleibender Gesundheitsschaden ohne Beeinflussung der Lebensqualität')},
            {'value': 16, 'text': this.$t('bleibender Gesundheitsschaden mit Beeinflussung der Lebensqualität')},
            {'value': 20, 'text': this.$t('Tod')}
          ],
          'ex': [
            {'value': 0, 'text': this.$t('Keine')},
            {'value': 1, 'text': this.$t('ca. 1 Stunde pro Monat')},
            {'value': 2, 'text': this.$t('c. 1 Stunde pro Woche')},
            {'value': 3, 'text': this.$t('ca. 1 Stunde pro Tag')},
            {'value': 4, 'text': this.$t('ca. 1-4 Stunden pro Tag')},
            {'value': 5, 'text': this.$t('über 4 Stunden pro Tag')}
          ],
          'wg': [
            {'value': 0, 'text': this.$t('keine')},
            {'value': 2, 'text': this.$t('kaum vorstellbar')},
            {'value': 4, 'text': this.$t('vorstellbar aber unüblich')},
            {'value': 6, 'text': this.$t('unter bestimmten Bedingungen vorstellbar')},
            {'value': 8, 'text': this.$t('kann erwartet werden')},
            {'value': 10, 'text': this.$t('tritt ohne Zweifel ein')}
          ],
          'result': {
            'type': 0, 'sa': null, 'ex': null, 'wg': null, 'int': null, 'manual': null
          }
        }
      }
    },
    props: [
      'hazardAssessment',
      'hazardAssessmentObject',
      'actiontypes',
      'hazardassessmenttypes',
      'hazardtypes',
      'specifications',
      'responsibles',
      'isValid'
    ],
    created () {
      this.actionEmpty = JSON.parse(JSON.stringify(this.hazardAssessmentObject.hazards[0].actions[0]))
      this.actionEmpty.status_history = ['STATUS_OPEN', 'STATUS_TAKEN']
      this.actionEmpty.implemented = 100
      let hazard = JSON.parse(JSON.stringify(this.hazardAssessmentObject.hazards[0]))
      hazard.actions = []
      this.hazard = hazard
      this.hazardEmpty = JSON.parse(JSON.stringify(hazard))
      this.$emit('isValid', this.hazardAssessment.hazards.length > 0)
    },
    updated () {
      this.setHazardDanger()
      this.$emit('isValid', this.hazardAssessment.hazards.length > 0)
    },
    watch: {
      tmpHazardType () {
        if (this.hazard.hazard_type && this.tmpHazardType.id === this.hazard.hazard_type.id) {
          return
        }
        if (typeof this.tmpHazardType !== 'undefined' && this.tmpHazardType !== null) {
          let type = JSON.parse(JSON.stringify(this.tmpHazardType))
          delete type.factors
          this.hazard.hazard_type = type
        }
      },
      tmpHazardFactor () {
        if (this.hazard.hazard_factor && this.tmpHazardFactor.id === this.hazard.hazard_factor.id) {
          return
        }
        if (typeof this.tmpHazardFactor !== 'undefined' && this.tmpHazardFactor !== null) {
          let factor = JSON.parse(JSON.stringify(this.tmpHazardFactor))
          delete factor.dangers
          this.hazard.hazard_factor = factor
        }
      },
      reportDate () {
        if (this.action !== null) {
          this.action.report_date = this.reportDate
          this.dateReportFormatted = this.formatDate(this.action.report_date)
        }
      },
      hazards () {
        this.$emit('isValid', this.hazardAssessment.hazards.length > 0)
      }
    },
    methods: {
      cancelStep() {
        this.step = 1
        this.dialog = false
      },
      cancelAction() {
        this.action = null
        this.actionIndex = null
      },
      specificationsUrl (specification) {
        if(!Object.prototype.hasOwnProperty.call(specification, 'url')) {
          return false
        }
        let url = Object.prototype.hasOwnProperty.call(specification.url, this.locale) ? specification.url[this.locale] : specification.url
        if (!url) {
          return url
        }
        return url.replace('www.arbeitsschutz-center.de/uploads/56/asc/', 'www.unterweisungsmanager.de/asc/')
      },
      specificationItemText (item) {
        return (typeof item === 'object') ? item.abbr.de : item
      },
      resetSelectedDangers () {
        this.hazard.hazard_dangers = []
        this.$refs['form1'].resetValidation()
      },
      next () {
        if (this.$refs['hazardComment']) {
          setTimeout(() => {
            this.$refs['hazardComment'].calculateInputHeight()
          }, 1)
        }
        if (this.$refs[`form${this.step}`].validate()) {
          this.step++
        }
      },
      saveHazard () {
        if (this.hazardIndex === null) {
          this.hazardIndex = this.hazardAssessment.hazards.length
        }
        this.hazard.actions = JSON.parse(JSON.stringify(this.hazardActions))
        this.$set(this.hazardAssessment.hazards, this.hazardIndex, JSON.parse(JSON.stringify(this.hazard)))
        this.snackColor = 'success'
        this.snackText = this.$t('Die Gefährdung wurde hinzugefügt.')
        if (this.hazard.risk_assessment > 0 && this.hazard.risk_assessment < 10) {
          this.snackText += '<br>' + this.$t('Es sind keine weiteren Maßnahmen erforderlich.')
        }
        this.snack = true
        this.dialog = false
        this.hazardIndex = null
        this.hazard = JSON.parse(JSON.stringify(this.hazardEmpty))
      },
      newHazard () {
        this.step = 1
        this.tmpHazardType = null
        this.tmpHazardFactor = null
        this.hazardIndex = this.hazardAssessment.hazards.length
        this.hazard = JSON.parse(JSON.stringify(this.hazardEmpty))
        this.dialog = true
        this.resetSelectedDangers()
      },
      selectHazard (hazard) {
        this.step = 1
        this.hazardIndex = this.hazardAssessment.hazards.indexOf(hazard)
        this.hazard = JSON.parse(JSON.stringify(this.hazardAssessment.hazards[this.hazardIndex]))
        this.dialog = true
      },
      removeHazard (hazard) {
        let index = this.hazardAssessment.hazards.indexOf(hazard)
        if (confirm(this.$t('Soll die Gefährdung wirklich entfernt werden?'))) {
          this.hazardAssessment.hazards.splice(index, 1)
          this.snack = true
          this.snackColor = 'error'
          this.snackText = this.$t('Die Gefährdung wurde entfernt.')
        }
      },
      editAction (item) {
        this.dateFormatted = null
        this.actionIndex = this.hazard.actions.indexOf(item)
        this.action = JSON.parse(JSON.stringify(this.hazard.actions[this.actionIndex]))
        this.reportDate = this.action.report_date
        if (this.$refs['actionDescription']) {
          setTimeout(() => {
            this.$refs['actionDescription'].calculateInputHeight()
          }, 1)
        }
      },
      newAction () {
        this.reportDate = null
        this.action = JSON.parse(JSON.stringify(this.actionEmpty))
      },
      saveAction () {
        if (this.actionIndex === null) {
          this.actionIndex = this.hazard.actions.length
        }
        this.action.status_name = 'STATUS_TAKEN'
        this.$set(this.hazard.actions, this.actionIndex, JSON.parse(JSON.stringify(this.action)))
        this.actionIndex = null
        this.action = null
      },
      removeAction (item) {
        let index = this.hazard.actions.indexOf(item)
        this.hazard.actions.splice(index, 1)
        this.action = null
      },
      setHazardDanger () {
        if (!this.hazardtypes || !this.hazard.hazard_type) {
          return
        }
        let objects = this.hazardtypes.filter((type) => {
          return (type.id === this.hazard.hazard_type.id)
        })
        if (objects.length === 0) {
          return
        }
        this.tmpHazardType = objects[0]
        if (!this.hazard.hazard_factor) {
          return
        }
        objects = this.tmpHazardType.factors.filter((type) => {
          return (type.id === this.hazard.hazard_factor.id)
        })
        if (objects.length === 0) {
          return
        }
        this.tmpHazardFactor = objects[0]
      },
      formatDate (date) {
        if (!date) return null
        let d = Moment(date, 'YYYY-MM-DD')
        return d.format('L')
      },
      parseDate (date) {
        if (!date) return null

        let d = Moment(date, 'DD.MM.YYYY')
        return `${d.year()}-${(d.month() + 1)}-${d.date()}`
      },
      getDescription (locale) {
        let label = this.hazardAssessment.type_description[locale] + '; '
        if (typeof this.hazard.hazard_type !== 'undefined' && this.hazard.hazard_type !== null) {
          label += this.hazard.hazard_type.num + '.'
        }
        if (typeof this.hazard.hazard_factor !== 'undefined' && this.hazard.hazard_factor !== null) {
          label += this.hazard.hazard_factor.num + '.'
        }
        label += ' &raquo; '
        return label
      },
      removeSelectedDanger (item) {
        const index = this.hazard.hazard_dangers.findIndex(spec => item.id === spec.id)
        if (index >= 0) this.hazard.hazard_dangers.splice(index, 1)
      },
      actionsTaken () {
        let actions = this.hazardActions.filter((action) => {
          return action.status_history && action.status_history.indexOf('STATUS_TAKEN') && action.template === null
        })
        return actions
      }
    },
    computed: {
      hazardActions () {
        let actions = []
        if (this.hazard.hazard_dangers !== null && Array.isArray(this.hazard.hazard_dangers)) {
          this.hazard.actions.forEach(action => {
            this.hazard.hazard_dangers.forEach(val => {
              let match = action.hazard_dangers.filter(danger => {
                return val.id === danger.id
              })
              if (match.length > 0 && actions.indexOf(action) === -1) {
                actions.push(action)
              }
            })
          })
        }
        return actions
      },
      locale () {
        return this.$store.getters['locale/currentLocale']
      },
      hazards () {
        return this.hazardAssessment.hazards
      },
      factors () {
        return this.tmpHazardType !== null ? this.tmpHazardType.factors : []
      },
      dangers () {
        return this.tmpHazardFactor !== null ? this.tmpHazardFactor.dangers : []
      },
      headers () {
        return [
          {
            text: this.$t('Name'),
            align: 'left',
            sortable: true,
            value: `name.${this.locale}`
          }, {
            text: 'Gefährdungen',
            align: 'left',
            value: 'hazard_dangers',
            sortable: false
          }, {
            text: 'Anzahl Maßnahmen',
            align: 'right',
            value: 'actions.length'
          }, {
            text: this.$t('Aktion'),
            value: 'name',
            sortable: false
          }
        ]
      },
      actionHeaders () {
        return [
          {
            text: this.$t('Name'),
            align: 'left',
            sortable: false,
            value: `name.${this.locale}`
          }, {
            text: this.$t('Gefährdungen'),
            align: 'left',
            sortable: false,
            value: 'dangers'
          }, {
            text: this.$t('Maßnahmentyp'),
            align: 'left',
            sortable: false,
            value: 'type'
          }, {
            text: this.$t('Verantwortlich'),
            align: 'left',
            sortable: false,
            value: 'responsible.name'
          }, {
            text: this.$t('Datum'),
            align: 'left',
            sortable: false,
            value: 'date'
          }, {
            text: this.$t('Aktion'),
            value: 'name',
            sortable: false
          }
        ]
      }
    },
    components: {
      formatter,
      fileUploader
    }
  }
</script>
