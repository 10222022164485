<template>
  <v-card class="step1" flat>
    <v-app-bar flat color="grey lighten-2">
      <img src="../../../assets/circle-07.svg" alt="" class="circle">
      <v-toolbar-title>
        {{ $t('Dokumentieren und fortschreiben') }}
      </v-toolbar-title>
    </v-app-bar>

    <v-divider></v-divider>

    <v-card-text>
      <v-expansion-panels
        v-model="panel"
        class="with-divider"
        focusable
        popout
      >
        <v-expansion-panel
          class="grey lighten-5"
          v-for="(hazard, i) in hazardAssessment.hazards"
          :key="i"
        >
          <v-expansion-panel-header>
            <div class="text-uppercase"><formatter :value="hazard.name" type="localized"></formatter></div>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-layout wrap>
              <v-flex sm12>
                <v-data-table
                  :headers="headers"
                  :items="hazard.actions"
                  hide-default-footer
                  item-key="name"
                >
                  <template slot="item" slot-scope="props">
                    <tr>
                      <td><formatter :value="props.item.name" type="localized"></formatter></td>
                      <td><formatter :value="props.item.description" type="localized"></formatter></td>
                      <td>
                          <v-layout wrap align-content-center>
                            <v-flex align-self-center xs6>
                              <v-progress-linear v-model="props.item.implemented" height="12"></v-progress-linear>
                            </v-flex>
                            <v-flex align-self-center xs6>
                              <span class="ml-1">{{ props.item.implemented||0 }}%</span>
                            </v-flex>
                          </v-layout>
                      </td>
                      <td>
                        <template v-if="props.item.implemented === 100">
                          <v-tooltip bottom v-if="hasStatus(props.item, 'STATUS_EFFECTIVE')">
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on" color="success">thumb_up</v-icon>
                            </template>
                            <span>Wirksamkeit wurde erreicht</span>
                          </v-tooltip>

                          <v-tooltip bottom v-else-if="hasStatus(props.item, 'STATUS_INEFFECTIVE')">
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on" color="warning">thumb_down</v-icon>
                            </template>
                            <span>Wirksamkeit wurde nicht erreicht</span>
                          </v-tooltip>

                          <v-tooltip bottom v-else>
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on" color="grey lighten-2">thumbs_up_down</v-icon>
                            </template>
                            <span>Wirksamkeitsprüfung steht noch aus</span>
                          </v-tooltip>
                        </template>
                      </td>
                      <td><span v-if="props.item.report_responsible">{{ props.item.report_responsible.name }}</span></td>
                      <td><formatter :value="props.item.report_date" type="date"></formatter></td>
                    </tr>
                  </template>
                  <v-alert slot="no-data" :value="true" color="warning" icon="warning">
                    {{ $t('Es sind keine Maßnahmen vorhanden.') }}
                  </v-alert>
                </v-data-table>
              </v-flex>
            </v-layout>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

    </v-card-text>
  </v-card>
</template>

<script>
  import formatter from '../../helpers/Formatter'

  export default {
    data () {
      return {
        form: true,
        hazard: null,
        panel: 0
      }
    },
    props: [
      'hazardAssessment',
      'hazardAssessmentObject',
      'actiontypes',
      'isValid'
    ],
    methods: {
      hasStatus (action, status) {
        return action.status_history.indexOf(status) >= 0
      }
    },
    computed: {
      locale () {
        return this.$store.getters['locale/currentLocale']
      },
      valid: {
        set (val) {
          this.form = val
          this.$emit('isValid', this.form)
        },
        get () {
          return this.form
        }
      },
      headers () {
        return [
          {
            text: 'Bezeichnung',
            align: 'left',
            sortable: true,
            value: `name.${this.locale}`
          },
          {
            text: 'Beschreibung',
            align: 'left',
            sortable: true,
            value: `description.${this.locale}`
          },
          {
            text: 'Abarbeitung in %',
            align: 'left',
            sortable: true,
            value: 'implemented'
          },
          {
            text: 'Wirksam?',
            align: 'left',
            sortable: true,
            value: 'status_name'
          },
          {
            text: 'Verantwortlicher',
            align: 'left',
            sortable: true,
            value: 'report_responsible.name'
          },
          {
            text: 'Umsetzungsdatum',
            align: 'left',
            sortable: true,
            value: 'report_date'
          }
        ]
      }
    },
    components: {
      formatter
    }
  }
</script>
