import { responseHelper } from '../helpers'
import Vue from 'vue'

export const specificationService = {
  getAll
}

function getAll () {
  return Vue.prototype.$http.get('/specification')
    .then(response => {
      return response.data
    })
    .catch(error => {
      return responseHelper.handleError(error)
    })
}

/* function getByHazardAssessment (id) {
  return Vue.prototype.$http.get('/specification/' + id)
    .then(response => {
      return response.data
    })
    .catch(error => {
      return responseHelper.handleError(error)
    })
} */
