<template>
    <div></div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  created () {
    const {dispatch} = this.$store
    dispatch('authentication/logout')
    this.$router.push({name: 'login'})
  }
}
</script>
