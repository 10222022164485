<template>
  <div>
    <div class="subheading grey--text text--darken-1">{{ $t('Dateien') }}</div>
    <div class="dropbox" v-bind:class="{ disabled: disabled }" @dragover.prevent @drop="onDrop">
      <input
        type="file"
        ref="file"
        multiple
        name="files"
        :disabled="disabled || isSaving"
        @change="filesChange($event.target.name, $event.target.files)"
        :accept="accept"
        class="input-file"
      />
      <p v-if="isSaving">
        {{ $t('fileuploader.uploading', {count: fileCount}) }}
      </p>
      <p v-else @click="launchFilePicker()">
        <template v-if="$vuetify.breakpoint.smAndDown">{{ $t('Dateien wählen') }}</template>
        <template v-else>{{ $t('fileuploader.drag') }}</template>
      </p>
    </div>

    <div class="v-messages theme--light mt-1">
      {{ $t('fileuploader.maxSize', {size: maxFilesize}) }}
      <template v-if="accept !== '*'">{{ $t('fileuploader.extensions.accepted', {types: printAccept}) }}</template>
      <template v-else>{{ $t('fileuploader.extensions.all') }}</template>
    </div>

    <template v-if="isInitial || isSelected || isSuccess">
      <!-- v-subheader>Uploaded {{ files.length }} file(s) successfully.</v-subheader -->
      <template v-for="(item, index) in files">
        <v-card flat :key="index">
          <v-card-text class="py-2 px-0">
            <v-layout wrap :key="`${index}-$(item.name)`">
              <v-flex xs3 md3>
                <v-img v-if="isImage(item)" :src="base64(item)" :alt="item.name"></v-img>
                <pdf-viewer
                  v-if="isPdf(item)"
                  :src="base64(item)"
                  pageScale="0.2"
                  :preview="true"
                />
              </v-flex>
              <v-flex xs8 md7>
                <div class="px-3">
                  <span class="subheading mr-3">{{ item.name }}</span>
                  <v-chip outlined small>{{ printfilesize(item.size) }}</v-chip>
                </div>
              </v-flex>
              <v-flex xs2 md2>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn @click="remove(item)" icon class="ma-0" v-on="on">
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('Datei löchen') }}</span>
                </v-tooltip>

                <v-tooltip bottom v-if="isImage(item) || isPdf(item)">
                  <template v-slot:activator="{ on }">
                    <v-btn @click="preview(item)" icon class="ma-0" v-on="on">
                      <v-icon>zoom_in</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('Datei anzeigen') }}</span>
                </v-tooltip>

                <v-tooltip bottom v-else>
                  <template v-slot:activator="{ on }">
                    <v-btn @click="download(item)" icon class="ma-0" v-on="on">
                      <v-icon>cloud_download</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('Datei speichern') }}</span>
                </v-tooltip>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>

        <v-divider :key="`${index}-divider`"></v-divider>
      </template>
    </template>

    <template v-if="isFailed">
      <v-alert
        :value="true"
        type="error"
      >
        {{ $t('fileuploader.error') }}<br>
        <pre>{{ uploadError }}</pre>
      </v-alert>
    </template>

    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      max-width="800"
      scrollable
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-card tile v-if="previewFile">
        <v-app-bar flat dark color="primary">
            <v-btn icon dark @click.native="dialog = false">
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ previewFile.name }}</v-toolbar-title>
        </v-app-bar>
        <v-card-text id="scrollarea">
          <v-img v-if="isImage(previewFile)" :src="base64(previewFile)" :alt="previewFile.name" contain />
          <pdf-viewer v-else-if="isPdf(previewFile)" :toolbar="false" :src="base64(previewFile)" window="scrollarea" />
          <p v-else>
            {{ $t('fileuploader.previewError', {name: previewFile.name}) }}
          </p>
        </v-card-text>

        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" text @click.stop="dialog = false">{{ $t('Schließen') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import filesize from 'filesize'
import { fileService } from '../../services'
import PdfViewer from './PdfViewer'

const STATUS_INITIAL = 0
const STATUS_SELECTED = 1
const STATUS_SAVING = 2
const STATUS_SUCCESS = 3
const STATUS_FAILED = 4

export default {
  data () {
    return {
      files: this.value,
      uploadError: null,
      currentStatus: null,
      previewFile: null,
      dialog: false,
      fileCount: 0
    }
  },
  props: {
    value: {
      type: Array,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    isInitial () {
      return this.currentStatus === STATUS_INITIAL
    },
    isSelected () {
      return this.currentStatus === STATUS_SELECTED
    },
    isSaving () {
      return this.currentStatus === STATUS_SAVING
    },
    isSuccess () {
      return this.currentStatus === STATUS_SUCCESS
    },
    isFailed () {
      return this.currentStatus === STATUS_FAILED
    },
    maxFilesize () {
      return this.printfilesize(process.env.VUE_APP_UPLOAD_FILE_SIZE)
    },
    accept () {
      return Object.values( JSON.parse(process.env.VUE_APP_UPLOAD_ACCEPT) ).join(',')
    },
    printAccept () {
      return Object.keys( JSON.parse(process.env.VUE_APP_UPLOAD_ACCEPT) ).join(', ')
    }
  },
  watch: {
    files () {
      this.updateFiles()
    },
    value () {
      this.files = this.value
      this.reset()
    }
  },
  methods: {
    updateFiles () {
      this.$emit('input', this.files)
    },
    launchFilePicker () {
      this.$refs.file.click()
    },
    onDrop: function (e) {
      e.stopPropagation()
      e.preventDefault()
      this.filesChange(e.target.name, e.dataTransfer.files)
      this.fileCount = e.dataTransfer.files.length
    },
    reset () {
      this.currentStatus = STATUS_INITIAL
      if (this.files.length > 0) {
        this.currentStatus = STATUS_SUCCESS
      }
      this.uploadError = null
    },
    save (formData) {
      // upload data to the server
      this.currentStatus = STATUS_SAVING
      fileService.upload(formData)
        .then(x => {
          this.files = this.files.concat(x)
          this.currentStatus = STATUS_SUCCESS
        })
        .catch(err => {
          this.uploadError = err.response
          this.currentStatus = STATUS_FAILED
        })
    },
    filesChange (fieldName, fileList) {
      // handle file changes
      let formData = new FormData()
      if (!fileList.length) {
        return
      }
      this.fileCount = 0
      // append the files to FormData
      for (let i = 0; i < fileList.length; i++) {
        let file = fileList[i]
        if (!this.accept.match(file.type)) {
          continue
        }
        this.fileCount++
        formData.append('files[' + i + ']', file, file.name)
      }
      if (this.fileCount === 0) {
        return
      }
      this.currentStatus = STATUS_SELECTED
      this.save(formData)
      this.fileCount = fileList.length
    },
    remove (file) {
      let index = this.files.indexOf(file)
      fileService.remove(file)
        .then(() => {
          this.files.splice(index, 1)
          this.currentStatus = STATUS_SUCCESS
        })
        .catch(err => {
          this.uploadError = err.response
          this.currentStatus = STATUS_FAILED
        })
    },
    printfilesize (size) {
      return filesize(size)
    },
    preview (file) {
      this.previewFile = file
      this.dialog = true
    },
    download (file) {
      let downloadLink = document.createElement('a')
      downloadLink.href = this.base64(file)
      downloadLink.download = file.name
      downloadLink.click()
      downloadLink.remove()
    },
    base64 (file) {
      return `data:${file.type};base64,${file.content}`
    },
    isImage (file) {
      return file.type.indexOf('image/') > -1
    },
    isPdf (file) {
      return file.type.indexOf('/pdf') > -1
    }
  },
  mounted () {
    this.reset()
  },
  components: {
    PdfViewer
  }
}
</script>

<style scoped>
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightgray;
  color: dimgray;
  padding: 10px 10px;
  min-height: 100px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 100px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 25px 0;
  margin: 0;
}

.dropbox.disabled {
  color: #C0C0C0;
  outline: 2px dashed #C0C0C0;
}
.dropbox.disabled:hover {
  background: lightgray;
  cursor: default;
}

</style>
