export default class DatetimeFormatter {
  constructor (settings) {
    this.locale = settings.locale
    this.formatter = new Intl.DateTimeFormat(this.locale, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    })
  }

  format (value) {
    if (!value) {
      return ''
    }
    if (!(value instanceof Date)) {
      /* Workaround for Safari Browsers */
      const parts = value.match(/(\d+)/g)
      let i = 0
      const fmt = {}
      'yyyy-mm-dd hh:ii:ss'.replace(/(yyyy|dd|mm|hh|ii|ss)/g, function (part) {
        fmt[part] = i++
      })
      value = new Date(parts[fmt.yyyy], parts[fmt.mm] - 1, parts[fmt.dd], parts[fmt.hh], parts[fmt.ii], parts[fmt.ss])
    }
    return this.formatter.format(value)
  }
}
