export const alert = {
  namespaced: true,
  state: {
    type: null,
    message: null
  },
  actions: {
    success ({ commit }, message) {
      commit('success', message)
    },
    error ({ commit }, message) {
      commit('error', message)
    },
    warning ({ commit }, message) {
      commit('warning', message)
    },
    clear ({ commit }, message) {
      commit('clear', message)
    }
  },
  mutations: {
    success (state, message) {
      if (typeof message !== 'undefined') {
        state.type = 'success'
        state.message = message
      }
    },
    warning (state, message) {
      state.type = 'warning'
      state.message = message
    },
    error (state, message) {
      state.type = 'error'
      state.message = message
    },
    clear (state) {
      state.type = null
      state.message = null
    }
  }
}
