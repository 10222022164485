export const locale = {
  namespaced: true,
  state: {
    availableLocales: [],
    currentLocale: null
  },
  getters: {
    availableLocales: (state) => state.availableLocales,
    currentLocale: (state) => {
      return state.currentLocale
    }
  },
  mutations: {
    setAvailableLocales (state, locales) {
      state.availableLocales = locales
    },
    setCurrentLocale (state, localeModule) {
      state.currentLocale = localeModule
    }
  }
}
