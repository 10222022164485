<template>
  <div>
    <v-card>
      <v-app-bar flat color="grey lighten-2">
        <v-toolbar-title>
          {{ $t('Einstellungen') }}
        </v-toolbar-title>
      </v-app-bar>

      <v-divider></v-divider>

      <v-card-text>
        <v-list
          subheader
          two-line
        >
          <v-subheader>{{ $t('Übersicht') }}</v-subheader>
          <v-list-item>
            <v-list-item-action>
              <v-checkbox v-model="settings.list"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content @click="settings.list = !settings.list">
              <v-list-item-title>{{ $t('Abgeschlossene Gefährdungsbeurteilungen einblenden') }}</v-list-item-title>
              <v-list-item-subtitle>{{ $t('Sollen abgeschlossene Gefährdungsbeurteilungen in der Übersicht standardmäßig eingeblendet werden?') }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-list
          subheader
          two-line
        >
          <v-subheader>{{ $t('Maßnahmen erfassen, durchführen & prüfen') }}</v-subheader>
          <v-list-item
            v-if="!isGranted(['ROLE_IMPLEMENTER'])"
          >
            <v-list-item-action>
              <v-checkbox v-model="settings.executionToggle"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content @click="settings.executionToggle = !settings.executionToggle">
              <v-list-item-title>{{ $t('Durchführung erforderlich') }}</v-list-item-title>
              <v-list-item-subtitle>{{ $t('Sollen der Filter für "Durchführung erforderlich" standardmäßig akiviert werden?') }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="!isGranted(['ROLE_IMPLEMENTER'])"
          >
            <v-list-item-action>
              <v-checkbox v-model="settings.effectivenessToggle"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content @click="settings.effectivenessToggle = !settings.effectivenessToggle">
              <v-list-item-title>{{ $t('Wirksamkeitsprüfung erforderlich') }}</v-list-item-title>
              <v-list-item-subtitle>{{ $t('Sollen der Filter für "Wirksamkeitsprüfung erforderlich" standardmäßig akiviert werden?') }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-action>
              <v-checkbox v-model="settings.executeView"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content @click="settings.executeView = !settings.executeView">
              <v-list-item-title>
                {{ $t('Ansicht der Maßnahmen') }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ $t('Sollen die Ansicht "Maßnahmenliste ohne Gruppierung" standardmäßig akiviert werden?') }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

        </v-list>

      </v-card-text>

      <v-card-actions>
        <v-btn
          color="primary"
          @click="save"
          :disabled="!changed"
        >
          {{ $t('Speichern') }}
        </v-btn>
        <v-btn
          color="primary"
          @click="reset"
          :disabled="!changed"
        >
          {{ $t('Einstellungen zurücksetzen') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import {
  settingsService,
  userService
} from '../../services'

export default {
  name: 'settings',
  data () {
    return {
      settings: {
        list: false,
        executeView: false,
        executionToggle: false,
        effectivenessToggle: false
      },
      cleanData: {}
    }
  },
  computed: {
    changed () {
      return JSON.stringify(this.settings) !== JSON.stringify(this.cleanData)
    }
  },
  mounted: function () {
    this.getSetting()
  },
  methods: {
    save () {
      let {dispatch} = this.$store
      dispatch('loader/show', {root: true})
      settingsService.save({settings: this.settings})
        .then(
          () => {
            this.getSetting()
            let {dispatch} = this.$store
            dispatch('alert/success', 'Ihre Einstellungen wurde aktualisiert.', {root: true})
          },
          error => {
            let {dispatch} = this.$store
            dispatch('alert/error', error.message, {root: true})
          }
        )
        .finally(() => dispatch('loader/hide', {root: true}))
    },
    async getSetting () {
      let {dispatch} = this.$store
      dispatch('loader/show', {root: true})
      await settingsService.getData()
        .then(
          result => {
            this.settings = Object.assign(this.settings, result)
            this.cleanData = Object.assign({}, this.settings)
          },
          error => {
            dispatch('alert/error', error.message, {root: true})
          }
        )
        .finally(() => dispatch('loader/hide', {root: true}))
    },
    isGranted (roles) {
      if (roles === null || roles.length === 0) {
        return true
      }
      return userService.isGranted(roles)
    },
    reset () {
      this.settings = Object.assign({}, this.cleanData)
    }
  }
}
</script>
