<template>
  <v-app>
    <v-navigation-drawer
      :mini-variant="miniVariant"
      v-model="drawer"
      enable-resize-watcher
      app
      persistent
      v-if="loggedIn"
      width="250px"
    >
      <v-toolbar color="white" light>
        <v-list>
          <v-list-item class="pa-0">
            <v-list-item-avatar>
              <v-avatar color="grey darken-1" size="36">
                <v-icon dark>account_circle</v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{username}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-toolbar>

      <v-list>
        <template v-for="(item, index) in items">
          <v-list-item
            :key="index"
            @click="$router.push(item.route)"
            v-if="item.title && isGranted(item.roles)"
          >
            <v-list-item-action v-if="item.icon">
              <v-icon v-html="item.icon"></v-icon>
            </v-list-item-action>
            <v-list-item-action v-else-if="item.img">
              <img src="./assets/circle-menu.svg" alt="" style="max-width: 100%">
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t(item.title) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider
            v-else-if="item.divider"
            :key="index"
          ></v-divider>
          <v-subheader
            v-else-if="item.header"
            :key="item.header"
          >
            {{ $t(item.header) }}
          </v-subheader>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-content
      style="max-height: calc(100vh - 64px)"
      class="my-0 py-0"
    >
      <v-toolbar
        color="white"
        max-height="64"
      >
        <v-app-bar-nav-icon
          @click.stop="drawer = !drawer"
          v-if="loggedIn"
        ></v-app-bar-nav-icon>

        <v-btn
          icon
          @click.stop="miniVariant = !miniVariant"
          v-if="loggedIn && drawer"
        >
          <v-icon v-html="miniVariant ? 'chevron_right' : 'chevron_left'"></v-icon>
        </v-btn>

        <v-toolbar-title class="app-logo">
          <a href="/">
            <img
              src="./assets/gefaehrdungs-manager-logo.svg"
              :alt="title"
              class="logo gb"
            >
          </a>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-toolbar-title>
          <a href="https://www.universum.de/" target="_blank"><img src="./assets/universum-verlag-logo.svg" alt="Logo: Universum Verlag" class="logo universum"></a>
        </v-toolbar-title>
      </v-toolbar>

      <v-layout fill-height>
        <v-snackbar
          top
          v-model="snackbar"
          :color="alert.type"
          :timeout="3000"
        >
          {{ $t(alert.message) }}

          <v-btn icon @click="alert.message = false" >
             <v-icon>close</v-icon>
           </v-btn>
        </v-snackbar>

        <v-layout row wrap class="my-4">
          <v-flex xs12 md12 lg10 offset-lg1 xl8 offset-xl2>
            <router-view/>
          </v-flex>
        </v-layout>
      </v-layout>
    </v-content>

    <v-dialog
      v-model="loading"
      persistent
      width="300"
    >
      <v-card color="primary" dark>
        <v-card-text>
          Loading...
          <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-footer color="white" fixed>
      <v-col class="text-center">
        <a href="https://lum-software.de" target="_blank">
          <img src="https://lum-software.de/images/lum-icon.png?source=jsa" alt="LUM GmbH" title="LUM - Die Softwaremanufatur" style="height: 30px;">
        </a>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import { userService } from './services'
export default {
  name: 'App',
  data () {
    return {
      drawer: true,
      fixed: false,
      items: [{
        img: './assets/circle-menu.svg',
        title: 'Übersicht',
        route: {name: 'hazardassessment_list'},
        roles: null
      }, {
        icon: 'add_circle',
        title: 'Neue Gefährdungsbeurteilung',
        route: {name: 'hazardassessment_edit', params: {id: 0}},
        roles: ['ROLE_CREATOR', 'ROLE_MANAGER', 'ROLE_ADMIN']
      }, {
        icon: 'settings',
        title: 'Einstellungen',
        route: {name: 'settings'},
        roles: null
      }, {
        divider: true
      }, {
        icon: 'help',
        title: 'Hilfe',
        route: {name: 'help'},
        roles: null
      }, {
        icon: 'feedback',
        title: 'Support',
        route: {name: 'support'},
        roles: null
      }, {
        divider: true
      }, {
        icon: 'logout',
        title: 'Abmelden',
        route: {name: 'logout'},
        roles: null
      }],
      miniVariant: false, // this.$vuetify.breakpoint.smAndDown
      title: 'GB Content Manager'
    }
  },
  computed: {
    username () {
      let user = this.$store.state.users.user
      if (!user) {
        return ''
      }
      return user.name ? user.name : user.username
    },
    loggedIn () {
      return this.$store.state.authentication.status.loggedIn
    },
    alert () {
      return this.$store.state.alert
    },
    snackbar: {
      get: function () {
        return this.$store.state.alert.message
      },
      set: function () {}
    },
    loading () {
      return this.$store.state.loader.loading
    }
  },
  methods: {
    isGranted (roles) {
      if (roles === null || roles.length === 0) {
        return true
      }
      return userService.isGranted(roles)
    }
  },
  watch: {
    $route () {
      if (this.loggedIn && typeof this.$matomo !== 'undefined') {
        this.$matomo.setUserId(localStorage.getItem('id'))
      }
      // clear alert on location change
      this.$store.dispatch('alert/clear')
    }
  }
}
</script>
