<template>
  <v-dialog
    persistent
    :value="true"
    max-width="800px"
    :scrollable="true"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
  >
    <v-card>
      <v-app-bar flat dark color="primary">
          <v-btn icon dark @click="cancel()">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ $t('Ticket') }}
            <template v-if="issue !== null">#{{ issue.id }}: {{ issue.subject }}</template>
          </v-toolbar-title>
      </v-app-bar>

      <v-card-text v-if="issue !== null">
        <v-list two-line subheader>
          <v-layout wrap>
            <v-flex md4>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ module }}</v-list-item-title>
                  <v-list-item-subtitle>{{ $t('Module') }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-flex>
            <v-flex md4>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <formatter :value="issue.created_on" type="datetime" />
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ $t('Erstellt am') }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-flex>
            <v-flex md4>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <formatter :value="issue.updated_on" type="datetime" />
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ $t('Letzte Änderung') }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-flex>
          </v-layout>
          <v-layout wrap>
            <v-flex md4>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ email }}</v-list-item-title>
                  <v-list-item-subtitle>{{ $t('E-Mail') }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-flex>
            <v-flex md8>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ issue.description }}</v-list-item-title>
                  <v-list-item-subtitle>{{ $t('Problembeschreibung') }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-flex>
          </v-layout>

          <template v-if="issue.files && issue.files.length > 0">
            <v-subheader>{{ $t('Dateianhänge') }}</v-subheader>
            <ul class="mx-2">
                <li v-for="file in issue.files" :key="file.id">
                  <a
                    :id="file.id"
                    target="_blank"
                    :href="base64(file.content, file.type)"
                    :download="file.filename"
                  >{{ file.filename }}</a>
                  &mdash;
                  <template v-if="file.description">{{ file.description }}</template>
                  <span class="text--secondary"><formatter :value="file.created_on" type="datetime" /></span>
                </li>
            </ul>

            <v-container grid-list-md v-if="previewFiles.length > 0" class="pb-0">
              <v-layout>
                <v-flex sm12 md6 v-for="file in previewFiles" :key="file.id">
                  <v-card flat>
                    <v-img
                      v-if="isImage(file)"
                      :src="base64(file.thumbnail, file.type)"
                      aspect-ratio="1.4"
                      :alt="file.filename"
                      @click.native="showPreview(file)"
                    />
                    <pdf-viewer
                      v-else-if="isPdf(file)"
                      :src="base64(file.content, file.type)"
                      pageScale="0.2"
                      :preview="true"
                      @click.native="showPreview(file)"
                    />
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
          </template>
        </v-list>

        <v-list two-line v-if="issue.journals && issue.journals.length > 0">
          <template v-for="(item, i) in issue.journals">
            <v-card flat :key="item.id">
              <v-card-title class="body-2 pb-0">
                <formatter :value="item.created_on" type="datetime" />
                <span class="text--secondary">&mdash; {{ item.user.name }}</span>
              </v-card-title>
              <v-card-text class="py-1">
                {{ item.notes }}
                <template v-for="(detail, y) in item.details">
                  <ul :id="y" v-if="detail.property === 'attachment'" :key="y">
                    <li v-html="$t('support.attachment_added', {filename: `<a href='#${detail.name}'>${detail.new_value}</a>`})"></li>
                  </ul>
                  <p class="mb-0" :id="y" v-if="detail.property === 'attr' && detail.name === 'status_id'" :key="y">
                    <em v-html="$t('support.status_changed', {status: statusLabel(detail.new_value)})"></em>
                  </p>
                </template>
              </v-card-text>
            </v-card>
            <v-divider :key="i" />
          </template>
        </v-list>

        <v-form v-model="valid" ref="form" v-if="this.issue.status.id !== 5">
          <v-textarea
            v-model="data.body"
            :label="$t('Kommentar hinzufügen')"
            :rules="requiredRules"
          >
          </v-textarea>

          <v-switch
            v-model="data.close"
            :label="$t('Ticket schließen')"
            :disabled="resolved"
          ></v-switch>
        </v-form>
      </v-card-text>

      <v-card-text v-else>
        <v-alert :value="issue !== null" type="error">
          {{ $t('Es ist ein Fehler aufgetreten!') }} {{ $t('Die Anfrage wurde nicht gefunden.') }}
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-spacer/>
        <v-btn color="primary" text @click="save()" :disabled="!valid">{{ $t('Absenden') }}</v-btn>
        <v-btn color="primary" text @click="cancel()">{{ $t('Schließen') }}</v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog
      v-model="previewDialog"
      transition="dialog-bottom-transition"
      max-width="800px"
      scrollable
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-card tile v-if="previewFile">
        <v-app-bar flat dark color="primary">
            <v-btn icon dark @click.native="hidePreview">
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ previewFile.filename }}</v-toolbar-title>
        </v-app-bar>
        <v-card-text id="scrollarea">
          <v-img v-if="isImage(previewFile)" :src="base64(previewFile.content, previewFile.type)" :alt="previewFile.name" contain />
          <pdf-viewer v-else :toolbar="false" :src="base64(previewFile.content, previewFile.type)" window="scrollarea" />
        </v-card-text>

        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" text @click.stop="hidePreview">{{ $t('Schließen') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-dialog>
</template>

<script>
import {redmineService} from '../../services'
import Formatter from '../helpers/Formatter'
import PdfViewer from '../helpers/PdfViewer'

export default {
  name: 'SupportView',
  data () {
    return {
      valid: false,
      dialog: true,
      previewDialog: false,
      previewFile: null,
      issue: null,
      data: {
        body: null,
        close: null
      },
      requiredRules: [v => !!v || this.$t('Bitte machen Sie eine Eingabe, das Feld darf nicht leer sein.')],
      emailRules: [
        (v) => !!v || 'Bitte machen Sie eine Eingabe, das Feld darf nicht leer sein.',
        (v) => /.+@.+/.test(v) || 'Bitte geben Sie eine gültige E-Mail-Adresse ein.'
      ]
    }
  },
  props: {
    modules: {
      type: Array,
      required: true
    },
    status: {
      type: Object,
      required: true
    }
  },
  computed: {
    resolved () {
      // 3 = Status resolved
      return this.issue.status.id === 3
    },
    module () {
      return this.getCustomField('Modul')
    },
    email () {
      return this.getCustomField('email')
    },
    previewFiles () {
      return this.issue.files.filter(file => { return this.isImage(file) || this.isPdf(file) })
    }
  },
  methods: {
    showPreview(file) {
      this.previewDialog = true
      this.previewFile = file
    },
    hidePreview() {
      this.previewDialog = false
      this.previewFile = null
    },
    isImage (file) {
      return file.type.indexOf('image/') > -1
    },
    isPdf (file) {
      return file.type.indexOf('/pdf') > -1
    },
    async getData (id) {
      this.data = Object.assign({}, {body: null, close: null})
      let {dispatch} = this.$store
      dispatch('loader/show', {root: true})
      await redmineService.get(id)
        .then(
          result => {
            this.issue = result
          },
          error => {
            dispatch('alert/error', error.message, {root: true})
          }
        )
        .finally(() => dispatch('loader/hide', {root: true}))
    },
    getCustomField (fieldName) {
      if (this.issue.custom_fields) {
        let field = this.issue.custom_fields.filter(field => {
          return field.name === fieldName
        })
        return field.length !== 0 ? field[0].value : ''
      }
      return ''
    },
    cancel () {
      this.$router.push({name: 'support'})
    },
    save () {
      if (this.valid) {
        redmineService.saveComment(this.issue.id, this.data).then(
          () => {
            let {dispatch} = this.$store
            dispatch('alert/success', 'Der Kommentar wurde gespeichert.', {root: true})
            this.$emit('needsUpdate')
            this.$router.push({name: 'support'})
          },
          error => {
            let {dispatch} = this.$store
            dispatch('alert/error', error.message, {root: true})
          }
        )
      }
    },
    statusLabel (statusId) {
      return Object.prototype.hasOwnProperty.call(this.status, statusId) ? '<strong>' + this.$t(this.status[statusId]) + '</strong>' : this.$t('unbekannt')
    },
    base64 (content, type) {
      return `data:${type};base64,${content}`
    }
  },
  mounted () {
    let id = this.$route.params.id
    if (id !== 'undefined' && !isNaN(id) && parseInt(id) > 0) {
      this.getData(id)
    }
  },
  components: {
    Formatter,
    PdfViewer
  }
}
</script>
