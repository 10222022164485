import { responseHelper } from '../helpers'
import Vue from 'vue'

export const hazardactionService = {
  get,
  versions,
  save,
  reactivate,
  remove
}

function get (id) {
  return Vue.prototype.$http.get('/hazardaction/' + id)
    .then(response => {
      return response.data
    })
    .catch(error => {
      return responseHelper.handleError(error)
    })
}

function versions (object) {
  return Vue.prototype.$http.get('/hazardaction/' + object.id + '/versions')
    .then(response => {
      return response.data
    })
    .catch(error => {
      return responseHelper.handleError(error)
    })
}

function save (object) {
  if (object.id !== null && object.id !== 0) {
    return Vue.prototype.$http.put('/hazardaction/' + object.id, object)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return responseHelper.handleError(error)
      })
  } else {
    return Vue.prototype.$http.post('/hazardaction', object)
      .then(response => {
        return response.data
      })
      .catch(function (error) {
        return responseHelper.handleError(error)
      })
  }
}

function reactivate (object) {
  return Vue.prototype.$http.put(
    '/hazardaction/reactivate/' + object.id, {
      implementation_responsible: object.implementation_responsible,
      implementation_deadline: object.implementation_deadline
    })
    .then(response => {
      return response.data
    })
    .catch(error => {
      return responseHelper.handleError(error)
    })
}

function remove (object) {
  return Vue.prototype.$http.delete('/hazardaction/' + object.id, object)
    .then(response => {
      return response.data
    })
    .catch(error => {
      return responseHelper.handleError(error)
    })
}
