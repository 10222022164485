<template>
  <v-dialog
    :value="true"
    transition="dialog-bottom-transition"
    scrollable
    max-width="600"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-app-bar flat dark color="primary">
        <v-btn icon dark @click="close()">
          <v-icon>close</v-icon>
        </v-btn>

        <v-toolbar-title>
          403 {{ $t('Zugriff verweigert') }}
        </v-toolbar-title>
      </v-app-bar>

      <v-divider></v-divider>

      <v-card-text>
        <v-alert :value="true" type="error">
          {{ $t('Sie haben nicht die Berechtigungen diese Seite anzuzeigen.') }}
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          test
          @click="close()"
        >
          {{ $t('Schließen') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'PermissionDenied',
  data () {
    return {
    }
  },
  methods: {
    close () {
      this.$router.go(-1)
    }
  }
}
</script>

