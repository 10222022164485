<template>
  <div>
    <v-card v-if="hazardAssessment !== null && hazardAssessmentObject !== null">
      <v-window v-model="step">
        <v-window-item :value="1">
          <step1
            ref="form1"
            :hazardAssessment="hazardAssessment"
            :was-validated="wasValidated"
            :supervisors="supervisors"
            :resubmissionIntervals="resubmissionIntervals"
            @isValid="setValidStep1"
            v-if="!isGranted(['ROLE_IMPLEMENTER'])"
          >
          </step1>
        </v-window-item>

        <v-window-item :value="2">
          <step2
            ref="form2"
            :hazardAssessment="hazardAssessment"
            :hazardAssessmentTypes="hazardAssessmentTypes"
            :specifications="specifications"
            @isValid="setValidStep2"
            v-if="!isGranted(['ROLE_IMPLEMENTER'])"
          ></step2>
        </v-window-item>

        <v-window-item :value="3">
          <step3
            ref="form3"
            :hazardtypes="hazardtypes"
            :hazardAssessmentTypes="hazardAssessmentTypes"
            :actiontypes="actiontypes"
            :specifications="specifications"
            :hazardAssessment="hazardAssessment"
            :hazardAssessmentObject="hazardAssessmentObject"
            :responsibles="responsibles"
            @isValid="setValidStep3"
            v-if="!isGranted(['ROLE_IMPLEMENTER'])"
          ></step3>
        </v-window-item>

        <v-window-item :value="4">
          <step4
            ref="form4"
            :hazardAssessment="hazardAssessment"
            :hazardAssessmentObject="hazardAssessmentObject"
            :actiontypes="actiontypes"
            @isValid="setValidStep4"
            v-if="!isGranted(['ROLE_IMPLEMENTER'])"
          ></step4>
        </v-window-item>

        <v-window-item :value="5">
          <step5
            ref="form5"
            :hazardAssessment="hazardAssessment"
            :hazardAssessmentObject="hazardAssessmentObject"
            :actiontypes="actiontypes"
            :responsibles="responsibles"
            :settings="settings"
            @isValid="setValidStep5"
          ></step5>
        </v-window-item>

        <v-window-item :value="6">
          <step6
            ref="form6"
            :hazardAssessment="hazardAssessment"
            :settings="settings"
            @isValid="setValidStep6"
          ></step6>
        </v-window-item>
      </v-window>

      <v-container wrap>
        <v-row>
          <v-col cols="auto" class="mr-auto">
            <template v-if="hazardAssessment.id && step === 1">
              <v-btn
                text
                @click="$router.push({name: 'hazardassessment_list'})"
              >
                <v-icon left>arrow_left</v-icon> {{$t('Zur Übersicht')}}
              </v-btn>
            </template>
            <template v-else-if="step >= 5">
              <v-btn @click="printPdf()">
                {{$t('Gefährdungsbeurteilung drucken')}}
                <v-icon right>print</v-icon>
              </v-btn>
            </template>
            <template v-if="step > 1 && step < 5">
              <v-btn
                text
                @click="back()"
              >
                <v-icon left>arrow_left</v-icon> {{$t('Zurück')}}
              </v-btn>
            </template>
          </v-col>

          <v-col cols="auto">
            <template v-if="step > 1 && step < 5 && !isGranted(['ROLE_IMPLEMENTER'])">
              <v-btn
                :disabled="step < 4 && !valid[step]"
                color="primary"
                @click="hazardAssessment.id !== null ? interrupt() : save()"
                class="mr-2"
              >
                {{$t('Bearbeitung unterbrechen')}} <v-icon right>save</v-icon>
              </v-btn>
            </template>
            <template v-if="step < 4">
            <v-btn
              :disabled="!valid[step]"
              color="primary"
              @click="next()"
            >
              {{$t('Weiter')}}<v-icon right>arrow_right</v-icon>
            </v-btn>
            </template>
            <template v-else-if="step === 4 && !isGranted(['ROLE_IMPLEMENTER'])">
              <v-btn
                :disabled="!valid[step]"
                color="primary"
                @click="executeDialog = true"
              >
                {{$t('Gefährdungsaufnahme abschließen')}} <v-icon right>save_alt</v-icon>
              </v-btn>
            </template>
            <template v-else-if="step === 5 && !isGranted(['ROLE_IMPLEMENTER'])">
              <v-btn
                :disabled="!valid[step]"
                color="primary"
                @click="finish()"
              >
                {{$t('Gefährdungsbeurteilung abschließen')}} <v-icon right>save_alt</v-icon>
              </v-btn>
            </template>
            <template v-else-if="!isGranted(['ROLE_IMPLEMENTER'])">
              <v-btn
                color="primary"
                @click="reissueDialog = true"
              >
                {{$t('Gefährdungsbeurteilung erneut durchführen')}}
                <v-icon right>autorenew</v-icon>
              </v-btn>
            </template>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-dialog
      v-model="noticeDialog"
      transition="dialog-bottom-transition"
      max-width="400"
    >
      <v-card color="warning">
        <v-card-title class="pb-0">
          <v-icon left>warning</v-icon>
          <span class="headline ml-3">{{ $t('Hinweis') }}</span>
        </v-card-title>
        <v-card-text class="subheading">
          {{ notice }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="noticeDialog = false" text>{{ $t('OK') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="executeDialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="450"
    >
      <v-card>
        <v-card-title class="headline text-xs-center">{{ $t('Möchten Sie die Gefährdungsaufnahme abschließen?') }}</v-card-title>
        <v-card-text class="subheading text-xs-center">
          {{ $t('Wenn Sie die Gefährdungsaufnahme abschließen, können Sie keine weiteren Gefährdungen hinzufügen.') }}<br>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red darken-1" @click="executeDialog = false" text>{{ $t('Nein') }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success darken-1" @click="execute()" text>{{ $t('Ja, abschließen') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="reissueDialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="450"
    >
      <v-card>
        <v-card-title class="headline text-xs-center">{{ $t('Möchten Sie die Gefährdungsbeurteilung erneut durchführen?') }}</v-card-title>
        <v-card-text class="subheading text-xs-center">
          {{ $t('Es wird eine Kopie der Gefährdungsbeurteilung erstellt, die Sie neu durchführen können.') }}<br>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red darken-1" @click="reissueDialog = false" text>{{ $t('Nein') }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success darken-1" @click="reissue()" text>{{ $t('Ja, erneut durchführen') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  hazardassessmentService,
  hazardtypeService,
  specificationService,
  hazardassessmenttypeService,
  hazardactiontypeService,
  userService,
  settingsService
} from '../../services'

// import printJS from 'print-js'
import Step1 from './workflow/Step1.vue'
import Step2 from './workflow/Step2.vue'
import Step3 from './workflow/Step3.vue'
import Step4 from './workflow/Step4.vue'
import Step5 from './workflow/Step5.vue'
import Step6 from './workflow/Step6.vue'

export default {
  name: 'workflow',
  data () {
    return {
      valid: {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false
      },
      notice: '',
      noticeDialog: false,
      executeDialog: false,
      reissueDialog: false,
      wasValidated: '',
      hazardAssessment: null,
      hazardAssessmentObject: null,
      specifications: [],
      hazardtypes: [],
      hazardAssessmentTypes: [],
      actiontypes: [],
      supervisors: [],
      responsibles: [],
      settings: null,
      resubmissionIntervals: [
        {id: 0, name: this.$t('ohne Wiedervorlage')},
        {id: 1, name: '1 ' + this.$tc('year', 1)},
        {id: 2, name: '2 ' + this.$tc('year', 2)},
        {id: 3, name: '3 ' + this.$tc('year', 2)},
        {id: 4, name: '4 ' + this.$tc('year', 2)},
        {id: 5, name: '5 ' + this.$tc('year', 2)}
      ]
    }
  },
  props: {
    id: {
      type: Number,
      default: 0,
      required: true
    },
    step: {
      type: Number,
      default: 1,
      required: false
    }
  },
  mounted: async function () {
    this.getSetting()
    this.getData()
    this.getHazardAssessmentData(this.id)
  },
  watch: {
    '$route' (to) {
      if (to.name === 'hazardassessment_edit' && typeof to.params.id !== 'undefined' && (isNaN(to.params.id) || parseInt(to.params.id) === 0)) {
        this.resetHazardAssessment()
        if (this.$refs['form1'] && this.$refs['form1'].$refs['form']) {
          this.$refs['form1'].$refs['form'].resetValidation()
        }
        if (this.$refs['form2'] && this.$refs['form2'].$refs['form']) {
          this.$refs['form2'].$refs['form'].resetValidation()
        }
      }
    }
  },
  methods: {
    setValidStep1 (val) {
      this.valid[1] = val
    },
    setValidStep2 (val) {
      this.valid[2] = val
    },
    setValidStep3 (val) {
      this.valid[3] = val
    },
    setValidStep4 (val) {
      this.valid[4] = val
    },
    setValidStep5 (val) {
      this.valid[5] = val
    },
    setValidStep6 (val) {
      this.valid[6] = val
    },
    next () {
      if (this.valid[this.step]) {
        if (this.step > 1) {
          let {dispatch} = this.$store
          dispatch('loader/show', {root: true})
          hazardassessmentService.save(this.hazardAssessment)
            .then(
              result => {
                this.hazardAssessment = result
                let step = this.step < 6 ? this.step + 1 : 1
                this.$router.push(`/hazardassessment/${this.hazardAssessment.id}/step/${step}`)
              },
              error => {
                dispatch('alert/error', error.message, {root: true})
              }
            )
            .finally(() => dispatch('loader/hide', {root: true}))
        } else {
          let step = this.step < 6 ? this.step + 1 : 1
          this.$router.push(`/hazardassessment/${this.hazardAssessment.id}/step/${step}`)
        }
      }
    },
    back () {
      let step = this.step > 2 ? this.step - 1 : 1
      if (this.valid[this.step]) {
        let {dispatch} = this.$store
        dispatch('loader/show', {root: true})
        hazardassessmentService.save(this.hazardAssessment)
          .then(
            result => {
              this.hazardAssessment = result
              this.$router.push(`/hazardassessment/${this.hazardAssessment.id}/step/${step}`)
            }
          )
          .finally(() => dispatch('loader/hide', {root: true}))
      } else if (this.hazardAssessment.id) {
        this.$router.push(`/hazardassessment/${this.hazardAssessment.id}/step/${step}`)
      } else {
        this.$router.push(`/hazardassessment/create/step/${step}`)
      }
    },
    save () {
      if (this.valid[this.step]) {
        let {dispatch} = this.$store
        dispatch('loader/show', {root: true})
        hazardassessmentService.save(this.hazardAssessment)
          .then(
            result => {
              this.hazardAssessment = result
              dispatch('alert/success', 'Die Gefährdungsermittlung wurde gespeichert.', {root: true})
            },
            error => {
              dispatch('alert/error', error.message, {root: true})
            }
          )
          .finally(() => dispatch('loader/hide', {root: true}))
      }
    },
    interrupt () {
      let {dispatch} = this.$store
      dispatch('loader/show', {root: true})
      hazardassessmentService.interrupt(this.hazardAssessment)
        .then(
          result => {
            this.hazardAssessment = result
            dispatch('alert/success', 'Die Gefährdungsermittlung wurde gespeichert.', {root: true})
          },
          error => {
            dispatch('alert/error', error.message, {root: true})
          }
        )
        .finally(() => dispatch('loader/hide', {root: true}))
    },
    finish () {
      let {dispatch} = this.$store
      dispatch('loader/show', {root: true})
      hazardassessmentService.finish(this.hazardAssessment)
        .then(
          result => {
            this.hazardAssessment = result
            dispatch('alert/success', 'Die Gefährdungsbeurteilung wurde abgeschloßen.', {root: true})
            this.$router.push(`/hazardassessment/${this.hazardAssessment.id}/finished`)
          },
          error => {
            if (error.message === '400: Bad Request') {
              this.notice = this.$t('Die Gefährdungsbeurteilung kann nicht abgeschloßen werden, da nicht alle Maßnahmen durchgeführt bzw. deren Wirksamkeit überprüft wurde.')
              this.noticeDialog = true
            } else {
              dispatch('alert/error', error.message, {root: true})
            }
          }
        )
        .finally(() => dispatch('loader/hide', {root: true}))
    },
    reissue () {
      this.reissueDialog = false
      let {dispatch} = this.$store
      dispatch('loader/show', {root: true})
      hazardassessmentService.reissue(this.hazardAssessment)
        .then(
          result => {
            this.hazardAssessment = result
            dispatch('alert/success', 'Die Gefährdungsbeurteilung kann erneut durchgeführt werden.', {root: true})
            this.$router.push({ name: 'hazardassessment_edit', params: {id: this.hazardAssessment.id} })
          },
          error => {
            dispatch('alert/error', error.message, {root: true})
          }
        )
        .finally(() => dispatch('loader/hide', {root: true}))
    },
    execute () {
      this.executeDialog = false
      if (this.valid[this.step]) {
        let {dispatch} = this.$store
        dispatch('loader/show', {root: true})
        hazardassessmentService.execute(this.hazardAssessment)
          .then(
            result => {
              this.hazardAssessment = result
              dispatch('alert/success', 'Die Gefährdungsermittlung wurde abgeschlossen.', {root: true})
              this.$nextTick(function () {
                this.$router.push({name: 'hazardassessment_execute', params: {id: this.hazardAssessment.id}})
              })
            },
            error => {
              dispatch('alert/error', error.message, {root: true})
            }
          )
          .finally(() => dispatch('loader/hide', {root: true}))
      }
    },
    printPdf () {
      let {dispatch} = this.$store
      dispatch('loader/show', {root: true})
      hazardassessmentService.pdf(this.hazardAssessment)
        .then(
          data => {
              console.error(data.name)
            /*printJS({
              printable: data.file,
              type: 'pdf',
              base64: true,
              documentTitle: data.name
            })*/
          },
          error => {
            dispatch('alert/error', error.message, {root: true})
          }
        )
        .finally(() => dispatch('loader/hide', {root: true}))
    },
    resetFormError () {
      this.wasValidated = ''
    },
    resetHazardAssessment () {
      this.hazardAssessment = JSON.parse(JSON.stringify(this.hazardAssessmentObject))
      this.hazardAssessment.hazards = []
    },
    async getHazardAssessmentData (id) {
      let {dispatch} = this.$store
      dispatch('loader/show', {root: true})
      await hazardassessmentService.create()
        .then(
          data => {
            this.hazardAssessmentObject = data
            if (isNaN(id) || parseInt(id) === 0) {
              this.resetHazardAssessment()
            }
          },
          error => {
            dispatch('alert/error', error.message, {root: true})
          }
        )
        .finally(() => dispatch('loader/hide', {root: true}))
      if (!isNaN(id) && parseInt(id) > 0) {
        dispatch('loader/show', {root: true})
        await hazardassessmentService.get(id)
          .then(
            data => {
              this.hazardAssessment = data
              if (data.status_name === 'STATUS_FINISHED' && this.$route.name !== 'hazardassessment_finished') {
                this.$router.replace(`/hazardassessment/${this.hazardAssessment.id}/finished`)
              } else if (data.status_name === 'STATUS_EXECUTION' && this.$route.name !== 'hazardassessment_execute') {
                this.$router.replace(`/hazardassessment/${this.hazardAssessment.id}/execute`)
              }
            },
            error => {
              dispatch('alert/error', error.message, {root: true})
            }
          )
          .finally(() => dispatch('loader/hide', {root: true}))
      }
    },
    async getData () {
      let {dispatch} = this.$store
      dispatch('loader/show', {root: true})
      await hazardtypeService.getAll()
        .then(
          list => {
            this.hazardtypes = list
          },
          error => {
            dispatch('alert/error', error.message, {root: true})
          }
        )
      await hazardassessmenttypeService.getAll()
        .then(
          list => {
            this.hazardAssessmentTypes = list
          },
          error => {
            dispatch('alert/error', error.message, {root: true})
          }
        )
      await hazardactiontypeService.getAll()
        .then(
          list => {
            this.actiontypes = list
          },
          error => {
            dispatch('alert/error', error.message, {root: true})
          }
        )
      await userService.getSupervisors()
        .then(
          list => {
            this.supervisors = list
          },
          error => {
            dispatch('alert/error', error.message, {root: true})
          }
        )
      await userService.getResponsibles()
        .then(
          list => {
            this.responsibles = list
          },
          error => {
            dispatch('alert/error', error.message, {root: true})
          }
        )
      specificationService.getAll()
        .then(
          list => {
            this.specifications = list
          },
          error => {
            dispatch('alert/error', error.message, {root: true})
          }
        )
      dispatch('loader/hide', {root: true})
    },
    async getSetting () {
      let {dispatch} = this.$store
      dispatch('loader/show', {root: true})
      await settingsService.getData()
        .then(
          result => {
            if (result !== null && typeof result.list !== 'undefined') {
              this.settings = result
            }
          },
          error => {
            dispatch('alert/error', error.message, {root: true})
          }
        )
        .finally(() => dispatch('loader/hide', {root: true}))
    },
    isGranted (roles) {
      if (roles === null || roles.length === 0) {
        return true
      }
      return userService.isGranted(roles)
    }
  },
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    Step6
  }
}
</script>
