<template>
  <div>
    <v-card>
      <v-app-bar flat color="grey lighten-2">
        <v-toolbar-title>
          {{ $t('Support') }}
        </v-toolbar-title>
      </v-app-bar>

      <v-divider></v-divider>

      <v-card-title>
        <h2 class="title">{{ $t('Bisher gestellte Anfragen') }}</h2>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn fab dark color="primary" @click="add()" v-on="on">
              <v-icon dark>add</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('Neue Anfrage stellen') }}</span>
        </v-tooltip>
      </v-card-title>

      <v-card-text>
        <v-card flat>
          <v-data-table
            :headers="headers"
            :items="data"
            item-key="name"
          >
            <template slot="item" slot-scope="props">
              <tr @click="select(props.item)">
                <td>{{ props.item.id }}</td>
                <td>{{ props.item.subject }}</td>
                <td><formatter :value="props.item.created_on" type="datetime"></formatter></td>
                <td><formatter :value="props.item.updated_on" type="datetime"></formatter></td>
                <td>{{ props.item.status.name }}</td>

                <td nowrap>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        v-on="on"
                        @click.stop="select(props.item)"
                        class="ma-0"
                      >
                        <v-icon color="primary">input</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('Anfrage anzeigen') }}</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
            <v-alert slot="no-data" :value="true" color="info" icon="warning">
              {{ $t('Es wurden noch keine Anfragen erfasst.') }}
            </v-alert>
          </v-data-table>
        </v-card>
      </v-card-text>
    </v-card>

    <router-view @needsUpdate="getData()" :email="email" :modules="modules" :status="status" />
  </div>
</template>

<script>
import {redmineService} from '../../services'
import formatter from '../helpers/Formatter'

export default {
  name: 'support',
  data () {
    return {
      data: [],
      modules: [],
      status: {},
      email: '',
      headers: [
        {
          text: this.$t('Nr'),
          align: 'left',
          sortable: false,
          value: 'id'
        },
        {
          text: this.$t('Kurzbeschreibung'),
          align: 'left',
          sortable: false,
          value: 'subject'
        },
        {
          text: this.$t('Erstellt'),
          align: 'left',
          sortable: false,
          value: 'created_on'
        },
        {
          text: this.$t('Aktualisiert'),
          align: 'left',
          sortable: false,
          value: 'updated_on'
        },
        {
          text: this.$t('Status'),
          align: 'left',
          sortable: false,
          value: 'status.name'
        },
        {text: this.$t('Aktion'), value: 'name', sortable: false}
      ]
    }
  },
  created: async function () {
    this.getData()
  },
  computed: {
    locale () {
      return typeof this.$store.getters.currentLocale === 'undefined' ? 'de' : this.$store.getters.currentLocale
    }
  },
  methods: {
    async getData () {
      let {dispatch} = this.$store
      dispatch('loader/show', {root: true})
      await redmineService.getAll()
        .then(
          result => {
            this.data = result.issues
            this.modules = result.modules
            this.status = result.status
            this.email = result.email
          },
          error => {
            dispatch('alert/error', error.message, {root: true})
          }
        )
        .finally(() => dispatch('loader/hide', {root: true}))
    },
    select (item) {
      this.$router.push({name: 'support_view', params: {id: item.id}})
    },
    add () {
      this.$router.push({name: 'support_add'})
    }
  },
  components: {
    formatter
  }
}
</script>
