export default class LocalizedFormatter {
  constructor (settings) {
    this.locale = settings.locale
  }

  format (value) {
    if (value === null || value.length === 0 || typeof value === 'undefined') {
      return
    }
    if (typeof value[this.locale] !== 'undefined') {
      return value[this.locale].replace(/</g, '&lt;').replace(/>/g, '&gt;')
    }
    return JSON.stringify(value)
  }
}
